import cookie from "js-cookie";

const getDefaultState = (useCookie = false) => {
  return {
    isAuthenticated: useCookie ? !!cookie.get("authToken") : false,
    profile: "",
    features: ""
  };
};

const createPermissionsGetter = (state, type) => {
  if (!state.profile.permissions) return false;
  const permission = state.profile.permissions.adminPermissions.find(obj => obj.type === type);
  if (permission) return permission.isEnabled;
};

// initial state
const state = getDefaultState(true);

const getters = {
  userIsAuthenticated(state) {
    return state.isAuthenticated;
  },
  userProfile(state) {
    return state.profile;
  },
  userEmail(state) {
    return state.profile.email;
  },
  userName(state) {
    return state.profile.name;
  },
  hasUsersRead(state) {
    return createPermissionsGetter(state, "UsersRead");
  },
  hasUserManage(state) {
    return createPermissionsGetter(state, "UsersManagement");
  },
  hasUsersKycRead(state) {
    return createPermissionsGetter(state, "UsersKycRead");
  },
  hasProfileEdit(state) {
    return createPermissionsGetter(state, "UsersProfileEdit");
  },
  hasDisable2Fa(state) {
    return createPermissionsGetter(state, "UsersDisable2Fa");
  },
  hasDelete(state) {
    return createPermissionsGetter(state, "UsersDelete");
  },
  hasResetLock(state) {
    return createPermissionsGetter(state, "UsersResetLock");
  },
  hasDuplicatesManage(state) {
    return createPermissionsGetter(state, "UsersDuplicatesManagement");
  },
  hasAdmin(state) {
    return createPermissionsGetter(state, "MainCanLogInToAdmin");
  },
  hasReadLogs(state) {
    return createPermissionsGetter(state, "MainCanReadLogs");
  },
  hasTradingAccountsRead(state) {
    return createPermissionsGetter(state, "TradingAccountsRead");
  },
  hasTradingAccountsManagement(state) {
    return createPermissionsGetter(state, "TradingAccountsManagement");
  },
  hasTransactionsRead(state) {
    return createPermissionsGetter(state, "TransactionsRead");
  },
  hasChangePermissions(state) {
    return createPermissionsGetter(state, "MainCanChangeAdminPermissions");
  },
  hasTransferFunds(state) {
    return createPermissionsGetter(state, "TransferFunds");
  },
  hasReportsRead(state) {
    return createPermissionsGetter(state, "MainCanFormReports");
  },
  hasChecksRead(state) {
    return createPermissionsGetter(state, "ChecksRead");
  },
  hasChecksManage(state) {
    return createPermissionsGetter(state, "ChecksManagement");
  },
  hasIbManage(state) {
    return createPermissionsGetter(state, "UsersIbManagement");
  },
  hasTournamentsRead(state) {
    return createPermissionsGetter(state, "TournamentsRead");
  },
  hasTournamentsManage(state) {
    return createPermissionsGetter(state, "TournamentsManagement");
  },
  hasPaymentsCreateWithdrawal(state) {
    return createPermissionsGetter(state, "TradingAccountsCreateWithdrawal");
  },
  hasFinanceInfoRead(state) {
    return createPermissionsGetter(state, "MainCanViewFinanceInfo");
  },
  hasBonusesRead(state) {
    return createPermissionsGetter(state, "BonusesRead");
  },
  hasBonusesManage(state) {
    return createPermissionsGetter(state, "BonusesManagement");
  }
};

const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  setUserAuthenticated({ commit }, isAuthenticated) {
    commit("updateIsAuthenticated", isAuthenticated);
  },
  setUserProfile({ commit }, profile) {
    commit("updateUserProfile", profile);
  },
  setPlatformFeatures({ commit }, features) {
    commit("updatePlatformFeatures", features);
  }
};

const mutations = {
  updateIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  updateUserProfile(state, profile) {
    state.profile = profile;
  },
  updatePlatformFeatures(state, features) {
    state.features = features;
  },
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
