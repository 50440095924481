import Repository from "./repository-base";

const adminResource = "admin/accounts";
const apiResource = "accounts";

export default {
  getAccounts(filter) {
    return Repository.get(`${adminResource}`, filter);
  },
  getAccountsFilterInfo() {
    return Repository.get(`${adminResource}/filters/info`);
  },
  getMt5Deals(filter) {
    return Repository.get(`${adminResource}/mt5/deals`, filter);
  },
  getMt5Positions(filter) {
    return Repository.get(`${adminResource}/mt5/positions`, filter);
  },
  getMt4Orders(filter) {
    return Repository.get(`${adminResource}/mt4/orders`, filter);
  },
  getTradingAccountDetails(id) {
    return Repository.get(`${adminResource}/${id}`);
  },
  getTerminalSessions(id, filter) {
    return Repository.get(`${adminResource}/${id}/terminal/sessions`, filter);
  },
  getComments(id, filter) {
    return Repository.get(`${adminResource}/${id}/comments`, filter);
  },
  updateAccountStatus(id, status) {
    return Repository.post(`${apiResource}/${id}/status`, status);
  },
  archiveAccount(id, request) {
    return Repository.post(`${adminResource}/${id}/archive`, request);
  },
  restoreAccount(id, request) {
    return Repository.post(`${adminResource}/${id}/restore`, request);
  },
  changeAccountLeverage(id, request) {
    return Repository.post(`${adminResource}/${id}/leverage`, request);
  },
  transferFund(id, request) {
    return Repository.post(`${adminResource}/${id}/transfer`, request);
  },
  addComment(id, request) {
    return Repository.post(`${adminResource}/${id}/comments`, request);
  }
};
