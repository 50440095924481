<template>
  <popup-modal ref="popup">
    <h4 class="mb-4">
      <b>{{ message }}</b>
    </h4>
    <b-container class="popup" fluid>
      <b-row class="mb-2">
        <b-col sm="4 text-sm-left mt-1">
          <label><b>Trading account: </b></label>
        </b-col>
        <b-col>
          <b-form-input v-model="selectedTarget" list="targets-list" placeholder="Select a target"> </b-form-input>
          <b-form-datalist id="targets-list" :options="targetsList" text-field="tradingAccount.login" />
        </b-col>
      </b-row>
    </b-container>
    <div class="btns mt-3">
      <b-button variant="primary" @click="onSave">Confirm</b-button>
      <b-button variant="dark" @click="_cancel">Cancel</b-button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "@/components/popups/popup-modal.vue";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";

const BonusesRepository = RepositoryFactory.get(RepositoryName.bonuses);
export default {
  name: "CancelUserBonusDlg",
  components: { PopupModal },
  data() {
    return {
      message: "",
      userBonusId: "",
      tradingAccountId: "",

      targetsList: [],
      selectedTarget: "",

      updatedData: {
        isOk: false,
        targetId: ""
      }
    };
  },
  methods: {
    onSave() {
      let targetId = this.targetsList.find(x => x.tradingAccount.login.toString() === this.selectedTarget)?.id;

      this.updatedData = {
        isOk: true,
        targetId: targetId
      };

      console.log(this.selectedTarget);
      this.resolvePromise(this.updatedData);
      this.resetState();
      this.$refs.popup.close();
    },
    async show(opts = {}) {
      this.message = opts.message;
      this.userBonusId = opts.userBonusId;

      await this.getAccounts();

      this.$refs.popup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.resetState();
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    resetState() {
      this.selectedTarget = "";
    },
    //#region Get
    async getAccounts() {
      this.isLoading = true;
      let axiosResponse = await BonusesRepository.getUserBonusTargets(this.userBonusId);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let accountsResponse = axiosResponse.data;

      this.targetsList = accountsResponse.items;
      this.isLoading = false;
    }
  }
  //endregion
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.popup {
  @include media-breakpoint-tablet() {
    width: 30rem;
  }
}
</style>
