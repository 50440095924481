<template>
  <router-link :to="link" class="card-stats-link">
    <b-card class="card-stats" border-variant="light">
      <b-row>
        <b-col>
          <slot>
            <h5 class="card-title text-uppercase text-muted mb-1" v-if="title">{{ title }}</h5>
            <span class="h4 font-weight-bold mb-0" v-if="subTitle">{{ subTitle }}</span>
          </slot>
        </b-col>

        <b-col cols="auto" v-if="$slots.icon || icon">
          <slot name="icon">
            <div class="icon-sm icon-shape rounded-circle" :class="[`icon-shape-${type}`]">
              <b-icon :icon="icon" variant="light"></b-icon>
            </div>
          </slot>
        </b-col>
      </b-row>

      <p class="mt-3 mb-0 text-sm">
        <slot name="footer"></slot>
      </p>
    </b-card>
  </router-link>
</template>
<script>
export default {
  name: "StatsCard",
  props: {
    type: {
      type: String,
      default: "primary"
    },
    icon: String,
    title: String,
    subTitle: [String, Number],
    link: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="scss" scoped>
.card-stats-link {
  display: block;
  height: 100%;
  min-height: 150px;
  color: $text-color;

  &:hover {
    transition: all 0.15s ease;
    color: $text-color;
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
  }
}

.card-stats {
  text-align: left;
  height: 100%;
}

.card-title {
  font-size: 0.8125rem;
}
</style>
