<template>
  <popup-modal ref="popup">
    <h4 class="mb-4">
      <b>{{ message }}</b>
    </h4>
    <b-container fluid class="popup-content">
      <b-row class="mb-4">
        <b-col sm="4" class="text-sm-left"><b>Affiliate Id:</b></b-col>
        <b-col>
          <b-form-input :state="isAffiliateValid" style="max-width: 100%" v-model="affiliateId" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4" class="text-sm-left"><b>Offer Id:</b></b-col>
        <b-col>
          <b-form-input :state="isOfferValid" style="max-width: 100%" v-model="offerId" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4" class="text-sm-left"><b>Sub Id 1:</b></b-col>
        <b-col>
          <b-form-input style="max-width: 100%" v-model="subIdFirst" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4" class="text-sm-left"><b>Sub Id 2:</b></b-col>
        <b-col>
          <b-form-input style="max-width: 100%" v-model="subIdSecond" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4" class="text-sm-left"><b>Sub Id 3:</b></b-col>
        <b-col>
          <b-form-input style="max-width: 100%" v-model="subIdThird" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4" class="text-sm-left"><b>Sub Id 4:</b></b-col>
        <b-col>
          <b-form-input style="max-width: 100%" v-model="subIdFourth" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4" class="text-sm-left"><b>Sub Id 5:</b></b-col>
        <b-col>
          <b-form-input style="max-width: 100%" v-model="subIdFifth" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4" class="text-sm-left"><b>Sub Id 6:</b></b-col>
        <b-col>
          <b-form-input style="max-width: 100%" v-model="subIdSixth" />
        </b-col>
      </b-row>
    </b-container>
    <div class="btns mt-3">
      <b-button variant="primary" :disabled="!isAffiliateValid || !isOfferValid" @click="onSave">Save</b-button>
      <b-button variant="dark" class="ml-2" @click="_cancel">Cancel</b-button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";

export default {
  name: "AttachToPartnershipDlg",
  components: { PopupModal },
  data() {
    return {
      message: "",
      affiliateId: "",
      offerId: "",
      subIdFirst: "",
      subIdSecond: "",
      subIdThird: "",
      subIdFourth: "",
      subIdFifth: "",
      subIdSixth: "",

      updatedData: {
        isOk: false,
        affiliateId: "",
        offerId: "",
        subIdFirst: "",
        subIdSecond: "",
        subIdThird: "",
        subIdFourth: "",
        subIdFifth: "",
        subIdSixth: ""
      }
    };
  },
  computed: {
    isAffiliateValid() {
      return this.affiliateId !== "" && this.affiliateId.match(/^\d*$/g) !== null;
    },
    isOfferValid() {
      return this.offerId !== "" && this.offerId.match(/^\d*$/g) !== null;
    }
  },
  methods: {
    onSave() {
      this.updatedData = {
        isOk: true,
        affiliateId: this.affiliateId,
        offerId: this.offerId,
        subIdFirst: this.subIdFirst,
        subIdSecond: this.subIdSecond,
        subIdThird: this.subIdThird,
        subIdFourth: this.subIdFourth,
        subIdFifth: this.subIdFifth,
        subIdSixth: this.subIdSixth
      };

      this.resolvePromise(this.updatedData);
      this.resetState();

      this.$refs.popup.close();
    },
    show() {
      this.$refs.popup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.resetState();
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    resetState() {
      this.affiliateId = "";
      this.offerId = "";
      this.subIdFirst = "";
      this.subIdSecond = "";
      this.subIdThird = "";
      this.subIdFourth = "";
      this.subIdFifth = "";
      this.subIdSixth = "";
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.popup {
  @include media-breakpoint-tablet() {
    width: 30rem;
  }
}
</style>
