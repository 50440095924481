import Repository from "./repository-base";

const resource = "admin/backoffice/checks";

export default {
  getDeposits(filter) {
    return Repository.get(`${resource}/deposit`, filter);
  },
  getWithdraw(filter) {
    return Repository.get(`${resource}/withdraw`, filter);
  },
  getAmlChecks(filter) {
    return Repository.get(`${resource}/aml`, filter);
  },
  update(params) {
    return Repository.post(`${resource}/update`, params);
  },
  addComment(id, request) {
    return Repository.post(`${resource}/${id}/comments`, request);
  },
  getChecksFilterInfo() {
    return Repository.get(`${resource}/filters/info`);
  },
  getStatistic(filter) {
    return Repository.get(`${resource}/statistic`, filter);
  }
};
