<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">Trading Tournaments</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
          <b-col v-if="hasTournamentsManage" sm="auto" class="ml-auto mt-3">
            <b-button variant="success" style="width: 100px" @click="this.addTournament">+ Add</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="tournamentsResultList"
      :fields-list="fields"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :has-details="true"
      :details-as-table="true"
      :custom-fields="detailsFields"
      @load-clicked="onLoadClicked"
      @srch-clicked="onSrchClicked"
      @pg-clicked="onPgClicked"
      @update-clicked="onUpdateClicked"
      @add-clicked="onAttachClicked"
      @delete-clicked="onDetachClicked"
      @start-clicked="onStartClicked"
      @stop-clicked="onStopClicked"
    >
    </common-data-selection-grid>
    <attach-to-tournament-dlg ref="attachToTournamentDlg"></attach-to-tournament-dlg>
    <add-tournament-dlg ref="addTournamentDlg"></add-tournament-dlg>
    <edit-tournament-dlg ref="editTournamentDlg"></edit-tournament-dlg>
    <confirm-dlg :show-confirm="true" ref="confirmDlg"></confirm-dlg>
  </b-card>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import CommonDataSelectionGrid from "../../../components/grids/common-data-selection-grid";
import AddTournamentDlg from "../../../components/popups/add-tournament-dlg";
import EditTournamentDlg from "../../../components/popups/edit-tournament-dlg";
import AttachToTournamentDlg from "../../../components/popups/attach-to-tournament-dlg";
import ConfirmDlg from "../../../components/popups/confirm-dlg";

import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { convertTournamentsList } from "../helpers/tournaments-converter";
import { mapGetters } from "vuex";

const TournamentsRepository = RepositoryFactory.get(RepositoryName.tradingTournaments);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "TournamentsList",
  components: {
    CommonDataSelectionGrid,
    AddTournamentDlg,
    EditTournamentDlg,
    AttachToTournamentDlg,
    ConfirmDlg
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,

      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,
      sortBy: "",
      sortDesc: true,

      tournamentsResultList: [],
      resultData: []
    };
  },
  async mounted() {
    await this.getTournaments();
  },
  computed: {
    ...mapGetters(["hasTournamentsRead", "hasTournamentsManage"]),
    fields() {
      return [
        {
          key: "tournamentTitle",
          label: "Title",
          sortable: false
        },
        {
          key: "type",
          label: "Type",
          sortable: false
        },
        {
          key: "isActive",
          label: "Is active",
          sortable: false
        },
        {
          key: "dateStart",
          label: "Date Start",
          sortable: false
        },
        {
          key: "dateEnd",
          label: "Date End",
          sortable: false
        },
        {
          key: "leadersCount",
          label: "Leaders Count",
          sortable: false
        },
        {
          key: "dateCreate",
          label: "Date Create",
          sortable: false
        },
        {
          key: "url",
          label: "Url",
          sortable: false
        },
        {
          key: "signUpUrl",
          label: "SignUp url",
          sortable: false
        },
        {
          key: "editTournament",
          label: "Actions",
          sortDirection: "asc",
          thStyle: { width: "10rem !important" }
        }
      ];
    },
    detailsFields() {
      return [
        {
          key: "login",
          label: "Login",
          tdClass: "w-10"
        },
        {
          key: "userEmail",
          label: "User email",
          tdClass: "w-10"
        },
        {
          key: "comment",
          label: "Comment",
          tdClass: "w-50"
        },
        {
          key: "deleteValue",
          label: "",
          tdClass: "w-10"
        }
      ];
    }
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getTournaments().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getTournaments().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getTournaments().catch(error => {
        console.error(error);
      });
    },
    async onUpdateClicked(value) {
      await this.updateTournament(value).catch(error => {
        console.error(error);
      });
    },
    async onAttachClicked(value) {
      await this.attachToTournament(value).catch(error => {
        console.error(error);
      });
    },
    async onDetachClicked(id, value) {
      await this.detachFromTournament(id, value).catch(error => {
        console.error(error);
      });
    },
    async onStartClicked(value) {
      await this.updateTournamentActiveState(value, true).catch(error => {
        console.error(error);
      });
    },
    async onStopClicked(value) {
      await this.updateTournamentActiveState(value, false).catch(error => {
        console.error(error);
      });
    },
    async getTournaments() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.filter
        }
      };

      this.isLoading = true;
      let axiosResponse = await TournamentsRepository.getTournaments(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let tournamentsResponse = axiosResponse.data;

      this.resultData = tournamentsResponse.items;
      this.isLoading = false;

      this.tournamentsResultList = convertTournamentsList(this.resultData);
    },
    async addTournament() {
      const dlgResult = await this.$refs.addTournamentDlg.show({
        isEdit: false,
        message: "Add tournament"
      });

      if (dlgResult.isOk) {
        let request = {
          title: dlgResult.title,
          utmSource: dlgResult.utmSource,
          type: dlgResult.tradingAccountType,
          leadersCount: dlgResult.leadersCount
        };

        let axiosResponse = await TournamentsRepository.createTournament(request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Tournament created success",
          blockClass: "msg-block"
        });
        await this.getTournaments();
      }
      this.isLoading = false;
    },
    async updateTournament(value) {
      const dlgResult = await this.$refs.editTournamentDlg.show({
        message: "Edit tournament",
        leadersCount: value.item.leadersCount
      });

      if (dlgResult.isOk) {
        let request = {
          leadersCount: dlgResult.leadersCount
        };

        let axiosResponse = await TournamentsRepository.updateTournament(value.item.id, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        await this.getTournaments();
        this.flashMessage.success({
          message: "Success",
          blockClass: "msg-block"
        });
      }
    },
    async attachToTournament(value) {
      const dlgResult = await this.$refs.attachToTournamentDlg.show({
        message: "Attach to tournament",
        serverType: value.item.type
      });

      if (dlgResult.isOk) {
        let request = {
          tradingAccountId: dlgResult.tradingAccountId,
          comment: dlgResult.comment
        };

        let axiosResponse = await TournamentsRepository.attachToTournament(value.item.id, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        await this.getTournaments();
        this.flashMessage.success({
          message: "Success",
          blockClass: "msg-block"
        });
      }
    },
    async detachFromTournament(tournamentId, value) {
      const dlgResult = await this.$refs.confirmDlg.show({
        title: `Are you sure you want to detach account from tournament?`,
        message: "",
        okButton: "Confirm"
      });

      if (dlgResult.isOk) {
        let request = {
          tradingAccountId: value.item.tradingAccountId
        };

        let axiosResponse = await TournamentsRepository.detachFromTournament(tournamentId, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        await this.getTournaments();
        this.flashMessage.success({
          message: "Success",
          blockClass: "msg-block"
        });
      }
    },
    async updateTournamentActiveState(value, isActive) {
      let state = isActive ? "start" : "stop";

      const dlgResult = await this.$refs.confirmDlg.show({
        title: `Are you sure you want to ${state} tournament?`,
        message: "",
        okButton: "Confirm"
      });

      if (dlgResult.isOk) {
        let axiosResponse = await TournamentsRepository.updateTournamentActiveState(value.item.id, isActive);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        await this.getTournaments();
        this.flashMessage.success({
          message: "Success",
          blockClass: "msg-block"
        });
      }
    }
  }
};
</script>

<style scoped></style>
