export function toISOLocal(currentDate) {
  var z = n => ("0" + n).slice(-2);
  var zz = n => ("00" + n).slice(-3);
  var off = currentDate.getTimezoneOffset();
  var sign = off > 0 ? "-" : "+";
  off = Math.abs(off);

  return (
    currentDate.getFullYear() +
    "-" +
    z(currentDate.getMonth() + 1) +
    "-" +
    z(currentDate.getDate()) +
    "T" +
    z(currentDate.getHours()) +
    ":" +
    z(currentDate.getMinutes()) +
    ":" +
    z(currentDate.getSeconds()) +
    "." +
    zz(currentDate.getMilliseconds()) +
    sign +
    z(0) +
    ":" +
    z(0)
  );
}
