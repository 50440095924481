<template>
  <div>
    <b-row>
      <b-col sm="auto">
        <b-card border-variant="light">
          <b-container fluid class="image-wrapper">
            <div class="image mt-3" style="width: 210px; height: 230px">
              <b-img class="w-100" :src="require('../../../assets/bonus.png')" alt="Bonus Logo"></b-img>
            </div>
            <b-row class="mb-2" align-v="center" align-h="center">
              <h5>{{ this.resultRecord.title }}</h5>
            </b-row>
            <b-row>
              <router-link :to="`/create-bonus?id=${id}`" class="w-100" v-if="hasBonusesManage">
                <b-button class="mb-2" block variant="info">Edit</b-button>
              </router-link>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
      <b-col>
        <b-card border-variant="light" no-body>
          <div class="tabs-container">
            <b-tabs content-class="mt-3" pills card>
              <b-tab title="Description" active no-body>
                <details-table :ref="refKeyDetailsTable" :data-result-list="bonusResult"> </details-table>
              </b-tab>
              <b-tab @click="onUserBonusesLoad" no-body>
                <template slot="title">
                  User's bonuses
                  <b-badge pill v-if="userBonusesTotalRows > 0" variant="secondary">{{ userBonusesTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="userBonusesResult"
                  :fields-list="userBonusesFields"
                  :is-busy="isLoading"
                  :total-rows="userBonusesTotalRows"
                  :per-page="userBonusesPerPage"
                  :need-info="true"
                  :has-details="true"
                  :details-as-table="true"
                  :custom-fields="userBonusesDetailsFields"
                  @srch-clicked="onUserBonusesSrchClicked"
                  @pg-clicked="onUserBonusesPgClicked"
                >
                </common-data-selection-grid>
              </b-tab>
            </b-tabs>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <router-view />
  </div>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";

import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import DetailsTable from "../../../components/grids/details-table";

import { mapGetters } from "vuex";
import { convertBonusDetails, convertUsersBonusesList } from "@/modules/sections/helpers/bonuses-converter";
import CommonDataSelectionGrid from "@/components/grids/common-data-selection-grid.vue";

const BonusesRepository = RepositoryFactory.get(RepositoryName.bonuses);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";
const refKeyDetailsTable = "details-table";
export default {
  name: "BonusDetails",
  props: ["id"],
  components: {
    CommonDataSelectionGrid,
    DetailsTable
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,
      refKeyDetailsTable,

      perPage: 10,
      userBonusesPerPage: 20,
      filter: null,

      isLoading: false,
      bonusResult: [],
      userBonusesResult: [],

      userBonusesTotalRows: 0,
      userBonusesMask: "",

      userBonusesFields: [
        {
          key: "user.name",
          label: "User name",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "dateStart",
          type: "date",
          label: "Date start",
          sortDirection: "desc",
          tdClass: "dateField"
        },
        {
          key: "dateExpiration",
          type: "date",
          label: "Date expiration",
          sortDirection: "desc",
          tdClass: "dateField"
        },
        {
          key: "status",
          label: "Status",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "accrualMode",
          label: "Accrual mode",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "actions",
          label: "Actions",
          sortDirection: "asc",
          thStyle: { width: "15rem !important" }
        }
      ],

      resultRecord: {
        bonusId: "",
        title: "",
        descriptionShort: "",
        currency: "",
        dateFrom: "",
        dateTo: "",

        expirationInDays: "",
        maxCountPerUser: "",
        isEnabled: "",
        isHided: "",
        isMultiApplicability: "",
        isRepeatedly: "",
        amountSet: "",

        startTrigger: "",
        startTriggerDescription: "",
        startTriggerOptions: "",

        partialExecutionRule: "",
        partialCancellationRule: "",
        applicability: "",
        applicabilityDescription: "",
        accrualMode: "",
        executionConditions: "",
        cancellationConditions: "",
        allowedTradingServerAccounts: "",
        allowedTradingServerSymbols: "",
        allowedTradingServerSymbolGroups: ""
      }
    };
  },
  mounted: async function () {
    await this.getBonusDetails();
  },
  computed: {
    ...mapGetters(["hasBonusesManage"]),
    userBonusesDetailsFields() {
      return [
        {
          key: "login",
          label: "Trading account"
        },
        {
          key: "amountAccrued",
          label: "Amount accrued"
        },
        {
          key: "amountAccruedTotal",
          label: "Amount accrued total"
        },
        {
          key: "amountPending",
          label: "Amount pending"
        },
        {
          key: "amountReleased",
          label: "Amount released"
        },
        {
          key: "currency",
          label: "Currency"
        }
      ];
    }
  },
  methods: {
    async onUserBonusesLoad() {
      await this.getUserBonusesResult();
    },
    async onUserBonusesPgClicked(value) {
      this.userBonusesCurrentPage = value;
      await this.getUserBonusesResult().catch(error => {
        console.error(error);
      });
    },
    async onUserBonusesSrchClicked(textValue) {
      this.userBonusesMask = textValue;

      await this.getUserBonusesResult().catch(error => {
        console.error(error);
      });
    },
    async getUserBonusesResult() {
      let filter = {
        params: {
          skip: (this.userBonusesCurrentPage - 1) * this.userBonusesPerPage,
          take: this.userBonusesPerPage,
          mask: this.userBonusesMask,
          bonusId: this.resultRecord.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await BonusesRepository.getUserBonuses(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.userBonusesTotalRows = axiosResponse.data.total;
      let bonusesResponse = convertUsersBonusesList(axiosResponse.data.items);

      this.userBonusesResult = bonusesResponse;
      this.isLoading = false;
    },
    async getBonusDetails() {
      let resultInfo = await BonusesRepository.getBonusDetails(this.id);

      if (resultInfo.status !== 200) {
        if (resultInfo.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: resultInfo.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      let result = convertBonusDetails(resultInfo.data);
      this.resultRecord = result;
      this.bonusResult = [
        {
          name: "Id",
          value: this.resultRecord.id
        },
        {
          name: "Title",
          value: this.resultRecord.title
        },
        {
          name: "Description",
          value: this.resultRecord.descriptionShort
        },
        {
          name: "Date from",
          value: this.resultRecord.dateFrom
        },
        {
          name: "Date to",
          value: this.resultRecord.dateTo
        },
        {
          name: "Expiration in days",
          value: this.resultRecord.expirationInDays
        },
        {
          name: "Max сount per user",
          value: this.resultRecord.maxCountPerUser
        },
        {
          name: "Is enabled",
          value: this.resultRecord.isEnabled
        },
        {
          name: "Is hided",
          value: this.resultRecord.isHided
        },
        {
          name: "Is multi applicability",
          value: this.resultRecord.isMultiApplicability
        },
        {
          name: "Is repeatedly",
          value: this.resultRecord.isRepeatedly
        },
        {
          name: "Currency",
          value: this.resultRecord.currency
        },
        {
          name: "Amount Set",
          value: this.resultRecord.amountSet
        },
        {
          name: "Start Trigger",
          value: this.resultRecord.startTrigger
        },
        {
          name: "Start Trigger Options",
          value: this.resultRecord.startTriggerOptions
        },
        {
          name: "Partial Execution Rule",
          value: this.resultRecord.partialExecutionRule
        },
        {
          name: "Partial Cancellation Rule",
          value: this.resultRecord.partialCancellationRule
        },
        {
          name: "Applicability",
          value: this.resultRecord.applicability
        },
        {
          name: "Accrual Mode",
          value: this.resultRecord.accrualMode
        },
        {
          name: "Execution Conditions",
          value: this.resultRecord.executionConditions
        },
        {
          name: "Сancellation Conditions",
          value: this.resultRecord.cancellationConditions
        },
        {
          name: "Allowed trading server accounts",
          value: this.resultRecord.allowedTradingServerAccounts
        },
        {
          name: "Allowed trading server symbols",
          value: this.resultRecord.allowedTradingServerSymbols
        },
        {
          name: "Allowed trading server symbol groups",
          value: this.resultRecord.allowedTradingServerSymbolGroups
        }
      ];
    }
  }
};
</script>

<style scoped lang="scss"></style>
