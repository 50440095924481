<template>
  <popup-modal ref="popup">
    <b-container fluid class="popup-content">
      <b-row class="mb-4">
        <b-col>
          <b>Subject:</b>
          <b-form-input style="max-width: 100%" v-model="subject" />
        </b-col>
        <b-col>
          <b>Locale:</b>
          <b-form-select v-model="locale" :options="sourceLocales" @change="editValue"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-textarea no-resize rows="15" max-rows="15" v-model="message"> </b-form-textarea>
        </b-col>
        <b-col sm="6">
          <div v-html="message"></div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <div class="mt-2">
            <b>Tags:</b>
          </div>
          <div style="display: inline-block" class="ml-1 mb-2" v-for="tag in tags" :key="tag.index">
            <b-badge variant="success">
              {{ tag }}
            </b-badge>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <div class="btns mb-4">
          <b-button variant="primary" @click="onSave">Save</b-button>
          <b-button variant="dark" class="ml-2" @click="_cancel">Cancel</b-button>
        </div>
      </b-row>
    </b-container>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";

export default {
  name: "EditEmailsDlg",
  components: { PopupModal },
  data() {
    return {
      type: "",
      locale: "",
      tags: "",
      template: {
        subject: "",
        message: ""
      },

      message: "",
      subject: "",
      buffer: "",

      resultData: [],
      rowValue: null,
      sourceLocales: [],
      sourceItems: [],
      isOk: false,
      updatedData: {
        locale: "",
        type: "",
        newMessage: ""
      }
    };
  },
  methods: {
    editValue() {
      this.buffer = this.sourceItems.find(item => item.locale === this.locale);

      this.message = this.buffer ? this.buffer.template.message : "";
      this.subject = this.buffer ? this.buffer.template.subject : "";
    },
    onSave() {
      this.isOk = true;

      this.updatedData = {
        type: this.type,
        locale: this.locale,
        newSubject: this.subject,
        newMessage: this.message
      };

      this.resolvePromise(this.updatedData);
      this.$refs.popup.close();
    },
    show(opts = {}) {
      this.rowValue = opts.rowValue;
      this.sourceLocales = opts.sourceLocales;
      this.sourceItems = opts.sourceItems;

      if (!this.rowValue.item) {
        this.type = this.rowValue.type;
        this.tags = this.rowValue.tags;
        this.locale = this.rowValue.locale;

        this.message = this.rowValue.template.body;
        this.subject = this.rowValue.template.subject;
      } else {
        this.type = this.rowValue.item.type;
        this.tags = this.rowValue.item.tags;
        this.sourceItems = this.rowValue.item.items;
      }

      this.$refs.popup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.resetState();
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    resetState() {
      this.type = "";
      this.locale = "";
      this.subject = "";
      this.message = "";
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popup-content {
  @include media-breakpoint-tablet() {
    width: 700px;
  }
  @include media-breakpoint-landscape-tablet() {
    width: 950px;
  }
}
</style>
