import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import router, { routePaths } from "@/modules/route/routes";
import store from "../../../store";
const ProfileRepository = RepositoryFactory.get(RepositoryName.profile);

export async function checkUserPermissions() {
  let response = await ProfileRepository.getProfile();
  if (response.status === 200 && response.data) {
    await store.dispatch("setUserProfile", response.data);
    await router.push(routePaths.homePage);
  }
}
