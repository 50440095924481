import Repository from "./repository-base";

const resource = "admin/funds";

export default {
  getTransactions(filter) {
    return Repository.get(`${resource}/transactions`, filter);
  },
  getTransactionsFilterInfo() {
    return Repository.get(`${resource}/filters/info`);
  }
};
