import axios from "axios";
import { baseUrl, addInterceptorXhrHeaders } from "./repository-base-config";
import router, { routePaths } from "@/modules/route/routes";
import store from "../../../store";

import cookie from "js-cookie";

const axiosInstance = axios.create({
  baseURL: baseUrl
});

addInterceptorXhrHeaders(axiosInstance);

// https://stackoverflow.com/questions/47216452/how-to-handle-401-authentication-error-in-axios-and-react
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ("Forbidden" === error.code) {
      console.log("ajax: 403 response. access denied");
      store.dispatch("resetState");
      router.push(routePaths.authLogin);
    } else if (401 === error.response.status) {
      // handle error: inform user, go to login, etc
      console.log("ajax: 401 response. user not logged in");
      cookie.remove("authToken");
      store.dispatch("resetState");
      router.push(routePaths.authLogin);
      return error.response;
      //clearAuthorizedDataIf401Error();
    } else if (403 === error.response.status) {
      console.log("ajax: 403 response. access denied");
      cookie.remove("authToken");
      store.dispatch("resetState");
      router.push(routePaths.homePage);
      return error.response;
    } else {
      return error.response; //Promise.reject(error);
    }
  }
);

export default axiosInstance;
