<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">Admin users</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="usersResultList"
      :fields-list="fields"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :has-details="false"
      :need-per-page="true"
      @load-clicked="onLoadClicked"
      @pg-clicked="onPgClicked"
      @srch-clicked="onSrchClicked"
      @edit-clicked="onEditClicked"
      @filter-clicked="onFilterClicked"
    >
    </common-data-selection-grid>
    <edit-permissions-dlg ref="editPermissionsDlg"></edit-permissions-dlg>
  </b-card>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import CommonDataSelectionGrid from "../../../../components/grids/common-data-selection-grid";
import { convertUsersList } from "@/modules/sections/helpers/users-converter";
import EditPermissionsDlg from "../../../../components/popups/edit-permissions-dlg";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { mapGetters } from "vuex";

const UsersRepository = RepositoryFactory.get(RepositoryName.users);
const ManageRepository = RepositoryFactory.get(RepositoryName.manage);

const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "PermissionsList",
  components: {
    CommonDataSelectionGrid,
    EditPermissionsDlg
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,
      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,

      usersResultList: [],
      resultData: []
    };
  },
  computed: {
    ...mapGetters(["hasChangePermissions"]),
    fields() {
      return [
        {
          key: "email",
          label: "Email",
          sortDirection: "desc",
          tdClass: "fieldsClass table-cell-wider"
        },
        {
          key: "permissions.main",
          label: "Main",
          sortDirection: "desc",
          tdClass: "fieldsClass text-left"
        },
        {
          key: "permissions.users",
          label: "Users",
          sortDirection: "desc",
          tdClass: "fieldsClass text-left"
        },
        {
          key: "permissions.tradingAccounts",
          label: "Trading accounts",
          sortDirection: "desc",
          tdClass: "fieldsClass text-left"
        },
        {
          key: "permissions.transactions",
          label: "Transactions",
          sortDirection: "desc",
          tdClass: "fieldsClass text-left"
        },
        {
          key: "permissions.checks",
          label: "Checks",
          sortDirection: "desc",
          tdClass: "fieldsClass text-left"
        },
        {
          key: "permissions.bonuses",
          label: "Bonuses",
          sortDirection: "desc",
          tdClass: "fieldsClass text-left"
        },
        {
          key: "permissions.tradingTournaments",
          label: "Trading Tournaments",
          sortDirection: "desc",
          tdClass: "fieldsClass text-left"
        },
        {
          key: "editSettings",
          label: "",
          thClass: !this.hasChangePermissions ? "d-none" : "",
          tdClass: !this.hasChangePermissions ? "d-none fieldsClass" : "fieldsClass"
        }
      ];
    }
  },
  mounted: async function () {
    await this.getUsers();
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getUsers().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getUsers().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getUsers().catch(error => {
        console.error(error);
      });
    },
    async onFilterClicked(value, textMask) {
      this.filter = textMask;

      await this.getUsers().catch(error => {
        console.error(error);
      });
    },
    async onEditClicked(rowValue) {
      await this.updatePermissions(rowValue).catch(error => {
        console.error(error);
      });
    },
    async getUsers() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      let filter = {
        params: {
          isAdmin: true,
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.filter
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUsers(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let usersResponse = axiosResponse.data;

      this.resultData = usersResponse.items;
      this.isLoading = false;

      this.usersResultList = convertUsersList(this.resultData);
      this.usersResultList.forEach(x => {
        x.permissions = {
          main: x.permissions.adminPermissions.filter(obj => obj.group === "Main"),
          users: x.permissions.adminPermissions.filter(obj => obj.group === "Users"),
          tradingAccounts: x.permissions.adminPermissions.filter(obj => obj.group === "Trading accounts"),
          transactions: x.permissions.adminPermissions.filter(obj => obj.group === "Transactions"),
          checks: x.permissions.adminPermissions.filter(obj => obj.group === "Checks"),
          bonuses: x.permissions.adminPermissions.filter(obj => obj.group === "Bonuses"),
          tradingTournaments: x.permissions.adminPermissions.filter(obj => obj.group === "Tournaments"),
          payments: x.permissions.adminPermissions.filter(obj => obj.group === "Payments")
        };
      });
    },
    async updatePermissions(rowValue) {
      rowValue.item.permissions.adminPermissions = rowValue.item.permissions.main
        .concat(rowValue.item.permissions.users)
        .concat(rowValue.item.permissions.tradingAccounts)
        .concat(rowValue.item.permissions.transactions)
        .concat(rowValue.item.permissions.checks)
        .concat(rowValue.item.permissions.bonuses)
        .concat(rowValue.item.permissions.tradingTournaments)
        .concat(rowValue.item.permissions.payments);

      const dlgResult = await this.$refs.editPermissionsDlg.show({
        rowValue: rowValue.item
      });

      if (dlgResult.isOk) {
        let request = {
          adminPermissions: dlgResult.permissions
        };

        let axiosResponse = await ManageRepository.updateUserPermissions(rowValue.item.id, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Permissions updated",
          blockClass: "msg-block"
        });

        await this.getUsers();
      }
    }
  }
};
</script>
