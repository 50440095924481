<template>
  <popup-modal ref="popup">
    <h4 class="mb-4 comments">
      <b>{{ message }}</b>
    </h4>
    <b-container class="popup" fluid>
      <b-row v-if="login != null" class="mb-2">
        <b-col class="text-sm-left pre-formatted">
          <label
            >Login: <b>#{{ login }}</b></label
          >
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col class="text-sm-left pre-formatted">
          <label
            >User: <b>{{ userEmail }}</b></label
          >
        </b-col>
      </b-row>
      <b-row v-if="amount != null" class="mb-2">
        <b-col class="text-sm-left pre-formatted">
          <label
            >Amount: <b>{{ amount }}</b></label
          >
        </b-col>
      </b-row>
      <b-row v-if="currency != null" class="mb-2">
        <b-col class="text-sm-left pre-formatted">
          <label
            >Currency: <b>{{ currency }}</b></label
          >
        </b-col>
      </b-row>
      <b-row v-if="transactionNumber != null" class="mb-2">
        <b-col class="text-sm-left">
          <label
            >Tx number: <b> {{ transactionNumber }}</b></label
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-textarea
            class="textarea"
            placeholder="Enter your comment"
            rows="5"
            v-model="comment"
            :state="comment.trim().length > 0 || !commentRequired"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
    </b-container>
    <div class="btns mt-3">
      <b-button variant="primary" @click="onSave" :disabled="comment.trim().length <= 0 && commentRequired"
        >Save</b-button
      >
      <b-button variant="dark" class="ml-2" @click="_cancel">Cancel</b-button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";

export default {
  name: "AddCommentDlg",
  components: { PopupModal },
  data() {
    return {
      message: "",
      comment: "",
      userEmail: "",
      currency: "",
      amount: "",
      commentRequired: false,
      transactionNumber: null,
      login: null,

      updatedData: {
        isOk: false,
        comment: ""
      }
    };
  },
  methods: {
    onSave() {
      this.updatedData = {
        isOk: true,
        comment: this.comment
      };

      this.resolvePromise(this.updatedData);
      this.resetState();

      this.$refs.popup.close();
    },
    show(opts = {}) {
      this.comment = opts.comment ?? "";
      this.transactionNumber = opts.transactionNumber;
      this.message = opts.message;
      this.commentRequired = opts.commentRequired;
      this.userEmail = opts.userEmail;
      this.amount = opts.amount;
      this.login = opts.login;
      this.currency = opts.currency;

      this.$refs.popup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.resetState();
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    resetState() {
      this.comment = "";
      this.userEmail = "";
      this.amount = "";
      this.currency = "";
      this.transactionNumber = null;
      this.login = null;

      this.commentRequired = false;
    }
  }
};
</script>

<style scoped lang="scss">
.textarea {
  resize: none;
}

.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.popup {
  @include media-breakpoint-tablet() {
    width: 30rem;
  }
}
</style>
