<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">Antifraud</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="duplicatesResultList"
      :fields-list="fields"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :has-details="false"
      @load-clicked="onLoadClicked"
      @srch-clicked="onSrchClicked"
      @pg-clicked="onPgClicked"
      @cancel-clicked="onCancelDuplicate"
    >
    </common-data-selection-grid>
  </b-card>
</template>
<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";

import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { convertDuplicates } from "../../helpers/users-converter";
import CommonDataSelectionGrid from "@/components/grids/common-data-selection-grid.vue";

const UsersRepository = RepositoryFactory.get(RepositoryName.users);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "UsersDuplicatesList",
  components: {
    CommonDataSelectionGrid
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,

      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,
      sortBy: "",
      sortDesc: true,

      duplicatesResultList: [],
      resultData: []
    };
  },
  async mounted() {
    await this.getDuplicates();
  },
  computed: {
    fields() {
      return [
        {
          key: "user.name",
          label: "User",
          sortable: false
        },
        {
          key: "userOriginal.name",
          label: "Original",
          sortable: false
        },
        {
          key: "date",
          label: "Date",
          sortable: false
        },
        {
          key: "description",
          label: "Description",
          sortable: false
        },
        {
          key: "status",
          label: "Status",
          sortable: false
        },
        {
          key: "comment",
          label: "Comment",
          sortable: false
        },
        {
          key: "admin.name",
          label: "Admin",
          sortable: false
        },
        {
          key: "editDuplicate",
          label: "Actions",
          sortDirection: "asc",
          thStyle: { width: "10rem !important" }
        }
      ];
    }
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getDuplicates().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getDuplicates().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getDuplicates().catch(error => {
        console.error(error);
      });
    },
    async onCancelDuplicate(comment, userId, fieldId, originalUserId) {
      let request = {
        originalUserId: originalUserId,
        fieldId: fieldId,
        comment: comment ?? null
      };

      let axiosResponse = await UsersRepository.cancelDuplicate(userId, request);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      await this.getDuplicates();
      this.flashMessage.success({
        message: "Success",
        blockClass: "msg-block"
      });
    },
    async getDuplicates() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.filter
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUserDuplicates(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let duplicatesResponse = axiosResponse.data;

      this.resultData = duplicatesResponse.items;
      this.isLoading = false;

      this.duplicatesResultList = convertDuplicates(this.resultData);
    }
  }
};
</script>
