import Vue from "vue";
import store from "./store";
import App from "./App.vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Router from "./modules/route/routes";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/styles/index.scss";
import FlashMessage from "@smartweb/vue-flash-message";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.use(VueReCaptcha, { siteKey: "6LdNCy8jAAAAAFnz8zBAlrR4ib-EcYwjdRzMPCxc", loaderOptions: { autoHideBadge: true } });
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(FlashMessage);

Vue.config.productionTip = false;

new Vue({
  store,
  render: h => h(App),
  router: Router
}).$mount("#app");
