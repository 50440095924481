<template>
  <b-table
    id="details-table"
    style="overflow: visible"
    ref="table"
    hover
    class="mt-3 table-condensed border-right border-top border-bottom"
    thead-class="d-none"
    :fields="fields"
    :items="getResult"
    show-empty
  >
    <template #cell(value)="data">
      <slot
        v-if="
          data.value === 'Verified' ||
          data.value === 'Approved' ||
          data.value === 'Yes' ||
          data.value === 'Low' ||
          data.value === 'Enabled' ||
          data.value === 'Showed'
        "
      >
        <b-badge pill variant="success">
          {{ data.value }}
        </b-badge>
      </slot>
      <slot
        v-else-if="
          data.value === 'NotVerified' || data.value === 'No' || data.value === 'Disabled' || data.value === 'Hided'
        "
      >
        <b-badge pill variant="secondary">
          {{ data.value }}
        </b-badge>
      </slot>
      <slot v-else-if="data.value === 'Rejected' || data.value === 'High'">
        <b-badge pill variant="danger">
          {{ data.value }}
        </b-badge>
      </slot>
      <slot
        v-else-if="
          data.value === 'Pending' || data.value === 'DocumentsRequested' || data.value === 'ResubmissionRequested'
        "
      >
        <b-badge pill variant="warning">
          {{ data.value }}
        </b-badge>
      </slot>
      <slot v-else-if="data.value === 'UnderReview' || data.value === 'Medium'">
        <b-badge pill variant="warning">
          {{ data.value }}
        </b-badge>
      </slot>
      <slot v-else-if="data.value === 'Accrued'">
        <b-badge pill variant="info">
          {{ data.value }}
        </b-badge>
      </slot>
      <slot v-else-if="data.value === 'Canceled'">
        <b-badge pill variant="dark">
          {{ data.value }}
        </b-badge>
      </slot>
      <slot v-else-if="data.item.name === 'Agent email' && data.item.value.email">
        <router-link target="_blank" :to="'/user-details/' + data.item.value.id">{{
          data.item.value.email
        }}</router-link>
      </slot>
      <slot v-else-if="data.item.name === 'Agent name' && data.item.value.agentName">
        <router-link target="_blank" :to="'/user-details/' + data.item.value.agentId">{{
          data.item.value.agentName
        }}</router-link>
      </slot>
      <slot v-else-if="data.item.name === 'Admin name' && data.item.value.adminName">
        <router-link target="_blank" :to="'/user-details/' + data.item.value.adminId">{{
          data.item.value.adminName
        }}</router-link>
      </slot>
      <slot v-else-if="data.item.name === 'User email'">
        <router-link target="_blank" :to="'/user-details/' + data.item.value.id">{{
          data.item.value.email
        }}</router-link>
      </slot>
      <slot v-else-if="data.item.name === 'User name'">
        <router-link target="_blank" :to="'/user-details/' + data.item.value.id">{{
          data.item.value.name
        }}</router-link>
      </slot>
      <slot v-else-if="data.item.name === 'Bonus'">
        <router-link target="_blank" :to="'/bonus-details/' + data.item.value.id">{{
          data.item.value.title
        }}</router-link>
      </slot>
      <slot v-else-if="data.item.name === 'SumSub info' && data.item.value">
        <b-link :href="data.item.value.applicantUrl" target="_blank">{{ data.item.value.applicantUrl }}</b-link>
      </slot>
      <slot v-else-if="data.item.name === 'Url' && data.item.value">
        <b-link :href="data.item.value" target="_blank">{{ data.item.value }}</b-link>
      </slot>
      <slot v-else-if="data.item.name === 'Date expiration' && data.item.isEditable">
        <b-row align-h="center" v-if="data.item.onEdit">
          <b-input-group class="w-50">
            <b-form-datepicker v-model="data.item.value.date" class="w-25" />
            <b-form-timepicker v-model="data.item.value.time" />
            <b-input-group-prepend class="ml-3">
              <b-button type="button" @click="onRemove" variant="light" class="p-1">
                <b-icon icon="x-square" variant="dark"></b-icon>
              </b-button>
              <b-button type="button" @click="onSave(data.item)" class="p-1">
                <b-icon icon="check-square" variant="success"></b-icon>
              </b-button>
            </b-input-group-prepend>
          </b-input-group>
        </b-row>
        <b-row align-h="center" class="table-rows" v-else>
          {{ data.value.date + " " + data.value.time }}
          <b-button variant="light" class="ml-3 mb-2 p-0" @click="onEdit(data.item)">
            <b-icon icon="pencil-square" scale="1" variant="dark"></b-icon>
          </b-button>
        </b-row>
      </slot>
      <slot v-else-if="data.item.isEditable && hasProfileEdit">
        <b-row align-h="center" v-if="data.item.onEdit">
          <b-form-input class="w-25" size="sm" v-model="data.item.value"></b-form-input>
          <b-button variant="light" class="ml-2 mt-1 p-0" @click="onSave(data.item)">
            <b-icon icon="check-square" scale="1" variant="success"></b-icon>
          </b-button>
        </b-row>
        <b-row align-h="center" class="table-rows" v-else>
          {{ data.value }}
          <b-button variant="light" class="ml-3 mb-2 p-0" @click="onEdit(data.item)">
            <b-icon icon="pencil-square" scale="1" variant="dark"></b-icon>
          </b-button>
        </b-row>
      </slot>
      <slot v-else-if="data.item.isAttached" class="table-rows">
        <b-row align-h="center" class="table-rows">
          {{ data.value }}
          <b-button variant="light" class="ml-3 mb-2 p-0" @click="onAttach(data.item)">
            <b-icon icon="plus-square" scale="1" variant="dark"></b-icon>
          </b-button>
        </b-row>
      </slot>
      <slot v-else-if="data.item.isTargetEditable">
        <b-row align-h="center" v-if="data.item.onEdit">
          <b-form-input class="w-25" size="sm" v-model="data.item.value"></b-form-input>
          <b-button variant="light" class="ml-2 mt-1 p-0" @click="onTargetUpdated(data.item)">
            <b-icon icon="check-square" scale="1" variant="success"></b-icon>
          </b-button>
        </b-row>
        <b-row align-h="center" class="table-rows" v-else>
          {{ data.value }}
          <b-button variant="light" class="ml-3 mb-2 p-0" @click="onEdit(data.item)">
            <b-icon icon="pencil-square" scale="1" variant="dark"></b-icon>
          </b-button>
          <b-button variant="light" class="ml-1 mb-2 p-0" @click="onTargetUpdated(data.item, true)">
            <b-badge variant="danger"> Default </b-badge></b-button
          >
        </b-row>
      </slot>
      <slot v-else> {{ data.value }} </slot>
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from "vuex";
import { BIcon } from "bootstrap-vue";

export default {
  name: "DetailsTable",
  components: { BIcon },
  props: ["dataResultList"],
  data() {
    return {
      resultItems: this.dataResultList
    };
  },
  watch: {
    dataResultList: {
      handler: function () {
        this.resultItems = this.dataResultList;
      }
    }
  },
  computed: {
    ...mapGetters(["hasProfileEdit"]),
    getResult() {
      return this.resultItems;
    },
    fields() {
      return [
        {
          key: "name",
          label: "Name",
          tdClass: "details-name"
        },
        {
          key: "value",
          label: "Value"
        }
      ];
    }
  },
  methods: {
    onEdit(item) {
      item.onEdit = !item.onEdit;
    },
    onAttach(item) {
      item.onEdit = !item.onEdit;
      this.$emit("attach-clicked", item);
    },
    onTargetUpdated(item, setDefault = false) {
      item.onEdit = !item.onEdit;
      this.$emit("edit-target-clicked", item, setDefault);
    },
    onSave(item) {
      item.onEdit = !item.onEdit;
      this.$emit("edit-clicked", item);
    },
    onRemove(item) {
      this.$emit("remove-clicked", item);
    }
  }
};
</script>

<style scoped lang="scss">
.table-condensed {
  font-size: 14px;
  outline: 5rem;
  overflow-y: auto;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  margin-bottom: 0;
}

.columns {
  width: 20%;
}

.table-rows {
  height: 1rem !important;
}
</style>
