<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">Bonuses</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
          <b-col sm="auto" class="ml-auto mt-3" v-if="hasBonusesManage">
            <router-link :to="'/create-bonus'">
              <b-button style="width: 140px" variant="success">+ Add bonus</b-button>
            </router-link>
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="bonusesResultList"
      :fields-list="fields"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :has-details="true"
      :details-as-table="true"
      :custom-fields="detailsFields"
      @load-clicked="onLoadClicked"
      @srch-clicked="onSrchClicked"
      @pg-clicked="onPgClicked"
    >
    </common-data-selection-grid>
  </b-card>
</template>
<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";

import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { convertBonusesList } from "../helpers/bonuses-converter";
import CommonDataSelectionGrid from "@/components/grids/common-data-selection-grid.vue";
import { mapGetters } from "vuex";

const BonusesRepository = RepositoryFactory.get(RepositoryName.bonuses);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "BonusesList",
  components: {
    CommonDataSelectionGrid
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,

      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,
      sortBy: "",
      sortDesc: true,

      bonusesResultList: [],
      resultData: []
    };
  },
  async mounted() {
    await this.getBonuses();
  },
  computed: {
    ...mapGetters(["hasBonusesManage"]),
    fields() {
      return [
        {
          key: "bonusTitle",
          label: "Title",
          sortable: false
        },
        {
          key: "dateFrom",
          label: "Date from",
          sortable: false
        },
        {
          key: "dateTo",
          label: "Date to",
          sortable: false
        },
        {
          key: "currency",
          label: "Currency",
          sortable: false
        },
        {
          key: "isEnabled",
          label: "Is enable",
          sortable: false
        },
        {
          key: "isHided",
          label: "Is hided",
          sortable: false
        },
        {
          key: "isMultiApplicability",
          label: "Is multi applicability",
          sortable: false
        },
        {
          key: "isRepeatedly",
          label: "Is repeatedly",
          sortable: false
        },
        {
          key: "accrualMode",
          label: "Accrual mode",
          sortable: false
        },
        {
          key: "editBonus",
          label: "Actions",
          sortDirection: "asc",
          thClass: !this.hasBonusesManage ? "d-none" : "",
          tdClass: !this.hasBonusesManage ? "d-none fieldsClass" : "fieldsClass",
          thStyle: { width: "10rem !important" }
        }
      ];
    },
    detailsFields() {
      return [
        {
          key: "amount",
          label: "Amount"
        },
        {
          key: "limitMax",
          label: "Max limit"
        }
      ];
    }
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getBonuses().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getBonuses().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getBonuses().catch(error => {
        console.error(error);
      });
    },
    async getBonuses() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.filter
        }
      };

      this.isLoading = true;
      let axiosResponse = await BonusesRepository.getBonuses(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let bonusesResponse = axiosResponse.data;

      this.resultData = bonusesResponse.items;
      this.isLoading = false;

      this.bonusesResultList = convertBonusesList(this.resultData);
    }
  }
};
</script>
