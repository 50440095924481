<template>
  <div>
    <b-row>
      <b-col sm="auto">
        <b-card border-variant="light">
          <b-container fluid>
            <b-row align-v="center" class="mb-5">
              <h5 class="mb-0 mr-2">{{ resultRecord.login }}{{ " (" + resultRecord.currency + ")" }}</h5>
              <span>{{ resultRecord.serverPlatformTitle }}</span>
            </b-row>
            <b-row v-if="hasTransactionsRead">
              <b-button class="mb-2" block variant="primary" @click="onTransactionsBtnClick">Transactions </b-button>
            </b-row>
            <b-row v-if="hasTradingAccountsManagement">
              <b-button class="mb-2" block variant="danger" @click="onArchiveBtnClick" :disabled="isDeleted"
                >{{ getArchiveBtnText }}
              </b-button>
            </b-row>
            <b-row v-if="hasTradingAccountsManagement">
              <b-button class="mb-2" block variant="danger" @click="onDeleteBtnClick" :disabled="isDeleted"
                >Delete account
              </b-button>
            </b-row>
            <b-row block v-if="hasTransferFunds && hasTradingAccountsManagement">
              <b-button
                class="mb-2"
                variant="dark"
                block
                @click="onTransferToTradingAccountBtnClick"
                :disabled="isDeleted"
                >Transfer
              </b-button>
            </b-row>
            <b-row block v-if="hasPaymentsCreateWithdrawal && isReal">
              <b-button class="mb-2" variant="dark" block @click="onCreateWithdrawBtnClick">Withdrawal </b-button>
            </b-row>
            <b-row block v-if="hasTradingAccountsManagement">
              <b-button class="mb-2" variant="dark" block @click="onEditLeverageBtnClick"
                >Change the leverage
              </b-button>
            </b-row>
            <b-row>
              <b-button class="mb-2" variant="dark" block @click="onAddCommentBtnClick">Comment </b-button>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
      <b-col>
        <b-card border-variant="light" no-body>
          <div class="tabs-container">
            <b-tabs content-class="mt-3" pills card>
              <b-tab title="Description" active no-body>
                <details-table :ref="refKeyDetailsTable" :data-result-list="accountResult"></details-table>
              </b-tab>
              <b-tab @click="onTradesLoad" no-body>
                <template slot="title">
                  Trades (Deals)
                  <b-badge pill v-if="tradesTotalRows > 0" variant="secondary">{{ tradesTotalRows }}</b-badge>
                </template>
                <b-row>
                  <b-col sm="auto" class="ml-auto">
                    <download-excel :before-generate="onTradesDownload" class="btn btn-light mb-3 mr-3">
                      Download
                    </download-excel>
                  </b-col>
                </b-row>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="tradesResult"
                  :fields-list="tradeFields"
                  :is-busy="isLoading"
                  :total-rows="tradesTotalRows"
                  :per-page="tradesPerPage"
                  :need-per-page="true"
                  :has-details="true"
                  @load-clicked="onTradesLoadClicked"
                  @pg-clicked="onTradesPgClicked"
                  @srch-clicked="onTradesSrchClicked"
                  @filter-clicked="onTradesFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onPositionsLoad" no-body v-if="this.isMt5">
                <template slot="title">
                  Trades (Positions)
                  <b-badge pill v-if="positionsTotalRows > 0" variant="secondary">{{ positionsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="positionsResult"
                  :fields-list="positionsFields"
                  :is-busy="isLoading"
                  :total-rows="positionsTotalRows"
                  :per-page="positionsPerPage"
                  :need-per-page="true"
                  @load-clicked="onPositionsLoadClicked"
                  @pg-clicked="onPositionsPgClicked"
                  @srch-clicked="onPositionsSrchClicked"
                  @filter-clicked="onPositionsFilterClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onBonusesLoad" v-if="hasBonusesRead" no-body>
                <template slot="title">
                  Bonuses
                  <b-badge pill v-if="bonusesTotalRows > 0" variant="secondary">{{ bonusesTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="bonusesResult"
                  :fields-list="bonusesFields"
                  :is-busy="isLoading"
                  :total-rows="bonusesTotalRows"
                  :per-page="bonusesPerPage"
                  :has-details="true"
                  :details-as-table="true"
                  :custom-fields="bonusesDetailsFields"
                  @srch-clicked="onBonusesSrchClicked"
                  @pg-clicked="onBonusesPgClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onSessionsLoad" v-if="hasTradingAccountsManagement" no-body>
                <template slot="title">
                  Terminal sessions
                  <b-badge pill v-if="sessionsTotalRows > 0" variant="secondary">{{ sessionsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="sessionsResult"
                  :fields-list="sessionsFields"
                  :is-busy="isLoading"
                  :total-rows="sessionsTotalRows"
                  :per-page="sessionsPerPage"
                  :has-details="false"
                  @srch-clicked="onSessionsSrchClicked"
                  @pg-clicked="onSessionsPgClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onCommentsLoad" no-body>
                <template slot="title">
                  Comments
                  <b-badge pill v-if="commentsTotalRows > 0" variant="secondary">{{ commentsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="commentsResult"
                  :fields-list="commentsFields"
                  :is-busy="isLoading"
                  :total-rows="commentsTotalRows"
                  :per-page="commentsPerPage"
                  :has-details="false"
                  @srch-clicked="onCommentsSrchClicked"
                  @pg-clicked="onCommentsPgClicked"
                >
                </common-data-selection-grid>
              </b-tab>
            </b-tabs>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <confirm-dialogue :show-confirm="true" ref="confirmDialogue"></confirm-dialogue>
    <transfer-trading-account-dlg ref="transferTradingAccountDialogue"></transfer-trading-account-dlg>
    <create-withdrawal-dlg ref="createWithdrawalDlg"></create-withdrawal-dlg>
    <edit-leverage-dlg ref="editLeverageDlg"></edit-leverage-dlg>
    <add-comment-dlg ref="addCommentDlg"></add-comment-dlg>
    <router-view />
  </div>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import {
  convertAccountDetails,
  convertOrders,
  convertDeals,
  convertPositions,
  convertTerminalSessions
} from "@/modules/sections/helpers/accounts-converter";
import { convertUsersBonusesList } from "@/modules/sections/helpers/bonuses-converter";
import { convertComments } from "@/modules/sections/helpers/users-converter";

import CommonDataSelectionGrid from "../../../components/grids/common-data-selection-grid";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import DetailsTable from "../../../components/grids/details-table";
import ConfirmDialogue from "../../../components/popups/confirm-dlg";
import TransferTradingAccountDlg from "../../../components/popups/transfer-trading-account-dlg";
import CreateWithdrawalDlg from "../../../components/popups/create-withdrawal-dlg";
import EditLeverageDlg from "../../../components/popups/edit-trading-account-leverage-dlg";
import AddCommentDlg from "../../../components/popups/add-comment-dlg";
import downloadExcel from "vue-json-excel";
import { saveAs } from "file-saver";

import { mapGetters } from "vuex";
const PaymentsRepository = RepositoryFactory.get(RepositoryName.payments);
const TradingAccountsRepository = RepositoryFactory.get(RepositoryName.tradingAccounts);
const ReportsRepository = RepositoryFactory.get(RepositoryName.reports);
const BonusesRepository = RepositoryFactory.get(RepositoryName.bonuses);

const refKeyCommonDataSelectionGrid = "common-data-selection-grid";
const refKeyDetailsTable = "details-table";

export default {
  name: "TradingAccountDetails",
  props: ["id"],
  components: {
    CreateWithdrawalDlg,
    CommonDataSelectionGrid,
    DetailsTable,
    ConfirmDialogue,
    TransferTradingAccountDlg,
    EditLeverageDlg,
    AddCommentDlg,
    downloadExcel
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,
      refKeyDetailsTable,

      tradesPerPage: 20,
      positionsPerPage: 20,
      bonusesPerPage: 20,
      sessionsPerPage: 20,
      commentsPerPage: 20,
      filter: null,

      isLoading: false,

      dealsResult: [],
      ordersResult: [],
      accountResult: [],
      tradesResult: [],
      positionsResult: [],
      bonusesResult: [],
      sessionsResult: [],
      commentsResult: [],

      tradesTotalRows: 0,
      positionsTotalRows: 0,
      bonusesTotalRows: 0,
      sessionsTotalRows: 0,
      commentsTotalRows: 0,

      tradesMask: "",
      positionsMask: "",
      bonusesMask: "",
      sessionsMask: "",
      commentsMask: "",

      userId: null,

      resultRecord: {
        id: "",
        login: "",
        mode: "",
        group: "",
        isEnabled: "",
        currency: "",
        balance: "",
        equity: "",
        dateCreate: "",
        leverage: "",
        server: "",
        serverAccount: ""
      },
      dealsFields: [
        {
          key: "deal",
          label: "Deal",
          tdClass: "sm-field"
        },
        {
          key: "order",
          label: "Order",
          tdClass: "details-fields-class"
        },
        {
          key: "position",
          label: "Position",
          tdClass: "details-fields-class"
        },
        {
          key: "symbol",
          label: "Symbol",
          tdClass: "details-fields-class"
        },
        {
          key: "date",
          label: "Date",
          tdClass: "details-fields-class"
        },
        {
          key: "action",
          label: "Action",
          tdClass: "details-fields-class"
        },
        {
          key: "entry",
          label: "Entry",
          tdClass: "details-fields-class"
        },
        {
          key: "profit",
          label: "Profit",
          tdClass: "details-fields-class"
        },
        {
          key: "commission",
          label: "Commission",
          tdClass: "details-fields-class"
        },
        {
          key: "swap",
          label: "Swap",
          tdClass: "details-fields-class"
        },
        {
          key: "volume",
          label: "Volume (in lots)",
          tdClass: "details-fields-class"
        },
        {
          key: "volumeBase",
          label: "Volume (in Account currency)",
          tdClass: "details-fields-class"
        },
        {
          key: "volumeUsd",
          label: "Volume (in USD)",
          tdClass: "details-fields-class"
        },
        {
          key: "price",
          label: "Price",
          tdClass: "details-fields-class"
        },
        {
          key: "contractSize",
          label: "Contract Size",
          tdClass: "details-fields-class"
        }
      ],
      ordersFields: [
        {
          key: "deal",
          label: "Order",
          tdClass: "sm-field"
        },
        {
          key: "symbol",
          label: "Symbol",
          tdClass: "details-fields-class"
        },
        {
          key: "type",
          label: "Type",
          tdClass: "details-fields-class"
        },
        {
          key: "volume",
          label: "Volume (in lots)",
          tdClass: "details-fields-class"
        },
        {
          key: "volumeBaseOpen",
          label: "Volume opened (in Account currency)",
          tdClass: "details-fields-class"
        },
        {
          key: "volumeBaseClose",
          label: "Volume closed (in Account currency)",
          tdClass: "details-fields-class"
        },
        {
          key: "volumeUSDOpen",
          label: "Volume opened (in USD)",
          tdClass: "details-fields-class"
        },
        {
          key: "volumeUSDClose",
          label: "Volume closed (in USD)",
          tdClass: "details-fields-class"
        },
        {
          key: "dateOpen",
          label: "Date Open",
          tdClass: "details-fields-class"
        },
        {
          key: "priceOpen",
          label: "Price Open",
          tdClass: "details-fields-class"
        },
        {
          key: "dateClose",
          label: "Date Close",
          tdClass: "details-fields-class"
        },
        {
          key: "priceClose",
          label: "Price Close",
          tdClass: "details-fields-class"
        },
        {
          key: "commission",
          label: "Commission",
          tdClass: "details-fields-class"
        },
        {
          key: "swap",
          label: "Swap",
          tdClass: "details-fields-class"
        },
        {
          key: "taxes",
          label: "Taxes",
          tdClass: "details-fields-class"
        },
        {
          key: "profit",
          label: "Profit",
          tdClass: "details-fields-class"
        }
      ],
      positionsFields: [
        {
          key: "dateOpen",
          label: "Open time",
          tdClass: "details-fields-class"
        },
        {
          key: "symbol",
          label: "Symbol",
          tdClass: "details-fields-class"
        },
        {
          key: "type",
          label: "Type",
          tdClass: "details-fields-class"
        },
        {
          key: "volume",
          label: "Volume",
          tdClass: "details-fields-class"
        },
        {
          key: "priceOpen",
          label: "Open price",
          tdClass: "details-fields-class"
        },
        {
          key: "position",
          label: "Position",
          tdClass: "details-fields-class"
        },
        {
          key: "stopLoss",
          label: "S/L",
          tdClass: "details-fields-class"
        },
        {
          key: "takeProfit",
          label: "T/P",
          tdClass: "details-fields-class"
        },
        {
          key: "dateClose",
          label: "Close time",
          tdClass: "details-fields-class"
        },
        {
          key: "priceClose",
          label: "Close price",
          tdClass: "details-fields-class"
        },
        {
          key: "commission",
          label: "Commission",
          tdClass: "details-fields-class"
        },
        {
          key: "swap",
          label: "Swap",
          tdClass: "details-fields-class"
        },
        {
          key: "profit",
          label: "Profit",
          tdClass: "details-fields-class"
        }
      ],
      bonusesFields: [
        {
          key: "user.name",
          label: "User name",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "userBonusTitle",
          label: "Bonus",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "dateStart",
          type: "date",
          label: "Date start",
          sortDirection: "desc",
          tdClass: "dateField"
        },
        {
          key: "dateExpiration",
          type: "date",
          label: "Date expiration",
          sortDirection: "desc",
          tdClass: "dateField"
        },
        {
          key: "status",
          label: "Status",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "accrualMode",
          label: "Accrual mode",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        }
      ],
      sessionsFields: [
        {
          key: "from",
          type: "date",
          label: "Date from",
          sortDirection: "desc",
          tdClass: "dateField"
        },
        {
          key: "to",
          type: "date",
          label: "Date to",
          sortDirection: "desc",
          tdClass: "dateField"
        }
      ],
      commentsFields: [
        {
          key: "date",
          label: "Date",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "comment",
          label: "Comment",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "admin.name",
          label: "Admin",
          sortable: false
        }
      ],
      tradeFields: []
    };
  },
  mounted: async function () {
    await this.getAccountDetails();
  },
  computed: {
    ...mapGetters([
      "hasTransactionsRead",
      "hasTransferFunds",
      "hasTradingAccountsRead",
      "hasBonusesRead",
      "hasTradingAccountsManagement",
      "hasPaymentsCreateWithdrawal"
    ]),
    getArchiveBtnText() {
      return this.resultRecord.status === "Active" ? "Archive account" : "Restore account";
    },
    isDeleted() {
      return this.resultRecord.status === "Deleted";
    },
    isMt5() {
      return this.resultRecord.serverPlatformTitle === "MetaTrader 5";
    },
    isReal() {
      return this.resultRecord.serverTypeTitle === "Real";
    },
    bonusesDetailsFields() {
      return [
        {
          key: "amountAccrued",
          label: "Amount accrued"
        },
        {
          key: "amountAccruedTotal",
          label: "Amount accrued total"
        },
        {
          key: "amountPending",
          label: "Amount pending"
        },
        {
          key: "amountReleased",
          label: "Amount released"
        },
        {
          key: "currency",
          label: "Currency"
        }
      ];
    }
  },
  methods: {
    async onTransactionsBtnClick() {
      await this.$router.push({
        name: "Transactions",
        params: { tradingAccountId: this.id, transactionType: "TradingAccount" },
        query: { tradingAccountId: this.id }
      });
    },
    async onTradesPgClicked(value) {
      this.tradesCurrentPage = value;
      if (this.resultRecord.serverPlatformTitle === "MetaTrader 4") {
        await this.getOrdersResult().catch(error => {
          console.error(error);
        });
      }

      if (this.resultRecord.serverPlatformTitle === "MetaTrader 5") {
        await this.getDealsResult().catch(error => {
          console.error(error);
        });
      }
    },
    async onTradesSrchClicked(textValue) {
      this.tradesMask = textValue;

      await this.onTradesLoad().catch(error => {
        console.error(error);
      });
    },
    async onTradesFilterClicked(value, textMask) {
      this.tradesMask = textMask;

      await this.onTradesLoad().catch(error => {
        console.error(error);
      });
    },
    async onTradesLoadClicked(value) {
      this.tradesPerPage = value;

      await this.onTradesLoad().catch(error => {
        console.error(error);
      });
    },
    async onTradesLoad() {
      if (this.resultRecord.serverPlatformTitle === "MetaTrader 4") {
        this.tradeFields = this.ordersFields;
        await this.getOrdersResult();
      }

      if (this.resultRecord.serverPlatformTitle === "MetaTrader 5") {
        this.tradeFields = this.dealsFields;
        await this.getDealsResult();
      }
    },
    async onTradesDownload() {
      if (this.resultRecord.serverPlatformTitle === "MetaTrader 4") {
        await this.getOrdersReport();
      }

      if (this.resultRecord.serverPlatformTitle === "MetaTrader 5") {
        await this.getDealsReport();
      }
    },
    async onPositionsPgClicked(value) {
      this.positionsCurrentPage = value;
      await this.getPositionsResult().catch(error => {
        console.error(error);
      });
    },
    async onPositionsSrchClicked(textValue) {
      this.positionsMask = textValue;

      await this.getPositionsResult().catch(error => {
        console.error(error);
      });
    },
    async onPositionsFilterClicked(value, textMask) {
      this.positionsMask = textMask;

      await this.getPositionsResult().catch(error => {
        console.error(error);
      });
    },
    async onPositionsLoadClicked(value) {
      this.positionsPerPage = value;

      await this.getPositionsResult().catch(error => {
        console.error(error);
      });
    },
    async onPositionsLoad() {
      await this.getPositionsResult();
    },
    async onBonusesLoad() {
      await this.getBonusesResult();
    },
    async onBonusesPgClicked(value) {
      this.bonusesCurrentPage = value;
      await this.getBonusesResult().catch(error => {
        console.error(error);
      });
    },
    async onBonusesSrchClicked(textValue) {
      this.bonusesMask = textValue;

      await this.getBonusesResult().catch(error => {
        console.error(error);
      });
    },
    async onSessionsLoad() {
      await this.getTerminalSessionsResult();
    },
    async onSessionsPgClicked(value) {
      this.sessionsCurrentPage = value;
      await this.getTerminalSessionsResult().catch(error => {
        console.error(error);
      });
    },
    async onSessionsSrchClicked(textValue) {
      this.sessionsMask = textValue;

      await this.getTerminalSessionsResult().catch(error => {
        console.error(error);
      });
    },
    async onCommentsLoad() {
      await this.getCommentsResult();
    },
    async onCommentsPgClicked(value) {
      this.commentsCurrentPage = value;
      await this.getCommentsResult().catch(error => {
        console.error(error);
      });
    },
    async onCommentsSrchClicked(textValue) {
      this.commentsMask = textValue;

      await this.getCommentsResult().catch(error => {
        console.error(error);
      });
    },
    async getAccountDetails() {
      let resultInfo = await TradingAccountsRepository.getTradingAccountDetails(this.id);

      if (resultInfo.status !== 200) {
        if (resultInfo.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: resultInfo.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      this.userId = resultInfo.data.user.id;

      let result = convertAccountDetails(resultInfo.data);
      this.resultRecord = result;
      this.accountResult = [
        {
          name: "Id",
          value: this.resultRecord.id
        },
        {
          name: "User email",
          value: this.resultRecord.user
        },
        {
          name: "Login",
          value: this.resultRecord.login
        },
        {
          name: "Mode",
          value: this.resultRecord.mode
        },
        {
          name: "Group",
          value: this.resultRecord.group
        },
        {
          name: "Status",
          value: this.resultRecord.status
        },
        {
          name: "Balance",
          value: `${this.resultRecord.balance} ${this.resultRecord.currency}`
        },
        {
          name: "Equity",
          value: `${this.resultRecord.equity} ${this.resultRecord.currency}`
        },
        {
          name: "Credit",
          value: `${this.resultRecord.credit} ${this.resultRecord.currency}`
        },
        {
          name: "Date create",
          value: this.resultRecord.dateCreate
        },
        {
          name: "Leverage",
          value: this.resultRecord.leverage
        },
        {
          name: "Server account",
          value: this.resultRecord.serverAccountTitle
        },
        {
          name: "Server type",
          value: this.resultRecord.serverTypeTitle
        },
        {
          name: "Server name",
          value: this.resultRecord.serverTitle
        },
        {
          name: "Server platform",
          value: this.resultRecord.serverPlatformTitle
        }
      ];
    },
    async getDealsResult() {
      let filter = {
        params: {
          skip: (this.tradesCurrentPage - 1) * this.tradesPerPage,
          take: this.tradesPerPage,
          mask: this.tradesMask,
          tradingAccountId: this.resultRecord.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getMt5Deals(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.tradesTotalRows = axiosResponse.data.total;
      let dealsResponse = convertDeals(axiosResponse.data.items);

      this.tradesResult = dealsResponse;
      this.isLoading = false;
    },
    async getPositionsResult() {
      let filter = {
        params: {
          skip: (this.positionsCurrentPage - 1) * this.positionsPerPage,
          take: this.positionsPerPage,
          mask: this.positionsMask,
          tradingAccountId: this.resultRecord.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getMt5Positions(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.positionsTotalRows = axiosResponse.data.total;
      let positionsResponse = convertPositions(axiosResponse.data.items);

      this.positionsResult = positionsResponse;
      this.isLoading = false;
    },
    async getOrdersResult() {
      let filter = {
        params: {
          skip: (this.tradesCurrentPage - 1) * this.tradesPerPage,
          take: this.tradesPerPage,
          mask: this.tradesMask,
          tradingAccountId: this.resultRecord.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getMt4Orders(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.tradesTotalRows = axiosResponse.data.total;
      let ordersResponse = convertOrders(axiosResponse.data.items);

      this.tradesResult = ordersResponse;
      this.isLoading = false;
    },
    async getBonusesResult() {
      let filter = {
        params: {
          skip: (this.bonusesCurrentPage - 1) * this.bonusesPerPage,
          take: this.bonusesPerPage,
          mask: this.bonusesMask,
          tradingAccountId: this.resultRecord.id
        }
      };

      this.isLoading = true;
      let axiosResponse = await BonusesRepository.getUserBonuses(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.bonusesTotalRows = axiosResponse.data.total;
      let bonusesResponse = convertUsersBonusesList(axiosResponse.data.items);

      this.bonusesResult = bonusesResponse;
      this.isLoading = false;
    },
    async getTerminalSessionsResult() {
      let filter = {
        params: {
          skip: (this.sessionsCurrentPage - 1) * this.sessionsPerPage,
          take: this.sessionsPerPage,
          mask: this.sessionsMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getTerminalSessions(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.sessionsTotalRows = axiosResponse.data.total;
      let sessionsResponse = convertTerminalSessions(axiosResponse.data.items);

      this.sessionsResult = sessionsResponse;
      this.isLoading = false;

      console.log("Done!");
    },
    async getCommentsResult() {
      let filter = {
        params: {
          skip: (this.commentsCurrentPage - 1) * this.commentsPerPage,
          take: this.commentsPerPage,
          mask: this.commentsMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getComments(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.commentsTotalRows = axiosResponse.data.total;
      let commentsResponse = convertComments(axiosResponse.data.items);

      this.commentsResult = commentsResponse;
      this.isLoading = false;

      console.log("Done!");
    },
    async onArchiveBtnClick() {
      let isActive = this.resultRecord.status === "Active";
      let status = isActive ? "archive" : "restore";

      const dlgResult = await this.$refs.confirmDialogue.show({
        title: "Are you sure you want to " + status + " account?",
        message: "",
        okButton: "Confirm"
      });

      if (!dlgResult.isOk) {
        return;
      }

      let request = {
        userId: this.userId
      };

      let axiosResponse = isActive
        ? await TradingAccountsRepository.archiveAccount(this.id, request)
        : await TradingAccountsRepository.restoreAccount(this.id, request);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "Account is " + (isActive ? "archived" : "restored") + " success",
        blockClass: "msg-block"
      });

      this.resultRecord.status = isActive ? "Archived" : "Active";
      await this.getAccountDetails();
    },
    async onDeleteBtnClick() {
      const dlgResult = await this.$refs.confirmDialogue.show({
        title: "Are you sure you want to delete account?",
        message: "",
        okButton: "Confirm"
      });

      if (!dlgResult.isOk) {
        return;
      }

      let axiosResponse = await TradingAccountsRepository.updateAccountStatus(this.id, "Deleted");
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "Account is deleted success",
        blockClass: "msg-block"
      });

      await this.getAccountDetails();
    },
    async onTransferToTradingAccountBtnClick() {
      const dlgResult = await this.$refs.transferTradingAccountDialogue.show({
        currency: this.resultRecord.currency
      });

      if (dlgResult.isOk) {
        let request = {
          amount: dlgResult.amount,
          type: dlgResult.type,
          mode: dlgResult.mode,
          comment: dlgResult.comment,
          needUpdateWithdrawLimit: dlgResult.needUpdateWithdrawLimit
        };

        let axiosResponse = await TradingAccountsRepository.transferFund(this.id, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Transfer is success",
          blockClass: "msg-block"
        });

        return axiosResponse.data;
      }
    },
    async onAddCommentBtnClick() {
      const dlgResult = await this.$refs.addCommentDlg.show({
        message: "Add comment",
        commentRequired: true,
        userEmail: this.resultRecord.user.email,
        login: this.resultRecord.login
      });

      if (dlgResult.isOk) {
        let request = {
          value: dlgResult.comment
        };

        let axiosResponse = await TradingAccountsRepository.addComment(this.id, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Comment created",
          blockClass: "msg-block"
        });
      }

      await this.getAccountDetails();
    },
    async onCreateWithdrawBtnClick() {
      const dlgResult = await this.$refs.createWithdrawalDlg.show({
        userId: this.userId,
        accountId: this.id,
        currency: this.resultRecord.currency
      });

      if (dlgResult.isOk) {
        let request = {
          userId: dlgResult.userId,
          paymentMethodId: dlgResult.paymentMethodId,
          recurringId: dlgResult.recurringId,
          deviceType: dlgResult.deviceType,
          amount: dlgResult.amount,
          fields: dlgResult.fields
        };

        let axiosResponse = await PaymentsRepository.createWithdrawalRequest(this.id, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Success",
          blockClass: "msg-block"
        });

        return axiosResponse.data;
      }
    },
    async onEditLeverageBtnClick() {
      const dlgResult = await this.$refs.editLeverageDlg.show({
        leverage: this.resultRecord.leverage
      });

      if (dlgResult.isOk) {
        let request = {
          userId: this.userId,
          leverage: dlgResult.leverage
        };

        let axiosResponse = await TradingAccountsRepository.changeAccountLeverage(this.id, request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "Success",
          blockClass: "msg-block"
        });

        await this.getAccountDetails();
      }
    },
    async getDealsReport() {
      let filter = {
        isTradingEnabled: null,
        tradingAccountId: this.id,
        tradingServerAccountType: null,
        tradingServerAccountsIds: [],
        tradingServerIds: [],
        dateCreateFrom: null,
        dateCreateTo: null
      };

      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getMt5Deals(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.isLoading = false;

      let currentDate = new Date();
      saveAs(axiosResponse.data, "TradesReport_" + currentDate.toLocaleString() + ".xlsx");
    },
    async getOrdersReport() {
      let filter = {
        isTradingEnabled: null,
        tradingAccountId: this.id,
        tradingServerAccountType: null,
        tradingServerAccountsIds: [],
        tradingServerIds: [],
        dateCreateFrom: null,
        dateCreateTo: null
      };

      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getMt4Orders(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.isLoading = false;

      let currentDate = new Date();
      saveAs(axiosResponse.data, "TradesReport_" + currentDate.toLocaleString() + ".xlsx");
    }
  }
};
</script>
