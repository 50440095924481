import moment from "moment";
import "moment-duration-format";

export function convertLogsList(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.showDetails = true;

      container.ip = item.ip;
      container.group = item.group;
      container.action = item.action;

      if (item.tradingAccount) {
        container.tradingAccountId = item.tradingAccount.id;
        container.login = item.tradingAccount.login;
      }

      container.user = item.user;
      container.admin = item.admin;

      container.date = moment(String(item.date)).utc().format("DD.MM.YYYY HH:mm:ss");
      container.details = [
        {
          title: "Data",
          description: item.data
        }
      ];

      return container;
    });
  }
}
