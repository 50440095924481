<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">User's bonuses</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>

    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="bonusesList"
      :fields-list="fields"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :need-info="true"
      :has-details="true"
      :details-as-table="true"
      :custom-fields="detailsFields"
      @info-clicked="onPgClicked"
      @pg-clicked="onPgClicked"
      @srch-clicked="onSrchClicked"
    >
    </common-data-selection-grid>
  </b-card>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import CommonDataSelectionGrid from "../../../components/grids/common-data-selection-grid";

import { mapGetters } from "vuex";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { convertUsersBonusesList } from "@/modules/sections/helpers/bonuses-converter";

const BonusesRepository = RepositoryFactory.get(RepositoryName.bonuses);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";
export default {
  name: "UsersBonusesList",
  props: ["userId"],
  components: {
    CommonDataSelectionGrid
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,

      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,
      companyId: "",
      selectedBonusId: null,

      bonusesList: [],
      resultData: []
    };
  },
  mounted: async function () {
    await this.getUsersBonuses();
  },
  computed: {
    ...mapGetters(["hasBonusesRead"]),
    fields() {
      return [
        {
          key: "user.name",
          label: "User name",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "userBonusTitle",
          label: "Bonus",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "dateStart",
          type: "date",
          label: "Date start",
          sortDirection: "desc",
          tdClass: "dateField"
        },
        {
          key: "dateExpiration",
          type: "date",
          label: "Date expiration",
          sortDirection: "desc",
          tdClass: "dateField"
        },
        {
          key: "status",
          label: "Status",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "accrualMode",
          label: "Accrual mode",
          sortDirection: "asc",
          tdClass: "fieldsClass"
        },
        {
          key: "actions",
          label: "Actions",
          sortDirection: "asc",
          thStyle: { width: "15rem !important" }
        }
      ];
    },
    detailsFields() {
      return [
        {
          key: "login",
          label: "Trading account"
        },
        {
          key: "amountAccrued",
          label: "Amount accrued"
        },
        {
          key: "amountAccruedTotal",
          label: "Amount accrued total"
        },
        {
          key: "amountPending",
          label: "Amount pending"
        },
        {
          key: "amountReleased",
          label: "Amount released"
        },
        {
          key: "currency",
          label: "Currency"
        }
      ];
    }
  },
  methods: {
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getUsersBonuses().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getUsersBonuses().catch(error => {
        console.error(error);
      });
    },
    async getUsersBonuses() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      let filter = {
        params: {
          userId: this.$route.query.userId,
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.filter
        }
      };

      this.isLoading = true;
      let axiosResponse = await BonusesRepository.getUserBonuses(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let bonusesResponse = axiosResponse.data;

      this.resultData = bonusesResponse.items;
      this.isLoading = false;

      this.bonusesList = convertUsersBonusesList(this.resultData);
    }
  }
};
</script>

<style scoped lang="scss"></style>
