<template>
  <div>
    <b-row>
      <b-col sm="auto">
        <b-card border-variant="light">
          <b-container fluid class="image-wrapper">
            <div class="image mt-3" style="width: 210px; height: 230px">
              <b-img class="w-100" :src="require('../../../assets/bonus.png')" alt="Bonus Logo"></b-img>
            </div>
            <b-row class="mb-2" align-v="center" align-h="center">
              <h5>{{ this.resultRecord.bonus?.title }}</h5>
            </b-row>
            <b-row>
              <b-button class="mb-2" block v-if="hasBonusesManage" variant="danger" @click="onCancelClick"
                >Cancel
              </b-button>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
      <b-col>
        <b-card border-variant="light" no-body>
          <div class="tabs-container">
            <b-tabs content-class="mt-3" pills card>
              <b-tab title="Description" active no-body>
                <details-table
                  :ref="refKeyDetailsTable"
                  :data-result-list="userBonusResult"
                  @remove-clicked="
                    resultRecord.dateExpiration.date = null;
                    resultRecord.dateExpiration.time = null;
                  "
                  @edit-clicked="onEditUserBonus"
                >
                </details-table>
              </b-tab>
              <b-tab @click="onTargetsLoad" no-body>
                <template slot="title">
                  Targets
                  <b-badge pill v-if="targetsTotalRows > 0" variant="secondary">{{ targetsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="targetsResult"
                  :fields-list="targetsFields"
                  :is-busy="isLoading"
                  :total-rows="targetsTotalRows"
                  :per-page="targetsPerPage"
                  :need-per-page="true"
                  @srch-clicked="onTargetsSrchClicked"
                  @pg-clicked="onTargetsPgClicked"
                >
                </common-data-selection-grid>
              </b-tab>
              <b-tab @click="onLogsLoad" no-body>
                <template slot="title">
                  Action logs
                  <b-badge pill v-if="logsTotalRows > 0" variant="secondary">{{ logsTotalRows }}</b-badge>
                </template>
                <common-data-selection-grid
                  :ref="refKeyCommonDataSelectionGrid"
                  :data-result-list="logsResult"
                  :fields-list="logsFields"
                  :is-busy="isLoading"
                  :total-rows="logsTotalRows"
                  :per-page="logsPerPage"
                  :need-per-page="true"
                  :has-details="true"
                  @srch-clicked="onLogsSrchClicked"
                  @pg-clicked="onLogsPgClicked"
                >
                </common-data-selection-grid>
              </b-tab>
            </b-tabs>
          </div>
          <cancel-user-bonus-dlg ref="cancelUserBonusDlg"></cancel-user-bonus-dlg>
        </b-card>
      </b-col>
    </b-row>
    <router-view />
  </div>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";

import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import DetailsTable from "../../../components/grids/details-table";

import CancelUserBonusDlg from "../../../components/popups/cancel-user-bonus-dlg";

import { mapGetters } from "vuex";
import { convertUserBonusDetails, convertTargets } from "@/modules/sections/helpers/bonuses-converter";
import CommonDataSelectionGrid from "@/components/grids/common-data-selection-grid.vue";
import { convertLogsList } from "@/modules/sections/helpers/logs-converter";
import { toISOLocal } from "@/modules/sections/helpers/dates-converter";

const BonusesRepository = RepositoryFactory.get(RepositoryName.bonuses);

const refKeyDetailsTable = "details-table";
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";
export default {
  name: "UserBonusDetails",
  props: ["id"],
  components: { CommonDataSelectionGrid, DetailsTable, CancelUserBonusDlg },
  data() {
    return {
      refKeyDetailsTable,
      refKeyCommonDataSelectionGrid,

      perPage: 10,
      filter: null,

      targetsCurrentPage: 1,
      logsCurrentPage: 1,
      targetsPerPage: 20,
      logsPerPage: 20,
      targetsMask: "",
      logsMask: "",

      isLoading: false,
      targetsTotalRows: 0,
      logsTotalRows: 0,

      userBonusResult: [],
      targetsResult: [],
      logsResult: [],

      resultRecord: {
        id: "",
        title: "",
        user: "",

        dateStart: "",
        dateExpiration: "",
        isActive: "",
        isMultiApplicability: "",
        isRepeatedly: "",

        accrualMode: "",
        applicability: "",
        status: "",

        amountLeft: "",
        amountUsed: "",

        amountSet: "",
        currency: ""
      },
      targetsFields: [
        {
          key: "login",
          label: "Trading account",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "isActive",
          label: "Is active",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "isCancelling",
          label: "Is cancelling",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "amountAccrued",
          label: "Amount accrued",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "amountAccruedTotal",
          label: "Amount accrued total",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "amountPending",
          label: "Amount pending",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "amountReleased",
          label: "Amount released",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "progress",
          label: "Progress",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "currency",
          label: "Currency",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "dateLastAction",
          label: "Date last action",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "dateLastPartialAccrual",
          label: "Date last partial action",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "dateLastPartialCancellation",
          label: "Date last partial cancellation",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        }
      ],
      logsFields: [
        {
          key: "showDetails",
          label: "",
          sortDirection: "desc"
        },
        {
          key: "date",
          label: "Date",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "group",
          label: "Group",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "action",
          label: "Action",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "login",
          label: "Trading account",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "user",
          label: "Person",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "admin",
          label: "Action by user",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        }
      ]
    };
  },
  mounted: async function () {
    await this.getBonusDetails();
  },
  computed: {
    ...mapGetters(["hasBonusesManage"])
  },
  methods: {
    async onTargetsSrchClicked(textValue) {
      this.targetsMask = textValue;

      await this.getTargetsResult().catch(error => {
        console.error(error);
      });
    },
    async onTargetsPgClicked(value) {
      this.targetsCurrentPage = value;

      await this.getTargetsResult.catch(error => {
        console.error(error);
      });
    },
    async onTargetsLoad() {
      await this.getTargetsResult();
    },
    async onLogsSrchClicked(textValue) {
      this.logsMask = textValue;

      await this.getBonusLogsResult().catch(error => {
        console.error(error);
      });
    },
    async onLogsPgClicked(value) {
      this.logsCurrentPage = value;

      await this.getBonusLogsResult().catch(error => {
        console.error(error);
      });
    },
    async onLogsLoad() {
      await this.getBonusLogsResult();
    },
    async onCancelClick() {
      const dlgResult = await this.$refs.cancelUserBonusDlg.show({
        message: "Cancel user bonus",
        userBonusId: this.resultRecord.id
      });

      if (dlgResult.isOk) {
        let axiosResponse = await BonusesRepository.cancelUserBonus(this.resultRecord.id, dlgResult.targetId ?? "");
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        }

        this.flashMessage.success({
          message: "User bonus canceled success",
          blockClass: "msg-block"
        });
        await this.getTournaments();
      }
      this.isLoading = false;
    },
    async onEditUserBonus(item) {
      let request = {
        dateExpiration: toISOLocal(new Date(item.value.date + " " + item.value.time))
      };

      let axiosResponse = await BonusesRepository.editUserBonus(this.id, request);
      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });
      } else {
        await this.getBonusDetails();
        this.flashMessage.success({
          message: "User bonus updated success",
          blockClass: "msg-block"
        });
      }
    },
    async getTargetsResult() {
      let filter = {
        params: {
          skip: (this.targetsCurrentPage - 1) * this.targetsPerPage,
          take: this.targetsPerPage,
          mask: this.targetsMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await BonusesRepository.getUserBonusTargets(this.id, filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.targetsTotalRows = axiosResponse.data.total;
      let targetsResponse = axiosResponse.data;
      this.isLoading = false;

      this.targetsResult = convertTargets(targetsResponse.items);
      console.log("Done!");
    },
    async getBonusLogsResult() {
      let filter = {
        params: {
          skip: (this.logsCurrentPage - 1) * this.logsPerPage,
          take: this.logsPerPage,
          mask: this.logsMask
        }
      };

      this.isLoading = true;
      let axiosResponse = await BonusesRepository.getUserBonusLogs(
        this.resultRecord.user.id,
        this.resultRecord.bonus.id,
        filter
      );

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.logsTotalRows = axiosResponse.data.total;
      let logsResponse = axiosResponse.data;
      this.isLoading = false;

      this.logsResult = convertLogsList(logsResponse.items);
      console.log("Done!");
    },
    async getBonusDetails() {
      let resultInfo = await BonusesRepository.getUserBonusDetails(this.id);

      if (resultInfo.status !== 200) {
        if (resultInfo.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: resultInfo.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      let result = convertUserBonusDetails(resultInfo.data);

      this.resultRecord = result;
      this.userBonusResult = [
        {
          name: "Id",
          value: this.resultRecord.id
        },
        {
          name: "Bonus",
          value: this.resultRecord?.bonus ?? ""
        },
        {
          name: "User name",
          value: this.resultRecord.user
        },
        {
          name: "Date start",
          value: this.resultRecord.dateStart
        },
        {
          name: "Date expiration",
          value: this.resultRecord.dateExpiration,
          isEditable: true,
          onEdit: false
        },
        {
          name: "Is active",
          value: this.resultRecord.isActive
        },
        {
          name: "Is multi applicability",
          value: this.resultRecord.isMultiApplicability
        },
        {
          name: "Is repeatedly",
          value: this.resultRecord.isRepeatedly
        },
        {
          name: "Is freezed",
          value: this.resultRecord.isFreezed
        },
        {
          name: "Amount set",
          value: this.resultRecord.amountSet
        },
        {
          name: "Amount left",
          value: this.resultRecord.amountLeft
        },
        {
          name: "Amount used",
          value: this.resultRecord.amountUsed
        },
        {
          name: "Currency",
          value: this.resultRecord.currency
        },
        {
          name: "Applicability",
          value: this.resultRecord.applicability
        },
        {
          name: "Accrual Mode",
          value: this.resultRecord.accrualMode
        }
      ];
    }
  }
};
</script>

<style scoped lang="scss"></style>
