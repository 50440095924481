import Repository from "./repository-base";

const resource = "admin/reports";

export default {
  getUsers(filter) {
    return Repository.post(`${resource}/users`, filter, {
      responseType: "blob"
    });
  },
  getUsersFilterInfo() {
    return Repository.get(`${resource}/users/filters`);
  },
  getTransactions(filter) {
    return Repository.post(`${resource}/transactions`, filter, {
      responseType: "blob"
    });
  },
  getTransactionsFilterInfo() {
    return Repository.get(`${resource}/transactions/filters`);
  },
  getWithdrawals(filter) {
    return Repository.post(`${resource}/withdrawals`, filter, {
      responseType: "blob"
    });
  },
  getWithdrawalsFilterInfo() {
    return Repository.get(`${resource}/withdrawals/filters`);
  },
  getTrading(filter) {
    return Repository.post(`${resource}/trading`, filter, {
      responseType: "blob"
    });
  },
  getMt5Deals(filter) {
    return Repository.post(`${resource}/mt5/deals`, filter, {
      responseType: "blob"
    });
  },
  getMt4Orders(filter) {
    return Repository.post(`${resource}/mt4/orders`, filter, {
      responseType: "blob"
    });
  },
  getTradingAccounts(filter) {
    return Repository.post(`${resource}/accounts`, filter, {
      responseType: "blob"
    });
  },
  getTradingFilters() {
    return Repository.get(`${resource}/trading/filters`);
  },
  getFinancialStatistic(filter) {
    return Repository.post(`${resource}/financial/statistic`, filter, {
      responseType: "blob"
    });
  },
  getFinancialStatisticInfo(filter) {
    return Repository.get(`${resource}/financial/statistic/total`, filter);
  },
  getFinancialStatisticFilters() {
    return Repository.get(`${resource}/financial/statistic/filters`);
  }
};
