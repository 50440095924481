<template>
  <popup-modal ref="popup">
    <h2 class="title mb-4"><b>Transfer funds</b></h2>
    <b-container class="popup" fluid>
      <b-row class="mb-3 align-items-center">
        <b-col sm="3 text-sm-left">
          <label
            ><b>Amount ({{ currency }})</b></label
          >
        </b-col>
        <b-col>
          <b-input :state="isAmountStateValid" v-model="amount" placeholder="Enter amount" />
        </b-col>
      </b-row>

      <b-row class="mb-3 align-items-center">
        <b-col sm="3 text-sm-left">
          <label><b>Comment</b></label>
        </b-col>
        <b-col>
          <b-form-input v-model="comment" list="input-list"></b-form-input>
          <b-form-datalist id="input-list" :options="$options.commentOptions"></b-form-datalist>
        </b-col>
      </b-row>

      <b-row class="mb-3 align-items-center">
        <b-col sm="3 text-sm-left">
          <label>
            <b>Type</b>
          </label>
        </b-col>
        <b-col sm="auto">
          <b-form-radio-group v-model="mode">
            <b-form-radio value="Balance">Balance</b-form-radio>
            <b-form-radio value="Credit">Credit</b-form-radio>
          </b-form-radio-group>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col sm="auto text-sm-left">
          <label>
            <b>Update withdraw limits</b>
          </label>
        </b-col>
        <b-col sm="auto">
          <b-form-checkbox v-model="needUpdateLimit"> </b-form-checkbox>
        </b-col>
      </b-row>

      <div class="btns">
        <b-button variant="primary" @click="_transfer('Deposit')">Deposit</b-button>
        <b-button variant="info" @click="_transfer('Withdrawal')">Withdraw</b-button>
        <b-button variant="dark" @click="_cancel">{{ cancelButton }}</b-button>
      </div>
    </b-container>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";

export default {
  name: "TransferTradingAccountDialogue",
  components: { PopupModal },
  commentOptions: ["Deposit", "Withdrawal", "Credit In", "Credit Out"],

  data: () => ({
    accountsList: [],
    cancelButton: "Close", // text for cancel button
    amount: null,
    currency: null,
    mode: "Balance",
    comment: null,
    needUpdateLimit: true,

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined
  }),

  computed: {
    isAmountStateValid() {
      if (this.amount) {
        return this.isValid(this.amount);
      }
      return null;
    }
  },
  methods: {
    isValid() {
      return this.amount.match(/^[0-9]{1,10}([,.][0-9]{1,5})?$/g) !== null;
    },
    async show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.currency = opts.currency;
      this.depositButton = opts.depositButton;
      this.withdrawButton = opts.withdrawButton;

      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }

      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _transfer(type) {
      this.$refs.popup.close();

      const resultItems = {
        isOk: true,
        amount: this.amount,
        type,
        mode: this.mode,
        comment: this.comment,
        needUpdateWithdrawLimit: this.needUpdateLimit
      };

      this.resetState();
      this.resolvePromise(resultItems);
    },

    _cancel() {
      this.resetState();
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    resetState() {
      this.amount = null;
      this.currency = null;
      this.mode = "Balance";
      this.comment = null;
      this.needUpdateLimit = true;
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popup {
  @include media-breakpoint-tablet() {
    width: 35rem;
  }
}
</style>
