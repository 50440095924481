<template>
  <div class="login-container">
    <b-card border-variant="light">
      <b-card-header>
        <h4>Login</h4>
      </b-card-header>
      <b-card-body class="card-body">
        <b-form-row>
          <b-form-input
            class="input-item"
            v-model="authUserEmail"
            placeholder="Enter your email"
            @keyup.enter="onClickUserLogin"
          />
          <b-form-input
            class="input-item"
            v-model="password"
            type="password"
            placeholder="Enter password"
            @keyup.enter="onClickUserLogin"
          />
          <b-form-input
            class="input-item"
            v-model="twoFactorCode"
            :state="isTwoFactorStateValid"
            placeholder="Enter 2FA code (optional)"
            @keyup.enter="onClickUserLogin"
          />
          <b-form-checkbox class="input-item" v-model="rememberMe">Remember me</b-form-checkbox>
          <b-button variant="dark" size="lg" block class="input-item" type="button" @click="onClickUserLogin">
            Sign In
          </b-button>
        </b-form-row>
        <b-row v-if="isLoading" class="load-row">
          <b-spinner :variant="'dark'"></b-spinner>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import cookie from "js-cookie";
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import router from "../route/routes";
import { routePaths } from "../route/routes";
import { mapActions } from "vuex";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";

const AuthRepository = RepositoryFactory.get(RepositoryName.auth);
const ProfileRepository = RepositoryFactory.get(RepositoryName.profile);
const PlatformRepository = RepositoryFactory.get(RepositoryName.platform);

export default {
  name: "login",
  data() {
    return {
      authUserEmail: "",
      password: "",
      twoFactorCode: "",
      captchaToken: "",
      rememberMe: false,

      resultData: "",
      isLoading: false
    };
  },
  computed: {
    isTwoFactorStateValid() {
      if (this.twoFactorCode) {
        return this.isValid(this.twoFactorCode);
      }
      return null;
    }
  },
  methods: {
    ...mapActions(["setUserAuthenticated", "setUserProfile", "setPlatformFeatures"]),
    isValid(value) {
      return value.length <= 20;
    },
    async onClickUserLogin() {
      await this.getCaptcha();
      await this.authUserLogin();
    },
    async getPlatformInfo() {
      let axiosResponse = await PlatformRepository.getPlatformInfo();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      let platformResponse = axiosResponse.data;
      return platformResponse.captcha.googleReCaptchaV3Info.key;
    },
    async getCaptcha() {
      this.isLoading = true;

      await this.$recaptchaLoaded();
      this.captchaToken = await this.$recaptcha("login");

      this.isLoading = false;
    },
    async authUserLogin() {
      const data = {
        email: this.authUserEmail,
        password: this.password,
        rememberMe: this.rememberMe,
        twoFactorCode: this.twoFactorCode,
        recoveryCode: this.recoveryCode,
        captcha: {
          challengeGoogleRecaptchaV3: {
            token: this.captchaToken
          }
        }
      };

      this.isLoading = true;
      let axiosResponse = await AuthRepository.login(data);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      let authResponse = axiosResponse.data;
      this.resultData = authResponse;
      this.authUserEmail = "";
      this.password = "";

      this.isLoading = false;

      cookie.set("authToken", authResponse, { expires: 1000 });
      let profile = await this.getUserProfile();

      if (profile) {
        this.setUserAuthenticated(true);
        this.setUserProfile(profile);

        await router.push(routePaths.homePage);
      }
    },
    async getUserProfile() {
      this.isLoading = true;
      let axiosResponse = await ProfileRepository.getProfile();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403 || axiosResponse.status === 401) {
          this.flashMessage.error({
            message: "Access denied",
            blockClass: "msg-block"
          });
        } else {
          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });
        }

        this.isLoading = false;
        return null;
      }

      var response = axiosResponse;
      return response.data;
    }
  }
};
</script>

<style scoped lang="scss">
.login-container {
  max-width: 700px;
  margin: 0 auto;
  @include media-breakpoint-tablet() {
    margin-top: 150px;
  }
}

.card-body {
  @include media-breakpoint-tablet() {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  }
}

.load-row {
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
</style>
