<template>
  <popup-modal ref="popup">
    <h4 class="mb-4">
      <b>{{ message }}</b>
    </h4>
    <b-container class="popup" fluid>
      <b-row class="mb-4">
        <b-col sm="3 text-sm-left mt-2">
          <label><b>User: </b></label>
        </b-col>
        <b-col>
          <b-form-input v-model="selectedUser" list="users-list" @change="getAccounts" placeholder="Select a user">
          </b-form-input>
          <b-form-datalist id="users-list" :options="usersList" text-field="email" />
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col sm="3 text-sm-left">
          <label><b>Trading account: </b></label>
        </b-col>
        <b-col>
          <b-form-input v-model="selectedAccount" list="account-list" placeholder="Select a trading account">
          </b-form-input>
          <b-form-datalist id="account-list" :options="accountsList" text-field="login" />
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col sm="3 text-sm-left">
          <label><b>Comment: </b></label>
        </b-col>
        <b-col>
          <b-input v-model="comment" />
        </b-col>
      </b-row>
    </b-container>
    <div class="btns mt-3">
      <b-button variant="primary" :disabled="!selectedAccount" @click="onSave">Save</b-button>
      <b-button variant="dark" @click="_cancel">Cancel</b-button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";

const UsersRepository = RepositoryFactory.get(RepositoryName.users);
const TradingAccountsRepository = RepositoryFactory.get(RepositoryName.tradingAccounts);
export default {
  name: "AttachToTournamentDlg",
  components: { PopupModal },
  data() {
    return {
      message: "",
      comment: "",
      tradingAccountId: "",
      serverType: "",

      usersList: [],
      accountsList: [],
      selectedUser: "",
      selectedAccount: "",

      updatedData: {
        isOk: false,
        tradingAccountId: "",
        comment: ""
      }
    };
  },
  async mounted() {
    await this.getUsers();
  },
  methods: {
    onSave() {
      let tradingAccountId = this.accountsList.find(x => x.login.toString() === this.selectedAccount).id;
      this.updatedData = {
        isOk: true,
        tradingAccountId: tradingAccountId,
        comment: this.comment
      };

      this.resolvePromise(this.updatedData);
      this.resetState();
      this.$refs.popup.close();
    },
    show(opts = {}) {
      this.message = opts.message;
      this.serverType = opts.serverType;
      this.$refs.popup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.resetState();
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    resetState() {
      this.selectedUser = "";
      this.selectedAccount = "";
      this.comment = "";
    },

    //#region Get
    async getUsers() {
      console.log("Start receiving users..");

      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.mask
        }
      };

      let resultInfo = await UsersRepository.getUsers(filter);
      if (resultInfo.status !== 200) {
        if (resultInfo.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: resultInfo.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      this.usersList = resultInfo.data.items;
      console.log("Done!");
    },
    async getAccounts() {
      let userId = this.usersList.find(x => x.email === this.selectedUser).id;
      let filter = {
        params: {
          userId: userId,
          serverType: this.serverType
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getAccounts(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let accountsResponse = axiosResponse.data;

      this.accountsList = accountsResponse.items;
      this.isLoading = false;
    }

    //endregion
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.popup {
  @include media-breakpoint-tablet() {
    width: 30rem;
  }
}
</style>
