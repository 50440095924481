import Vue from "vue";
import VueRouter from "vue-router";
import store from "../../store";

//Lists
import UsersList from "../sections/lists/users-list";
import DepositsList from "../sections/lists/deposits-list";
import WithdrawalsList from "../sections/lists/withdrawals-list";
import AmlChecksList from "../sections/lists/aml-checks-list";
import TradingAccountsList from "../sections/lists/trading-accounts-list";
import TransactionsList from "../sections/lists/transactions-list";
import ExpiredPolList from "../sections/lists/expired-pol-list";
import TournamentsList from "../sections/lists/tournaments-list";
import BonusesList from "../sections/lists/bonuses-list";
import UsersBonusesList from "../sections/lists/users-bonuses-list";

//Details
import UserDetails from "../sections/details/user-details";
import BonusDetails from "../sections/details/bonus-details";
import UserBonusDetails from "../sections/details/user-bonus-details";
import TradingAccountDetails from "../sections/details/trading-account-details";

import CreateBonus from "../sections/create-pages/create-bonus.page";

//Settings
import LogsList from "../sections/lists/settings/logs-list";
import PermissionsList from "../sections/lists/settings/permissions-list";
import EmailsList from "../sections/lists/settings/emails-list";
import DuplicatesList from "../sections/lists/settings/users-duplicates-list";

//Reports
import Reports from "../sections/reports/reports-page";

//Auth
import AuthLogin from "../auth/login";
import AuthSignup from "../auth/signup";
import HomePage from "../sections/home-page";

Vue.use(VueRouter);

export const routePaths = {
  userDetails: "/user-details/:id",
  bonusDetails: "/bonus-details/:id",
  userBonusDetails: "/user-bonus-details/:id",
  tradingAccountDetails: "/trading-account-details/:id",
  createBonus: "/create-bonus",

  usersLists: "/users-list/",
  accountsList: "/trading-accounts-list/",
  transactionsList: "/transactions-list/",
  logsLists: "/logs-list/",
  permissionsLists: "/permissions-list/",
  emailsLists: "/emails-list/",
  tournamentsList: "/tournaments-list/",
  bonusesList: "/bonuses-list/",
  usersBonusesList: "/users-bonuses-list/",
  duplicatesList: "/users-duplicates-list/",

  deposits: "/deposits",
  withdrawals: "/withdrawals",
  amlChecks: "/aml-checks",
  expiredPolList: "/expired-pol-list",

  reports: "/reports",
  authLogin: "/login",
  authSignup: "/sign-up",
  homePage: "/"
};

let router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: routePaths.authLogin,
      component: AuthLogin,
      name: "Login",
      meta: { title: "LogIn" },
      beforeEnter: (_, __, next) => {
        if (store.getters.userIsAuthenticated) {
          next({ name: "Home" });
        } else {
          next();
        }
      }
    },
    {
      path: routePaths.authSignup,
      component: AuthSignup,
      name: "Signup",
      meta: {
        title: "SignUp",
        requiresAuth: false
      }
    },
    {
      name: "Home",
      path: routePaths.homePage,
      component: HomePage,
      alias: "/home",
      meta: {
        title: "Home",
        requiresAuth: true
      }
    },
    {
      name: "Users",
      path: routePaths.usersLists,
      component: UsersList,
      meta: {
        title: "Users",
        requiresAuth: true
      },
      props: true
    },
    {
      name: "Deposits",
      path: routePaths.deposits,
      component: DepositsList,
      meta: {
        title: "Deposits",
        requiresAuth: true
      }
    },
    {
      name: "Withdrawals",
      path: routePaths.withdrawals,
      component: WithdrawalsList,
      meta: {
        title: "Withdrawals",
        requiresAuth: true
      }
    },
    {
      name: "AmlChecks",
      path: routePaths.amlChecks,
      component: AmlChecksList,
      meta: {
        title: "AmlChecks",
        requiresAuth: true
      }
    },
    {
      name: "ExpiredPol",
      path: routePaths.expiredPolList,
      component: ExpiredPolList,
      meta: {
        title: "Expired Pol",
        requiresAuth: true
      }
    },
    {
      name: "Trading Accounts",
      path: routePaths.accountsList,
      component: TradingAccountsList,
      meta: {
        title: "Trading Accounts",
        requiresAuth: true
      },
      props: true
    },
    {
      name: "Transactions",
      path: routePaths.transactionsList,
      component: TransactionsList,
      meta: {
        title: "Transactions",
        requiresAuth: true
      },
      props: true
    },
    {
      name: "Logs",
      path: routePaths.logsLists,
      component: LogsList,
      meta: {
        title: "Logs",
        requiresAuth: true
      },
      props: true
    },
    {
      name: "Emails",
      path: routePaths.emailsLists,
      component: EmailsList,
      meta: {
        title: "Emails",
        requiresAuth: true
      },
      props: true
    },
    {
      name: "Permissions",
      path: routePaths.permissionsLists,
      component: PermissionsList,
      meta: {
        title: "Permissions",
        requiresAuth: true
      },
      props: true
    },
    {
      name: "Duplicates",
      path: routePaths.duplicatesList,
      component: DuplicatesList,
      meta: {
        title: "User's duplicates",
        requiresAuth: true
      },
      props: true
    },
    {
      name: "User Details",
      path: routePaths.userDetails,
      component: UserDetails,
      props: true,
      meta: {
        title: "User Details",
        requiresAuth: true
      }
    },
    {
      name: "Trading Account Details",
      path: routePaths.tradingAccountDetails,
      component: TradingAccountDetails,
      props: true,
      meta: {
        title: "Trading Account Details",
        requiresAuth: true
      }
    },
    {
      name: "Bonus Details",
      path: routePaths.bonusDetails,
      component: BonusDetails,
      props: true,
      meta: {
        title: "Bonus Details",
        requiresAuth: true
      }
    },
    {
      name: "User Bonus Details",
      path: routePaths.userBonusDetails,
      component: UserBonusDetails,
      props: true,
      meta: {
        title: "User Bonus Details",
        requiresAuth: true
      }
    },
    {
      name: "Reports",
      path: routePaths.reports,
      component: Reports,
      props: true,
      meta: {
        title: "Reports",
        requiresAuth: true
      }
    },
    {
      name: "Trading Tournaments",
      path: routePaths.tournamentsList,
      component: TournamentsList,
      meta: {
        title: "Trading Tournaments",
        requiresAuth: true
      },
      props: true
    },
    {
      name: "Bonuses",
      path: routePaths.bonusesList,
      component: BonusesList,
      meta: {
        title: "Bonuses",
        requiresAuth: true
      },
      props: true
    },
    {
      name: "UsersBonuses",
      path: routePaths.usersBonusesList,
      component: UsersBonusesList,
      meta: {
        title: "User's bonuses",
        requiresAuth: true
      },
      props: true
    },
    {
      name: "Create bonus",
      path: routePaths.createBonus,
      component: CreateBonus,
      props: true,
      meta: {
        title: "Create bonus",
        requiresAuth: true
      }
    }
  ]
});

router.beforeEach((toRoute, fromRoute, next) => {
  let title = toRoute.meta.title;
  document.title = `${title} - Admin` || "Admin";
  next();
});

router.beforeEach((to, from, next) => {
  //https://router.vuejs.org/guide/advanced/meta.html
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.name !== "Login" && !store.getters.userIsAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
