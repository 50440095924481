<template>
  <popup-modal ref="popup">
    <h4 class="mb-4">
      <b>{{ message }}</b>
    </h4>
    <b-container class="popup" fluid>
      <b-row class="mb-4">
        <b-col sm="3 text-sm-left">
          <label><b>Title: </b></label>
        </b-col>
        <b-col>
          <b-input :state="title.trim().length > 0" v-model="title" />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="3 text-sm-left mt-2">
          <label><b>Utm source: </b></label>
        </b-col>
        <b-col>
          <b-input-group>
            <b-input :state="utmSource.trim().length > 0" v-model="utmSource" />
            <b-input-group-append>
              <b-button variant="border border-dark" size="sm" @click="generateUtmSource">
                <b-icon icon="plus-circle" variant="dark"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="3 mt-2 text-sm-left">
          <label><b>Type: </b></label>
        </b-col>
        <b-col>
          <b-form-select
            v-model="tradingAccountType"
            :state="tradingAccountType !== null"
            :options="tradingAccountTypes"
          />
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="3 text-sm-left">
          <label><b>Leaders count: </b></label>
        </b-col>
        <b-col>
          <b-input :state="leadersCount > 0" v-model="leadersCount" />
        </b-col>
      </b-row>
    </b-container>
    <div class="btns mt-3">
      <b-button variant="primary" @click="onSave" :disabled="title.trim().length <= 0">Save</b-button>
      <b-button variant="dark" class="ml-2" @click="_cancel">Cancel</b-button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";
import { uuid } from "vue-uuid";
export default {
  name: "AddTournamentDlg",
  components: { PopupModal },
  data() {
    return {
      message: "",
      tradingAccountTypes: ["Real", "Demo"],

      title: "",
      utmSource: "",
      tradingAccountType: null,
      leadersCount: 0,

      updatedData: {
        isOk: false,
        title: "",
        utmSource: "",
        tradingAccountType: null,
        leadersCount: 0
      }
    };
  },
  methods: {
    generateUtmSource() {
      this.utmSource = uuid.v4();
    },
    onSave() {
      this.updatedData = {
        isOk: true,
        title: this.title,
        utmSource: this.utmSource,
        leadersCount: this.leadersCount,
        tradingAccountType: this.tradingAccountType
      };

      this.resolvePromise(this.updatedData);
      this.resetState();

      this.$refs.popup.close();
    },
    show(opts = {}) {
      this.message = opts.message;

      this.$refs.popup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.resetState();
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    resetState() {
      this.title = "";
      this.utmSource = "";
      this.tradingAccountType = "";
      this.leadersCount = 0;
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.popup {
  @include media-breakpoint-tablet() {
    width: 35rem;
  }
}
</style>
