<template>
  <div id="app">
    <FlashMessage :position="'right bottom'"></FlashMessage>
    <div class="bg-panel"></div>
    <template v-if="!loading">
      <header class="app-header">
        <NavMenu />
      </header>
      <main class="app-container">
        <router-view></router-view>
      </main>
    </template>
  </div>
</template>

<script>
import cookie from "js-cookie";

import NavMenu from "./components/layout/nav-menu";
import { RepositoryFactory, RepositoryName } from "./api/repositories/repository-factory";
import { mapActions } from "vuex";

const ProfileRepository = RepositoryFactory.get(RepositoryName.profile);

export default {
  name: "App",
  components: {
    NavMenu
  },
  data: () => ({ loading: false }),
  async created() {
    this.loading = true;
    const tokenExist = !!cookie.get("authToken");
    if (tokenExist) {
      this.setUserAuthenticated(true);
      await this.authUserLogin();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions(["setUserAuthenticated", "setUserProfile", "setPlatformFeatures"]),
    async authUserLogin() {
      var profile = await this.getUserProfile();

      if (profile) {
        this.setUserProfile(profile);
      }
    },
    async getUserProfile() {
      let axiosResponse = await ProfileRepository.getProfile();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403 || axiosResponse.status === 401) {
          this.flashMessage.error({
            message: "Access denied",
            blockClass: "msg-block"
          });
        } else {
          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });
        }

        this.isLoading = false;
        return null;
      }

      var response = axiosResponse;
      return response.data;
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  padding-top: 190px;
  padding-bottom: 54px;
  min-height: 100vh;

  @include media-breakpoint-tablet() {
    padding-top: 135px;
  }
}

.bg-panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  min-height: 300px;
  z-index: 0;
  background-color: $primary-color;
  background: linear-gradient(87deg, $primary-color, $primary-states-color) !important;
}

.app-header {
  background-color: $primary-color;
  background: linear-gradient(87deg, $primary-color, $primary-states-color);
  position: fixed;
  width: 100%;
  z-index: 10;
}
</style>
