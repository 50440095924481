<template>
  <popup-modal ref="popup">
    <h2 class="title" style="max-width: 20rem">{{ title }}</h2>
    <h3 class="pre-formatted mb-2">{{ message }}</h3>
    <div class="btns mt-2">
      <b-button variant="success" @click="_confirm" v-if="showConfirm">{{ okButton }}</b-button>
      <b-button variant="dark" @click="_cancel">{{ cancelButton }}</b-button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";

export default {
  name: "ConfirmDialogue",
  components: { PopupModal },
  props: ["showConfirm"],
  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "Go Back", // text for cancel button
    confirmCode: "",

    resultItems: {
      isOk: false,
      confirmCode: ""
    },
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined
  }),
  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;

      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();

      this.resultItems = {
        isOk: true
      };

      this.resolvePromise(this.resultItems);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.title {
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: sans-serif;
}

.pre-formatted {
  white-space: pre;
  font-size: 1.2rem;
  text-align: left;
}
</style>
