<template>
  <popup-modal ref="popup">
    <h2 class="title">Confirm 2FA</h2>

    <b-form>
      <b-form-input v-model="twoFaCode" class="mb-3" placeholder="Enter 2FA code"></b-form-input>

      <b-button v-b-toggle.collapse-3 variant="link">Forgot 2Fa?</b-button>
      <b-collapse id="collapse-3">
        <b-form-input v-model="recoveryCode" class="mb-4 mt-2" placeholder="Enter recovery code"></b-form-input>
      </b-collapse>
    </b-form>

    <div class="btns mt-1">
      <b-button variant="primary" @click="_confirm">Confirm</b-button>
      <b-button variant="dark" @click="_cancel">Close</b-button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";

export default {
  name: "Confirm2faDlg",
  components: {
    PopupModal
  },
  data: () => ({
    twoFaCode: "",
    recoveryCode: "",

    resultItems: {
      isOk: false,
      twoFaCode: "",
      recoveryCode: ""
    },
    resolvePromise: undefined,
    rejectPromise: undefined
  }),
  methods: {
    show() {
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();

      this.resultItems = {
        isOk: true,
        twoFaCode: this.twoFaCode,
        recoveryCode: this.recoveryCode
      };

      this.resolvePromise(this.resultItems);
    },

    _cancel() {
      this.twoFaCode = "";
      this.recoveryCode = "";

      this.$refs.popup.close();
      this.resolvePromise(false);
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.title {
  margin-bottom: 2rem;
  font-weight: bold;
  font-family: sans-serif;
}
</style>
