<template>
  <div :class="className">
    <b-container v-if="isSearched" fluid>
      <b-row>
        <b-col>
          <b-form-group label-for="filter-input" class="mb-0">
            <b-input-group size="default">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
                v-on:keyup="onBtnSearchClick"
              >
              </b-form-input>
              <b-input-group-prepend>
                <b-button type="button" @click="onCleanFiltersClick" variant="light">
                  <b-icon icon="x-square" scale="1.1" variant="dark"></b-icon>
                </b-button>
              </b-input-group-prepend>
              <b-button style="width: 100px" type="button" @click="onBtnSearchClick" variant="primary">Search</b-button>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="checkboxFilters">
        <b-col>
          <b-button
            variant="light"
            v-b-toggle="'filter-collapse'"
            style="margin-left: 0; display: flex"
            fluid="10"
            class="mt-3"
            >Filters</b-button
          >
          <b-collapse id="filter-collapse" class="mt-2">
            <b-card border-variant="light">
              <b-container style="margin-left: 0; display: flex" fluid="10">
                <b-row v-for="(item, key) in getFilters()" :key="key">
                  <b-col cols="sm" style="margin-left: 0; margin-right: 20px">
                    <b-form-group class="px-4 border rounded-sm d-flex flex-column mx-auto">
                      <div>
                        <p style="font-size: 16px; font-weight: bold" class="mb-2 mt-2">{{ item.filterMessage }}</p>
                      </div>
                      <b-form-radio
                        v-for="(opt, index) in item.items"
                        :key="index"
                        :value="opt"
                        v-model="selectedCheckBox"
                        class="mb-1 d-flex"
                        @change="onCheckboxChanged(opt, item)"
                        >{{ opt }}
                      </b-form-radio>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
    <b-form-group label-for="filter-input" class="mb-0 overflow-scroll">
      <b-table
        id="data-table"
        style="overflow: visible"
        ref="table"
        class="mt-3 table-condensed"
        responsive
        :no-local-sorting="true"
        :busy="isBusy"
        :items="getResult"
        :fields="fieldsList"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @sort-changed="onSortChanged"
        @row-clicked="expandAdditionalInfo"
        show-empty
      >
        <template v-if="isFiltered" slot="top-row" slot-scope="{ fields }">
          <td v-for="field in fields" :key="field.key" style="padding: 0.3rem">
            <slot v-if="field.key === 'dateRange'">
              <b-form-datepicker
                :value="sourceFilterModel('dateRangeStart')"
                @input="onFilterValueChange($event, 'dateRangeStart')"
                button-only
              />
              -
              <b-form-datepicker
                :value="sourceFilterModel('dateRangeEnd')"
                @input="onFilterValueChange($event, 'dateRangeEnd')"
                button-only
              />
            </slot>
            <slot v-else-if="field.key === 'calculatedDate'">
              <b-form-datepicker
                :value="sourceFilterModel('calculatedDateStart')"
                @input="onFilterValueChange($event, 'calculatedDateStart')"
                button-only
              />
              -
              <b-form-datepicker
                :value="sourceFilterModel('calculatedDateEnd')"
                @input="onFilterValueChange($event, 'calculatedDateEnd')"
                button-only
              />
            </slot>
            <slot v-else-if="field.key === 'accruedDate'">
              <b-form-datepicker
                :value="sourceFilterModel('accruedDateStart')"
                @input="onFilterValueChange($event, 'accruedDateStart')"
                button-only
              />
              -
              <b-form-datepicker
                :value="sourceFilterModel('accruedDateEnd')"
                @input="onFilterValueChange($event, 'accruedDateEnd')"
                button-only
              />
            </slot>
            <slot v-else>
              <b-form-select
                style="width: auto"
                size="ml"
                v-if="field.filtered && getSourceFiltersList(field.key) && getSourceFiltersList(field.key).length > 0"
                :value="sourceFilterModel(field.key)"
                :options="getSourceFiltersList(field.key)"
                @change="onFilterValueChange($event, field.key)"
              >
                <template #first>
                  <b-form-select-option value="">Select All</b-form-select-option>
                </template>
              </b-form-select>
              <multiselect
                class="multiple-filter"
                v-if="
                  field.multipleFiltered &&
                  getSourceFiltersList(field.key) &&
                  getSourceFiltersList(field.key).length > 0
                "
                v-model="multipleFiltersModel[field.key]"
                :options="getSourceFiltersList(field.key)"
                :close-on-select="false"
                :multiple="true"
                :show-labels="false"
                :taggable="true"
                @close="onMultipleFilterValueChange"
                @remove="onMultipleFilterValueRemove($event, field.key)"
              >
              </multiselect>
            </slot>
          </td>
        </template>

        <!--#region Templates -->

        <template v-if="hasDetails && !detailsAsTable && !detailsList" #row-details="row">
          <b-card border-variant="light">
            <div v-for="detail in row.item.details" :key="detail.id">
              <b-row class="mb-2">
                <b-col sm="3" class="details-info text-sm-left"
                  ><b>{{ detail.title }}:</b></b-col
                >
                <b-col class="text-sm-left">
                  <span v-if="detail.logo">
                    <load-image
                      v-if="detail.logo.url"
                      :preview="detail.logo"
                      :fileUrl="detail.logo.url"
                      :fileName="detail.logo.info.fileName"
                    />
                  </span>
                  <span v-if="detail.description">
                    <span v-if="detail.description.fieldValue">
                      {{ detail.description.fieldValue }}
                    </span>
                    <span v-if="detail.description.mode">
                      <slot v-if="detail.description.mode === 'Attention'">
                        <b-badge pill variant="danger">
                          {{ detail.description.mode }}
                        </b-badge>
                      </slot>
                      <slot v-else-if="detail.description.mode === 'Info'">
                        <b-badge pill variant="warning">
                          {{ detail.description.mode }}
                        </b-badge>
                      </slot>
                      <slot v-else-if="detail.description.mode === 'Required'">
                        <b-badge pill variant="light">
                          {{ detail.description.mode }}
                        </b-badge>
                      </slot>
                    </span>
                    <span class="ml-2" v-if="detail.description.hasOwnProperty('isEnabled')">
                      <slot v-if="detail.description && detail.description.isEnabled">
                        <b-badge pill variant="success"> Visible </b-badge>
                      </slot>
                      <slot v-else>
                        <b-badge pill variant="secondary"> Not visible </b-badge>
                      </slot>
                    </span>
                    <span class="ml-2" v-if="detail.description && detail.description.hasOwnProperty('isInternal')">
                      <slot v-if="detail.description.isInternal">
                        <b-badge pill variant="success"> Visible </b-badge>
                      </slot>
                      <slot v-else>
                        <b-badge pill variant="secondary"> Not visible </b-badge>
                      </slot>
                    </span>
                    <span class="ml-2" v-if="detail.description.hasOwnProperty('isRequired')">
                      <slot v-if="detail.description.isRequired">
                        <b-badge pill variant="light"> Required </b-badge>
                      </slot>
                      <slot v-else>
                        <b-badge pill variant="info"> Optional </b-badge>
                      </slot>
                    </span>
                    <span v-if="detail.key === 'transactionInfoUrl'">
                      <b-link :href="detail.description" target="_blank">{{ detail.description }}</b-link>
                    </span>
                    <span v-else-if="detail.url">
                      <b-link :href="detail.url" target="_blank">{{ detail.description }}</b-link>
                    </span>
                    <span v-else-if="detail.key === 'manager'">
                      <router-link
                        v-if="detail.description && detail.description.id"
                        target="_blank"
                        :to="'/user-details/' + detail.description.id"
                      >
                        {{ detail.description.name }}
                      </router-link>
                    </span>
                    <span v-else class="pre-formatted">
                      <span v-if="detail.key === 'paymentAccountDetails'">
                        <div v-for="field in detail.description" :key="field.key">
                          {{ parseObjectKey(field.key) }} : {{ field.value }}
                        </div>
                      </span>
                      <span v-else-if="detail.description.sumSubInfo" class="pre-formatted">
                        <slot
                          v-if="
                            detail.description.sumSubInfo.status === 'Verified' ||
                            detail.description.sumSubInfo.status === 'Approved'
                          "
                        >
                          <b-badge pill variant="success">
                            {{ detail.description.sumSubInfo.status }}
                          </b-badge>
                        </slot>
                        <slot v-else-if="detail.description.sumSubInfo.status === 'Not verified'">
                          <b-badge pill variant="secondary">
                            {{ detail.description.sumSubInfo.status }}
                          </b-badge>
                        </slot>
                        <slot v-else-if="detail.description.sumSubInfo.status === 'Rejected'">
                          <b-badge pill variant="danger">
                            {{ detail.description.sumSubInfo.status }}
                          </b-badge>
                        </slot>
                        <slot
                          v-else-if="
                            detail.description.sumSubInfo.status === 'Pending' ||
                            detail.description.sumSubInfo.status === 'Documents requested' ||
                            detail.description.sumSubInfo.status === 'Resubmission requested'
                          "
                        >
                          <b-badge pill variant="warning">
                            {{ detail.description.sumSubInfo.status }}
                          </b-badge>
                        </slot>
                        <slot v-else-if="detail.description.sumSubInfo.status === 'Under review'">
                          <b-badge pill variant="warning">
                            {{ detail.description.sumSubInfo.status }}
                          </b-badge>
                        </slot>
                        <slot v-else>
                          <b-badge pill variant="info">
                            {{ detail.description.sumSubInfo.status }}
                          </b-badge>
                        </slot>
                        <b-button
                          variant="light"
                          class="p-0 mb-1 ml-1"
                          size="sm"
                          v-if="detail.description.sumSubInfo.status !== 'Not verified'"
                          @click="openSumSub(detail.description.sumSubInfo.applicantUrl)"
                        >
                          <b-icon class="align-middle" icon="box-arrow-right" variant="dark"></b-icon>
                        </b-button>
                      </span>
                      <span v-else>
                        {{ detail.description }}
                      </span>
                    </span>
                  </span>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </template>
        <template v-else-if="hasDetails && detailsAsTable" #row-details="row">
          <b-table
            :items="row.item.details"
            :fields="getFields"
            small
            outlined
            no-border-collapse
            hover
            caption-top
            bordered
            responsive
          >
            <template #cell(title)="data">
              <span v-if="(data.item.description === '' && data.item.files.length === 0) || data.item.needColor">
                <b>{{ data.item.title }}</b>
              </span>
              <span v-else>
                {{ data.item.title }}
              </span>
            </template>
            <template #cell(statusTitle)="data">
              <slot v-if="data.item.statusTitle === 'Declined'">
                <b-badge variant="danger">{{ data.item.statusTitle }}</b-badge>
              </slot>
              <slot v-else-if="data.item.statusTitle === 'Accepted'">
                <b-badge variant="success">{{ data.item.statusTitle }}</b-badge>
              </slot>
              <slot v-else-if="data.item.statusTitle === 'NotChecked'">
                <b-badge variant="secondary">{{ data.item.statusTitle }}</b-badge>
              </slot>
            </template>

            <template #cell(reviewType)="data">
              <slot v-if="data.item.reviewType === 'Final'">
                <b-badge variant="danger" v-b-popover.hover.topright="data.item.reviewTypeTitle">{{
                  data.item.reviewType
                }}</b-badge>
              </slot>
              <slot v-else-if="data.item.reviewType === 'Retry'">
                <b-badge variant="secondary" v-b-popover.hover.topright="data.item.reviewTypeTitle">{{
                  data.item.reviewType
                }}</b-badge>
              </slot>
              <span v-if="data.item.reviewType">
                <b-icon
                  class="ml-1 p-0"
                  icon="question-circle"
                  font-scale="1"
                  variant="secondary"
                  v-b-popover.hover.topright="data.item.reviewTypeTitle"
                >
                </b-icon>
              </span>
            </template>
            <template #cell(description)="data">
              <span v-if="data.item.fieldType === 'File' || data.item.files.length > 0">
                <div style="display: inline-block" v-for="file in data.item.files" :key="file.id">
                  <load-image
                    v-if="file && file.url"
                    :fileUrl="file.url"
                    :preview="file.preview"
                    :fileName="file.info.fileName"
                  />
                </div>
              </span>
              <span v-else-if="data.item.fieldType === 'Date'">
                {{ data.item.description | formattedDate }}
              </span>
              <span v-else-if="data.item.description && data.item.description.tradingAccountId">
                <router-link target="_blank" :to="'/trading-account-details/' + data.item.description.tradingAccountId"
                  >{{ data.item.description.login }}
                </router-link>
              </span>
              <span v-else> {{ data.item.description }} </span>
            </template>

            <template #cell(deleteValue)="data">
              <b-button variant="light" size="sm" @click="onDeleteValue(row.item.id, data)">
                <b-icon icon="trash-fill" variant="dark"></b-icon>
              </b-button>
            </template>

            <template #cell(login)="data">
              <slot v-if="data.item.tradingAccountId">
                <router-link target="_blank" :to="'/trading-account-details/' + data.item.tradingAccountId"
                  >{{ data.item.login }}
                </router-link>
              </slot>
            </template>
          </b-table>
        </template>
        <template v-else-if="hasDetails && detailsList" #row-details="row">
          <b-table
            :items="row.item.details"
            :fields="detailsFieldsList"
            small
            outlined
            no-border-collapse
            hover
            caption-top
            bordered
            responsive
          >
            <template #cell(options)="data">
              <slot v-if="!data.item.options.isKycRequired">
                <b-badge style="width: auto" variant="danger"> IsKycRequired</b-badge>
              </slot>
              <slot v-if="data.item.options.isKycRequired">
                <b-badge style="width: auto" variant="success"> IsKycRequired</b-badge>
              </slot>
            </template>

            <template #cell(leverages)="data">
              <div v-for="leverage in data.item.leverages" :key="leverage.leverage">
                <div class="mb-2 mt-2">
                  {{ leverage.leverage }}
                  <slot v-if="!leverage.isDefault">
                    <b-icon
                      style="width: 0.7rem; height: 1rem; padding: 0; font-size: medium; margin-left: 0.2rem"
                      icon="check-circle"
                      variant="dark"
                      @click="onSetDefault(data.item.id, leverage.leverage, !leverage.isDefault, 'leverage')"
                    ></b-icon>
                  </slot>
                  <slot v-else>
                    <b-icon
                      style="width: 0.7rem; height: 1rem; padding: 0; font-size: medium; margin-left: 0.2rem"
                      icon="check-circle-fill"
                      variant="success"
                      @click="onSetDefault(data.item.id, leverage.leverage, !leverage.isDefault, 'leverage')"
                    ></b-icon>
                  </slot>
                  <b-icon
                    class="ml-2"
                    style="width: 0.7rem; height: 1rem; padding: 0; font-size: medium; margin-left: 0.2rem"
                    icon="dash-circle"
                    variant="danger"
                    @click="onDeleteRowValue(data.item.id, leverage, 'leverage')"
                  ></b-icon>
                </div>
              </div>
              <div style="width: 100%">
                <b-icon
                  class="mr-2"
                  icon="plus-square"
                  variant="dark"
                  font-scale="1.25"
                  @click="onAddRowValue(data.item.id, 'leverage')"
                ></b-icon>
              </div>
            </template>

            <template #cell(currencies)="data">
              <div v-for="currency in data.item.currencies" :key="currency.currency">
                <div class="mb-2 mt-2">
                  {{ currency.currency }}
                  <slot v-if="!currency.isDefault">
                    <b-icon
                      style="width: 0.7rem; height: 1rem; padding: 0; font-size: medium; margin-left: 0.2rem"
                      icon="check-circle"
                      variant="dark"
                      @click="onSetDefault(data.item.id, currency.currency, !currency.isDefault, 'currency')"
                    ></b-icon>
                  </slot>
                  <slot v-else>
                    <b-icon
                      style="width: 0.7rem; height: 1rem; padding: 0; font-size: medium; margin-left: 0.2rem"
                      icon="check-circle-fill"
                      variant="success"
                      @click="onSetDefault(data.item.id, currency.currency, !currency.isDefault, 'currency')"
                    ></b-icon>
                  </slot>
                  <b-icon
                    class="ml-2"
                    style="width: 0.7rem; height: 1rem; padding: 0; font-size: medium; margin-left: 0.2rem"
                    icon="dash-circle"
                    variant="danger"
                    @click="onDeleteRowValue(data.item.id, currency, 'currency')"
                  ></b-icon>
                </div>
              </div>
              <div style="width: 100%">
                <b-icon
                  class="mr-2"
                  icon="plus-square"
                  variant="dark"
                  font-scale="1.25"
                  @click="onAddRowValue(data.item.id, 'currency')"
                ></b-icon>
              </div>
            </template>
          </b-table>

          <div style="float: left">
            <b-button variant="success" style="width: 10rem; font-weight: bold" @click="onAddValue(row.item)"
              >+ Add account
            </b-button>
          </div>
        </template>

        <template #cell(status.status)="data">
          <slot v-if="data.item.status.status === 'Done' || data.item.status.status === 'Finished'">
            <b-badge pill variant="success">
              {{ data.item.status.status }}
            </b-badge>
          </slot>
          <slot
            v-else-if="
              data.item.status.status === 'Canceled' ||
              data.item.status.status === 'Error' ||
              data.item.status.status === 'Rejected'
            "
          >
            <b-badge pill variant="danger">
              {{ data.item.status.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status.status === 'Pending' || data.item.status.status === 'Processing'">
            <b-badge pill variant="warning">
              {{ data.item.status.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status.status === 'Refunded'">
            <b-badge pill variant="secondary">
              {{ data.item.status.status }}
            </b-badge>
          </slot>
        </template>

        <template #cell(status)="data">
          <slot v-if="data.item.status === 'Done'">
            <b-badge pill variant="success">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'Active'">
            <b-badge pill variant="success">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'Archived'">
            <b-badge pill variant="secondary">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'Rejected'">
            <b-badge pill variant="danger">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot
            v-else-if="
              data.item.status === 'Approved' || data.item.status === 'Completed' || data.item.status === 'Finished'
            "
          >
            <b-badge pill variant="success">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'Canceled' || data.item.status === 'NotApplied'">
            <b-badge pill variant="secondary">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot
            v-else-if="
              data.item.status === 'Pending' || data.item.status === 'InProcess' || data.item.status === 'InProgress'
            "
          >
            <b-badge pill variant="warning">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'ReadyForWithdraw'">
            <b-badge pill variant="warning">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'UnderReview'">
            <b-badge pill variant="warning">
              {{ data.item.status }}
            </b-badge>
          </slot>

          <slot v-else-if="data.item.status === 'Bronze'">
            <b-badge pill variant="danger">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'Silver'">
            <b-badge pill variant="secondary">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'Gold'">
            <b-badge pill variant="warning">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'Agent'">
            <b-badge pill variant="warning">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'Master'">
            <b-badge pill variant="success">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.status === 'Member'">
            <b-badge pill variant="secondary">
              {{ data.item.status }}
            </b-badge>
          </slot>
          <slot v-else>
            <b-badge pill variant="info">
              {{ data.item.status }}
            </b-badge>
          </slot>
        </template>

        <template #cell(isEnabled)="data">
          <slot v-if="data.item.isEnabled === 'Yes'">
            <b-badge pill variant="success">
              {{ data.item.isEnabled }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.isEnabled === 'Enabled'">
            <b-badge pill variant="success">
              {{ data.item.isEnabled }}
            </b-badge>
          </slot>
          <slot v-else>
            <b-badge pill variant="secondary">
              {{ data.item.isEnabled }}
            </b-badge>
          </slot>
        </template>

        <template #cell(isInternal)="data">
          <slot v-if="data.item.isInternal === 'Yes'">
            <b-badge pill variant="success">
              {{ data.item.isInternal }}
            </b-badge>
          </slot>
          <slot v-else>
            <b-badge pill variant="secondary"> {{ data.item.isInternal }} </b-badge>
          </slot>
        </template>

        <template #cell(riskStatus)="data">
          <slot v-if="data.item.riskStatus === 'Low'">
            <b-badge pill variant="success">
              {{ data.item.riskStatus }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.riskStatus === 'Medium'">
            <b-badge pill variant="warning"> {{ data.item.riskStatus }} </b-badge>
          </slot>
          <slot v-else-if="data.item.riskStatus === 'High'">
            <b-badge pill variant="danger"> {{ data.item.riskStatus }} </b-badge>
          </slot>
        </template>

        <template #cell(isAccrued)="data">
          <slot v-if="data.item.isAccrued === 'Yes'">
            <b-badge pill variant="success">
              {{ data.item.isAccrued }}
            </b-badge>
          </slot>
          <slot v-else>
            <b-badge pill variant="secondary">
              {{ data.item.isAccrued }}
            </b-badge>
          </slot>
        </template>

        <template #cell(isActive)="data">
          <slot v-if="data.item.isActive === 'Yes'">
            <b-badge pill variant="success">
              {{ data.item.isActive }}
            </b-badge>
          </slot>
          <slot v-else>
            <b-badge pill variant="secondary">
              {{ data.item.isActive }}
            </b-badge>
          </slot>
        </template>

        <template #cell(isHided)="data">
          <slot v-if="data.item.isHided === 'Yes'">
            <b-badge pill variant="success">
              {{ data.item.isHided }}
            </b-badge>
          </slot>
          <slot v-else>
            <b-badge pill variant="secondary">
              {{ data.item.isHided }}
            </b-badge>
          </slot>
        </template>

        <template #cell(isMultiApplicability)="data">
          <slot v-if="data.item.isMultiApplicability === 'Yes'">
            <b-badge pill variant="success">
              {{ data.item.isMultiApplicability }}
            </b-badge>
          </slot>
          <slot v-else>
            <b-badge pill variant="secondary">
              {{ data.item.isMultiApplicability }}
            </b-badge>
          </slot>
        </template>

        <template #cell(isRepeatedly)="data">
          <slot v-if="data.item.isRepeatedly === 'Yes'">
            <b-badge pill variant="success">
              {{ data.item.isRepeatedly }}
            </b-badge>
          </slot>
          <slot v-else>
            <b-badge pill variant="secondary">
              {{ data.item.isRepeatedly }}
            </b-badge>
          </slot>
        </template>

        <template #cell(lastCheck.isSuccess)="data">
          <slot v-if="data.item.lastCheck">
            <slot v-if="data.item.lastCheck.isSuccess">
              <b-icon icon="check-circle-fill" font-scale="1" variant="success"></b-icon>
            </slot>
          </slot>
          <slot v-else>
            <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
          </slot>
        </template>

        <template #cell(lastCheck.code)="data">
          <slot v-if="data.item.lastCheck">
            <slot v-if="data.item.lastCheck.code == '200'">
              <b-badge variant="success">
                {{ data.item.lastCheck.code }}
              </b-badge>
            </slot>
            <slot v-else-if="data.item.lastCheck.code == '400'">
              <b-badge pill variant="danger">
                {{ data.item.code }}
              </b-badge>
            </slot>
          </slot>
        </template>

        <template #cell(isWithdrawEnabled)="data">
          <slot v-if="data.item.isWithdrawEnabled === 'Yes'">
            <b-badge pill variant="success">
              {{ data.item.isWithdrawEnabled }}
            </b-badge>
          </slot>
          <slot v-else>
            <b-badge pill variant="secondary">
              {{ data.item.isWithdrawEnabled }}
            </b-badge>
          </slot>
        </template>

        <template #cell(externalKycStatus)="data">
          <slot v-if="data.item.externalKycStatus === 'Verified' || data.item.externalKycStatus === 'Approved'">
            <b-badge pill variant="success">
              {{ data.item.externalKycStatus }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.externalKycStatus === 'Not verified'">
            <b-badge pill variant="secondary">
              {{ data.item.externalKycStatus }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.externalKycStatus === 'Rejected'">
            <b-badge pill variant="danger">
              {{ data.item.externalKycStatus }}
            </b-badge>
          </slot>
          <slot
            v-else-if="
              data.item.externalKycStatus === 'Pending' ||
              data.item.externalKycStatus === 'Documents requested' ||
              data.item.externalKycStatus === 'Resubmission requested'
            "
          >
            <b-badge pill variant="warning">
              {{ data.item.externalKycStatus }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.externalKycStatus === 'UnderReview'">
            <b-badge pill variant="warning">
              {{ data.item.externalKycStatus }}
            </b-badge>
          </slot>
          <slot v-else>
            <b-badge pill variant="info">
              {{ data.item.externalKycStatus }}
            </b-badge>
          </slot>
        </template>

        <!--        <template #cell(depositComment)="data">-->
        <!--          <b-form-input v-model="data.item.depositComment"></b-form-input>-->
        <!--        </template>-->

        <!--        <template #cell(depositStatus)="data">-->
        <!--          <b-form-select-->
        <!--            :options="depositStatuses"-->
        <!--            v-model="data.item.depositStatus"-->
        <!--            value-field="value"-->
        <!--            text-field="text"-->
        <!--            @change="updateStatus($event, data.item.transactionId, data.item.depositComment)"-->
        <!--          />-->
        <!--        </template>-->

        <template #cell(actionBtn)="">
          <b-icon class="mt-1" icon="plus-square" variant="dark" />
        </template>

        <template #cell(address)="data">
          <b-link :href="data.item.url" target="_blank">{{ data.item.address }}</b-link>
        </template>

        <template #cell(terminalUrl)="data">
          <b-link :href="data.item.terminalUrl" target="_blank">{{ data.item.terminalUrl }}</b-link>
        </template>

        <template #cell(url)="data">
          <b-link :href="data.item.url" target="_blank">{{ data.item.url }}</b-link>
        </template>

        <template #cell(signUpUrl)="data">
          <b-link :href="data.item.signUpUrl" target="_blank">{{ data.item.signUpUrl }}</b-link>
        </template>

        <template #cell(wallet.address)="data">
          <b-link :href="data.item.wallet.url" target="_blank">{{ data.item.wallet.address }}</b-link>
        </template>

        <template #cell(type)="data">
          <slot v-if="data.item.type === 'Deposit'">
            <b-badge pill variant="success">
              {{ data.item.type }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.type === 'Withdraw'">
            <b-badge pill variant="warning">
              {{ data.item.type }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.type === 'Standard'">
            <b-badge pill variant="success">
              {{ data.item.type }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.type === 'InOut'">
            <b-badge pill variant="warning">
              {{ data.item.type }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.type === 'In'">
            <b-badge pill variant="warning">
              {{ data.item.type }}
            </b-badge>
          </slot>
          <slot v-else>
            {{ data.item.type }}
          </slot>
        </template>

        <template #cell(paymentMethodMode)="data">
          <slot v-if="data.item.paymentMethodMode === 'Deposit'">
            <b-badge pill variant="success">
              {{ data.item.paymentMethodMode }}
            </b-badge>
          </slot>
          <slot v-else-if="data.item.paymentMethodMode === 'Withdrawal'">
            <b-badge pill variant="warning">
              {{ data.item.paymentMethodMode }}
            </b-badge>
          </slot>
          <slot v-else>
            {{ data.item.paymentMethodMode }}
          </slot>
        </template>

        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </template>

        <template #cell(lastActionKycDate)="data">
          {{ data.item.lastActionKycDate }}
          <slot v-if="hasDetails && data.item.details.length > 0">
            <b-icon class="ml-1" icon="chevron-down" font-scale="1" variant="dark"></b-icon>
          </slot>
        </template>

        <template #cell(tournamentTitle)="data">
          {{ data.item.tournamentTitle }}
          <slot v-if="hasDetails && data.item.details.length > 0">
            <b-icon class="ml-1" icon="chevron-down" font-scale="1" variant="dark"></b-icon>
          </slot>
        </template>

        <template #cell(dateAndTime)="data">
          {{ data.item.dateAndTime }}
          <slot v-if="hasDetails && data.item.details.length > 0">
            <b-icon class="ml-1" icon="chevron-down" font-scale="1" variant="dark"></b-icon>
          </slot>
        </template>

        <template #cell(showDetails)="data">
          <slot v-if="hasDetails && data.item.details.length > 0">
            <b-icon class="ml-1" icon="chevron-down" font-scale="1" variant="dark"></b-icon>
          </slot>
        </template>

        <template #cell(bonusTitle)="data">
          <slot v-if="data.item.bonusId">
            <router-link target="_blank" :to="'/bonus-details/' + data.item.bonusId"
              >{{ data.item.bonusTitle }}
            </router-link>
          </slot>
          <slot v-if="hasDetails && data.item.details.length > 0">
            <b-icon class="ml-1" icon="chevron-down" font-scale="1" variant="dark"></b-icon>
          </slot>
        </template>

        <template #cell(userBonusTitle)="data">
          <slot v-if="data.item.bonusId">
            <router-link target="_blank" :to="'/bonus-details/' + data.item.bonusId"
              >{{ data.item.userBonusTitle }}
            </router-link>
          </slot>
        </template>

        <template #cell(kycDocType)="data">
          {{ data.item.kycDocType }}
          <slot v-if="hasDetails">
            <b-icon class="ml-1" icon="chevron-down" font-scale="1" variant="dark"></b-icon>
          </slot>
        </template>

        <template #cell(deal)="data">
          <slot v-if="hasDetails">
            {{ data.item.deal }}
            <b-icon class="ml-1" icon="chevron-down" font-scale="1" variant="dark"></b-icon>
          </slot>
        </template>

        <template #cell(email)="data">
          <div>
            <router-link target="_blank" :to="'/user-details/' + getUserLink(data)">{{ data.item.email }}</router-link>

            <slot v-if="data.item.isEmailConfirmed">
              <b-icon icon="check" font-scale="1.2" variant="success"></b-icon>
            </slot>
            <slot v-if="!data.item.isEmailConfirmed">
              <b-icon icon="x" font-scale="1.2" variant="danger"></b-icon>
            </slot>

            <slot v-if="hasDetails && !data.item.isCheck">
              <b-icon class="mr-2" icon="chevron-down" font-scale="1" variant="dark"></b-icon>
            </slot>

            <slot v-if="data.item.isRootAdmin">
              <b-badge style="width: auto" variant="secondary"> Root</b-badge>
            </slot>
            <slot v-else-if="data.item.isAdmin">
              <b-badge style="width: auto" variant="info"> Admin</b-badge>
            </slot>
          </div>
        </template>

        <template #cell(transactionInfoUrl)="data">
          <div>
            <b-link :href="data.item.transactionInfoUrl" target="_blank">{{ data.item.transactionInfoUrl }}</b-link>
          </div>
        </template>

        <template #cell(weight)="data">
          <b-span :href="data.item.weight" target="_blank" :class="scoreLevelClass(data.item.weight)">{{
            data.item.weight
          }}</b-span>
        </template>

        <template #cell(serviceName)="data">
          <div>
            <router-link :to="{ name: 'Service Details', params: { service: data.item } }"
              >{{ data.item.serviceName }}
            </router-link>
          </div>
        </template>

        <template #cell(validUntil)="data">
          <slot v-if="data.item.isExpired">
            <b-badge pill variant="danger">
              {{ data.item.validUntil }}
            </b-badge>
          </slot>
          <slot v-else>
            {{ data.item.validUntil }}
          </slot>
        </template>

        <template #cell(isConfirmed)="data">
          <slot v-if="data.item.isConfirmed">
            <b-icon icon="check" font-scale="2" variant="success"></b-icon>
          </slot>
          <slot v-if="!data.item.isConfirmed">
            <b-icon icon="x" font-scale="2" variant="danger"></b-icon>
          </slot>
        </template>

        <template #cell(approvedByUser)="data">
          <slot v-if="data.item.approvedByUser">
            <b-icon icon="check" font-scale="2" variant="success"></b-icon>
          </slot>
          <slot v-if="!data.item.approvedByUser">
            <b-icon icon="x" font-scale="2" variant="danger"></b-icon>
          </slot>
        </template>

        <template #cell(approvedByPlatform)="data">
          <slot v-if="data.item.approvedByPlatform">
            <b-icon icon="check" font-scale="2" variant="success"></b-icon>
          </slot>
          <slot v-if="!data.item.approvedByPlatform">
            <b-icon icon="x" font-scale="2" variant="danger"></b-icon>
          </slot>
        </template>

        <template #cell(login)="data">
          <div v-if="needGrouped">
            <div v-if="shouldShowGroupHeader(data.item.group, data.index)">
              <router-link target="_blank" :to="'/trading-account-details/' + data.item.tradingAccountId"
                >{{ data.item.login }}
              </router-link>
            </div>
          </div>
          <div v-else>
            <slot v-if="data.item.tradingAccountId">
              <router-link target="_blank" :to="'/trading-account-details/' + data.item.tradingAccountId"
                >{{ data.item.login }}
              </router-link>
            </slot>
            <slot v-else>
              <router-link target="_blank" :to="'/trading-account-details/' + data.item.id"
                >{{ data.item.login }}
              </router-link>
            </slot>
          </div>
        </template>

        <template #cell(user.email)="data">
          <div class="d-flex justify-content-center align-items-center">
            <div v-if="data.item.user">
              <router-link target="_blank" :to="'/user-details/' + data.item.user.id"
                >{{ data.item.user.email }}
              </router-link>
            </div>
            <div v-if="hasDetails" class="cursor-pointer ml-1">
              <b-icon v-if="!data.detailsShowing" icon="chevron-down" variant="dark"></b-icon>
              <b-icon v-if="data.detailsShowing" icon="chevron-up" variant="dark"></b-icon>
            </div>
          </div>
        </template>

        <template #cell(userEmail)="data">
          <div class="d-flex justify-content-center align-items-center">
            <router-link target="_blank" :to="'/user-details/' + data.item.userId"
              >{{ data.item.userEmail }}
            </router-link>

            <div v-if="hasDetails" class="cursor-pointer ml-2">
              <b-icon v-if="!data.detailsShowing" icon="chevron-down" variant="dark"></b-icon>
              <b-icon v-if="data.detailsShowing" icon="chevron-up" variant="dark"></b-icon>
            </div>
          </div>
        </template>

        <template #cell(adminEmail)="data">
          <div class="d-flex justify-content-center align-items-center">
            <router-link target="_blank" :to="'/user-details/' + data.item.adminId"
              >{{ data.item.adminEmail }}
            </router-link>
          </div>
        </template>

        <template #cell(admin.name)="data">
          <router-link v-if="data.item.admin" target="_blank" :to="'/user-details/' + data.item.admin.id">
            {{ data.item.admin.name }}
          </router-link>
        </template>

        <template #cell(admin.email)="data">
          <router-link v-if="data.item.admin" target="_blank" :to="'/user-details/' + data.item.admin.id">
            {{ data.item.admin.email }}
          </router-link>
        </template>

        <template #cell(user.name)="data">
          <router-link
            v-if="data.item.user && data.item.user.id"
            target="_blank"
            :to="'/user-details/' + data.item.user.id"
          >
            {{ data.item.user.name }}
          </router-link>
          <slot v-if="hasDetails && data.item.details.length > 0 && data.item.userBonusTitle">
            <b-icon class="ml-1" icon="chevron-down" font-scale="1" variant="dark"></b-icon>
          </slot>
        </template>

        <template #cell(manager.name)="data">
          <router-link
            v-if="data.item.manager && data.item.manager.id"
            target="_blank"
            :to="'/user-details/' + data.item.manager.id"
          >
            {{ data.item.manager.name }}
          </router-link>
        </template>

        <template #cell(userOriginal.name)="data">
          <router-link
            v-if="data.item.userOriginal && data.item.userOriginal.id"
            target="_blank"
            :to="'/user-details/' + data.item.userOriginal.id"
          >
            {{ data.item.userOriginal.name }}
          </router-link>
        </template>

        <template #cell(user)="data">
          <span v-if="data.item.user">
            <span v-if="data.item.user.id">
              <router-link target="_blank" :to="'/user-details/' + data.item.user.id">
                {{ data.item.user.name }}
              </router-link>
            </span>
            <span v-else>
              {{ data.item.user.name }}
            </span>
          </span>
          <span v-if="hasDetails && !data.item.showDetails">
            <b-icon icon="chevron-down" font-scale="1" variant="dark"></b-icon>
          </span>
        </template>

        <template #cell(groupTitle)="data">
          <b>{{ data.item.groupTitle }}</b>
          <span v-if="hasDetails">
            <b-icon icon="chevron-down" class="ml-1" font-scale="1" variant="dark"></b-icon>
          </span>
        </template>

        <template #cell(paymentMethodTitle)="data">
          {{ data.item.paymentMethodTitle }}
          <span v-if="hasDetails">
            <b-icon icon="chevron-down" class="ml-2" font-scale="1" variant="dark"></b-icon>
          </span>
        </template>

        <template #cell(admin)="data">
          <span v-if="data.item.admin">
            <span v-if="data.item.admin && data.item.admin.id">
              <router-link target="_blank" :to="'/user-details/' + data.item.admin.id">
                {{ data.item.admin.name }}
              </router-link>
            </span>
            <span v-else>
              {{ data.item.admin.name }}
            </span>
          </span>
        </template>

        <template #cell(tradingAccount)="data">
          <span v-if="data.item.tradingAccount && data.item.tradingAccount.id">
            <router-link target="_blank" :to="'/trading-account-details/' + data.item.tradingAccount.id">
              {{ data.item.tradingAccount.login }}
            </router-link>
          </span>
        </template>

        <template #cell(blockchainWithdrawalRequest)="data">
          <span v-if="data.item.blockchainWithdrawalRequest && data.item.blockchainWithdrawalRequest.id">
            <router-link
              target="_blank"
              :to="
                $router.resolve({
                  name: 'Withdrawal requests',
                  params: { id: data.item.blockchainWithdrawalRequest.id },
                  query: { id: data.item.blockchainWithdrawalRequest.id }
                }).href
              "
            >
              {{ data.item.blockchainWithdrawalRequest.address }}
            </router-link>
          </span>
        </template>

        <template #cell(permissions.main)="data">
          <div v-for="permission in data.item.permissions.main" :key="permission.title">
            <b class="mr-1">{{ permission.title }}:</b>
            <slot v-if="permission.isEnabled">
              <b-icon icon="check-circle-fill" font-scale="1" variant="success"></b-icon>
            </slot>
            <slot v-if="!permission.isEnabled">
              <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
            </slot>
          </div>
        </template>

        <template #cell(permissions.users)="data">
          <div v-for="permission in data.item.permissions.users" :key="permission.title">
            <b class="mr-1">{{ permission.title }}:</b>
            <slot v-if="permission.isEnabled">
              <b-icon icon="check-circle-fill" font-scale="1" variant="success"></b-icon>
            </slot>
            <slot v-if="!permission.isEnabled">
              <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
            </slot>
          </div>
        </template>

        <template #cell(permissions.tradingAccounts)="data">
          <div v-for="permission in data.item.permissions.tradingAccounts" :key="permission.title">
            <b class="mr-1">{{ permission.title }}:</b>
            <slot v-if="permission.isEnabled">
              <b-icon icon="check-circle-fill" font-scale="1" variant="success"></b-icon>
            </slot>
            <slot v-if="!permission.isEnabled">
              <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
            </slot>
          </div>
        </template>

        <template #cell(permissions.transactions)="data">
          <div v-for="permission in data.item.permissions.transactions" :key="permission.title">
            <b class="mr-1">{{ permission.title }}:</b>
            <slot v-if="permission.isEnabled">
              <b-icon icon="check-circle-fill" font-scale="1" variant="success"></b-icon>
            </slot>
            <slot v-if="!permission.isEnabled">
              <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
            </slot>
          </div>
        </template>

        <template #cell(permissions.checks)="data">
          <div v-for="permission in data.item.permissions.checks" :key="permission.title">
            <b class="mr-1">{{ permission.title }}:</b>
            <slot v-if="permission.isEnabled">
              <b-icon icon="check-circle-fill" font-scale="1" variant="success"></b-icon>
            </slot>
            <slot v-if="!permission.isEnabled">
              <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
            </slot>
          </div>
        </template>

        <template #cell(permissions.tradingTournaments)="data">
          <div v-for="permission in data.item.permissions.tradingTournaments" :key="permission.title">
            <b class="mr-1">{{ permission.title }}:</b>
            <slot v-if="permission.isEnabled">
              <b-icon icon="check-circle-fill" font-scale="1" variant="success"></b-icon>
            </slot>
            <slot v-if="!permission.isEnabled">
              <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
            </slot>
          </div>
        </template>

        <template #cell(permissions.bonuses)="data">
          <div v-for="permission in data.item.permissions.bonuses" :key="permission.title">
            <b class="mr-1">{{ permission.title }}:</b>
            <slot v-if="permission.isEnabled">
              <b-icon icon="check-circle-fill" font-scale="1" variant="success"></b-icon>
            </slot>
            <slot v-if="!permission.isEnabled">
              <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
            </slot>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-button
            v-if="data.item.warnings && data.item.warnings.length > 0"
            class="p-0 m-1"
            variant="outline-light"
            size="sm"
            @click="onNotificate(data)"
          >
            <b-icon class="align-middle" font-scale="2" icon="exclamation-triangle-fill" variant="warning"></b-icon>
          </b-button>
          <b-button v-if="needComment" class="m-1 p-0" size="sm" @click="onAddCommentBtnClick(data.item)">
            <b-icon class="align-middle" icon="chat-square-text" variant="dark"></b-icon>
          </b-button>
          <b-button
            v-if="needDetails"
            variant="light"
            pill
            class="p-0 m-1"
            size="sm"
            @click="onGoToUrlBtnClick(data.item.id)"
          >
            <b-icon class="align-middle" icon="box-arrow-right" variant="dark"></b-icon>
          </b-button>
          <router-link target="_blank" :to="'/user-bonus-details/' + data.item.id">
            <b-button v-if="needInfo" variant="light" pill class="p-0 m-1" size="sm">
              <b-icon class="align-middle" icon="info-circle" variant="dark"></b-icon>
            </b-button>
          </router-link>
          <b-button v-if="needDownload" variant="success" pill size="sm" @click="onDownloadBtnClick(data.item)">
            <b-icon class="align-middle mb-1" scale="1.2" icon="download" variant="light"></b-icon>
          </b-button>
        </template>

        <template #cell(editSettings)="data">
          <b-button variant="primary" size="sm" @click="onEditSettings(data)">
            <b-icon icon="gear-fill" variant="light"></b-icon>
          </b-button>
        </template>

        <template #cell(editWithdraw)="data">
          <b-button
            v-if="hasChecksManage && data.item.canApprove"
            class="m-1 p-0"
            size="sm"
            @click="onApproveRequest(data)"
          >
            <b-icon class="align-middle" icon="check-square" variant="success"></b-icon>
          </b-button>
          <b-button
            v-if="hasChecksManage && data.item.canDecline"
            class="m-1 p-0"
            size="sm"
            @click="onDeclineRequest(data)"
          >
            <b-icon class="align-middle" icon="x-square" variant="danger"></b-icon>
          </b-button>
          <b-button
            v-if="hasChecksManage && data.item.checkStatus === 'New'"
            class="m-1 p-0"
            size="sm"
            @click="onProgressRequest(data)"
          >
            <b-icon class="align-middle" icon="exclamation-square" variant="warning"></b-icon>
          </b-button>
          <b-button v-if="hasChecksManage" class="m-1 p-0" size="sm" @click="onAddComment(data)">
            <b-icon class="align-middle" icon="chat-square-text" variant="dark"></b-icon>
          </b-button>
        </template>

        <template #cell(editTournament)="data">
          <b-button
            v-if="data.item.isActive === 'No'"
            variant="light"
            class="p-1"
            size="sm"
            @click="onStartValue(data)"
          >
            <b-icon icon="caret-right-square" variant="success"></b-icon>
          </b-button>
          <b-button
            v-if="data.item.isActive === 'Yes'"
            variant="light"
            class="p-1"
            size="sm"
            @click="onStopValue(data)"
          >
            <b-icon icon="stop-circle" variant="danger"></b-icon>
          </b-button>
          <b-button variant="light" class="ml-1 p-1" size="sm" @click="onAddValue(data)">
            <b-icon icon="plus-square" variant="dark"></b-icon>
          </b-button>
          <b-button variant="light" class="ml-1 p-1" size="sm" @click="onEditValue(data)">
            <b-icon icon="pencil-fill" variant="dark"></b-icon>
          </b-button>
        </template>

        <template #cell(editBonus)="data">
          <router-link :to="`/create-bonus?id=${data.item.bonusId}`" class="w-100" v-if="hasBonusesManage">
            <b-button variant="light" class="ml-1 p-1" size="sm">
              <b-icon icon="pencil-fill" variant="dark"></b-icon>
            </b-button>
          </router-link>
        </template>

        <template #cell(editDuplicate)="data">
          <b-button
            v-if="hasDuplicatesManage && data.item.status !== 'Canceled'"
            class="m-1 p-0"
            size="sm"
            @click="onDeclineDuplicate(data)"
          >
            <b-icon class="align-middle" icon="x-square" variant="danger"></b-icon>
          </b-button>
        </template>

        <template #cell(editAmlStatus)="data">
          <b-button
            variant="primary"
            size="sm"
            :class="data.item.checkStatus !== 'New' ? 'd-none fieldsClass' : 'fieldsClass'"
            @click="onAmlStatusSettings(data)"
          >
            <b-icon icon="gear-fill" variant="light"></b-icon>
          </b-button>
        </template>

        <template #cell(editValue)="data">
          <b-button variant="light" size="sm" @click="onEditValue(data)">
            <b-icon icon="pencil-fill" variant="dark"></b-icon>
          </b-button>
        </template>

        <template #cell(addValue)="data">
          <b-button variant="light" size="sm" @click="onAddValue(data)">
            <b-icon icon="plus-square" variant="dark"></b-icon>
          </b-button>
        </template>

        <template #cell(scheduler)="data" style="overflow: inherit">
          <b-icon class="mt-1 ml-2 mb-1" icon="plus-square" variant="dark" @click="onAddScheduler(data)"></b-icon>
          <div v-for="schedule in data.item.scheduler" :key="schedule.id">
            <b-row>
              <b-form-input style="max-width: 4rem" class="ml-4" size="sm" v-model="schedule.value"></b-form-input>
              <b-form-datepicker
                style="max-width: 19rem"
                class="mr-2 ml-2"
                size="sm"
                v-model="schedule.dateFrom"
              ></b-form-datepicker>
              -
              <b-form-datepicker
                style="max-width: 19rem"
                class="ml-2 mr-2"
                size="sm"
                v-model="schedule.dateTo"
              ></b-form-datepicker>
              <b-icon class="mt-2 ml-2" icon="trash-fill" variant="dark" @click="onDeleteScheduler(schedule)"></b-icon>
            </b-row>
          </div>
        </template>

        <!--#endregion-->

        <template #cell(items.locale)="data">
          <b-container>
            <b-row cols="1">
              <div v-for="template in data.item.items" :key="template.locale">
                <b-col>
                  <b-button variant="link" @click="onEditSettings(template, data.item.items)">
                    {{ template.locale }}
                  </b-button>
                </b-col>
              </div>
            </b-row>
          </b-container>
        </template>
      </b-table>
    </b-form-group>
    <b-row align-h="center">
      <b-col sm="auto" class="d-flex pagination">
        <b-pagination
          align="right"
          v-if="needPagination"
          :total-rows="totalRows"
          v-model="currentPage"
          :per-page="perPage"
        >
        </b-pagination>
      </b-col>
      <b-col sm="auto" class="per-page-text">
        <label v-if="needPerPage">Number per page: </label>
        <b-form-select
          class="mr-auto per-page-text ml-2"
          v-if="needPerPage"
          v-model="selectedPerPage"
          :options="perPageItems"
          @change="onReload(selectedPerPage)"
        ></b-form-select
      ></b-col>
      <b-col sm="auto" class="d-flex pagination"> </b-col>
      <b-col sm="auto">
        <label class="ml-auto shown-text" v-if="needPerPage">Shown: {{ getShownItems }} of {{ totalRows }}</label>
      </b-col>
    </b-row>
    <confirm-dialogue :show-confirm="true" ref="confirmDialogue"></confirm-dialogue>
    <add-comment-dlg ref="addCommentDlg"></add-comment-dlg>
    <withdraw-funds-dlg ref="withdrawFundsDlg"></withdraw-funds-dlg>
    <withdrawals-dlg ref="withdrawalsDlg"></withdrawals-dlg>
    <cancel-duplicate-dlg ref="cancelDuplicateDlg"></cancel-duplicate-dlg>
    <aml-dlg ref="amlDlg"></aml-dlg>
  </div>
</template>

<script>
import "bootstrap-icons/font/bootstrap-icons.css";
import ConfirmDialogue from "../../components/popups/confirm-dlg";
import AddCommentDlg from "../../components/popups/add-comment-dlg";
import WithdrawFundsDlg from "../../components/popups/withdraw-funds-dlg";
import WithdrawalsDlg from "../../components/popups/withdrawals-dlg.vue";
import AmlDlg from "../../components/popups/aml-dlg.vue";
import CancelDuplicateDlg from "../../components/popups/cancel-duplicate-dlg.vue";

import { BIcon } from "bootstrap-vue";
import moment from "moment";
import LoadImage from "./load-image";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "CommonDataSelectionGrid",
  components: {
    Multiselect,
    LoadImage,
    BIcon,
    ConfirmDialogue,
    AddCommentDlg,
    WithdrawFundsDlg,
    WithdrawalsDlg,
    AmlDlg,
    CancelDuplicateDlg
  },
  props: {
    className: null,
    dataResultList: null,
    detailsList: null,
    fieldsList: null,
    filtersList: null,
    filtersModel: null,
    multipleFiltersModel: null,
    detailsFieldsList: [],
    redirectionTemplate: null,
    isBusy: {
      type: Boolean,
      default: false
    },
    totalRows: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 0
    },
    hasDetails: {
      type: Boolean,
      default: false
    },
    nestedFiles: [],
    checkboxFilters: [],
    detailsAsTable: {
      type: Boolean,
      default: false
    },
    isFiltered: {
      type: Boolean,
      default: false
    },
    isSearched: {
      type: Boolean,
      default: true
    },
    needPagination: {
      type: Boolean,
      default: true
    },
    needEdit: {
      type: Boolean,
      default: false
    },
    needAdd: {
      type: Boolean,
      default: false
    },
    needComment: {
      type: Boolean,
      default: false
    },
    needDetails: {
      type: Boolean,
      default: false
    },
    needInfo: {
      type: Boolean,
      default: false
    },
    needCancel: {
      type: Boolean,
      default: false
    },
    needWithdraw: {
      type: Boolean,
      default: false
    },
    needDownload: {
      type: Boolean,
      default: false
    },
    needPerPage: {
      type: Boolean,
      default: false
    },
    needGrouped: {
      type: Boolean,
      default: false
    },
    customFields: []
  },
  data() {
    return {
      tableDataKey: 0,
      currentPage: 1,
      filter: null,
      dateRange: {
        start: null,
        end: null
      },
      selectedRow: {},
      selectedCell: null,
      selectedCheckBox: null,
      selectedPerPage: this.perPage,
      isFilterEnable: false,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      currentGroup: null,

      resultItems: this.dataResultList,
      showingRows: this.totalRows,
      perPageItems: [20, 50, 100],
      checked: {}
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        if (this.currentPage) this.$emit("pg-clicked", this.currentPage);
      }
    },
    dataResultList: {
      handler: function () {
        this.resultItems = this.dataResultList;
      }
    },
    totalRows: {
      handler: function () {
        this.showingRows = this.totalRows;
      }
    }
  },
  computed: {
    ...mapGetters(["hasTransferFunds", "hasChecksManage", "hasBonusesManage", "hasDuplicatesManage"]),
    rows() {
      if (this.dataResultList) return this.dataResultList.length;
      else return 0;
    },
    getResult() {
      return this.resultItems;
    },
    getFields() {
      if (this.customFields && this.customFields.length > 0) return this.customFields;

      return ["title", "description"];
    },
    getShownItems() {
      return this.selectedPerPage * this.currentPage < this.totalRows
        ? this.selectedPerPage * this.currentPage
        : this.totalRows;
    }
  },
  filters: {
    formattedDate(value) {
      return moment(String(value)).utc().format("DD.MM.YYYY");
    }
  },
  methods: {
    parseObjectKey(key) {
      if (!key) {
        return "";
      }

      return key.replace(/[_-]/g, " ");
    },
    shouldShowGroupHeader(group, index) {
      if (index === 0 || group !== this.resultItems[index - 1].group) {
        this.currentGroup = group;
        return true;
      }
      return false;
    },
    scoreLevelClass(score) {
      if (score >= 0 && score <= 1) {
        return "score-low";
      } else if (score === 2) {
        return "score-medium";
      } else if (score === 3) {
        return "score-high";
      } else {
        return ""; // If score is outside the specified ranges, return an empty string or handle it as needed.
      }
    },
    openSumSub(url) {
      window.open(url, "_blank");
    },
    getFilters() {
      return this.checkboxFilters;
    },
    onCheckboxChanged(value, item) {
      this.$emit("checkbox-clicked", value, item);
    },
    sourceFilterModel(key) {
      if (!this.filtersModel) return;
      var model = this.filtersModel.find(item => item.key === key);

      if (!model || model.selected === "") return;
      return model.selected;
    },
    getSourceFiltersList(key) {
      if (!this.filtersList) return;
      var filter = this.filtersList.find(item => item.key === key);

      if (!filter) return;
      return [...new Set(filter.items)];
    },
    onFilterValueChange(value, key) {
      var model = this.filtersModel.find(item => item.key === key);

      if (!model) return;
      model.selected = value;

      this.$emit("filter-clicked", this.filtersModel, this.filter);
    },
    onMultipleFilterValueChange() {
      this.$emit("multiple-filter-clicked", this.multipleFiltersModel, this.filter);
    },
    onMultipleFilterValueRemove(value, key) {
      this.multipleFiltersModel[key] = this.multipleFiltersModel[key].filter(x => x !== value);
      this.$emit("multiple-filter-clicked", this.multipleFiltersModel, this.filter);
    },
    onCleanFiltersClick() {
      if (this.filtersModel) this.filtersModel.forEach(item => (item.selected = ""));
      if (this.multipleFiltersModel)
        Object.keys(this.multipleFiltersModel).forEach(key => (this.multipleFiltersModel[key] = []));
      if (this.filter) this.filter = null;

      this.$emit("filter-clicked", this.filtersModel, this.filter);
      this.$emit("multiple-filter-clicked", this.multipleFiltersModel, this.filter);
    },
    onBtnSearchClick() {
      this.$emit("srch-clicked", this.filter, this.checked);
    },
    getUserLink(data) {
      const link = ["Deposits", "Withdrawals", "AmlChecks"].some(route => route === this.$route.name)
        ? data.item.userId
        : data.item.id;

      return link;
    },
    expandAdditionalInfo(row) {
      if (row["_showDetails"]) row["_showDetails"] = false;
      else if (row.details && row.details.length > 0) this.$set(row, "_showDetails", true);

      this.$emit("details-clicked", row);
    },
    onNotificate(message) {
      this.$emit("notificate-clicked", message);
    },
    onEditSettings(row, sourceValues) {
      this.$emit("edit-clicked", row, sourceValues);
    },
    onEditValue(row) {
      this.$emit("update-clicked", row);
    },
    async onAddComment(row) {
      const dlgResult = await this.$refs.addCommentDlg.show({
        transactionNumber: row.item.transactionNumber,
        message: "Add comment",
        commentRequired: true,
        userEmail: row.item.email,
        amount: row.item.amount + " " + row.item.currency
      });

      if (dlgResult.isOk) {
        this.$emit("comment-clicked", row.item.id, dlgResult.comment);
      }
    },
    async onProgressRequest(row) {
      const dlgResult = await this.$refs.addCommentDlg.show({
        transactionNumber: row.item.transactionNumber,
        message: "Are you sure you need more information on this request? Request will go to In progress status.",
        userEmail: row.item.email,
        amount: row.item.amount + " " + row.item.currency
      });

      if (dlgResult.isOk) {
        this.$emit("update-clicked", row.item.transferTransactionId, "InProgress", dlgResult.comment);
      }
    },
    async onApproveRequest(row) {
      const dlgResult = await this.$refs.addCommentDlg.show({
        transactionNumber: row.item.transactionNumber,
        message: "Are you sure you want to approve request?",
        userEmail: row.item.email,
        amount: row.item.amount + " " + row.item.currency
      });

      if (dlgResult.isOk) {
        this.$emit("update-clicked", row.item.transferTransactionId, "Approved", dlgResult.comment);
      }
    },
    async onDeclineRequest(row) {
      const dlgResult = await this.$refs.addCommentDlg.show({
        transactionNumber: row.item.transactionNumber,
        message: "Are you sure you want to decline request?",
        commentRequired: true,
        userEmail: row.item.email,
        amount: row.item.amount + " " + row.item.currency
      });

      if (dlgResult.isOk) {
        this.$emit("update-clicked", row.item.transferTransactionId, "Declined", dlgResult.comment);
      }
    },
    async onDeclineDuplicate(row) {
      const dlgResult = await this.$refs.cancelDuplicateDlg.show({
        message: "Are you sure you want to cancel duplicate?"
      });

      if (dlgResult.isOk) {
        this.$emit("cancel-clicked", dlgResult.comment, row.item.user.id, row.item.fieldId, row.item.userOriginal.id);
      }
    },
    async onAmlStatusSettings(row) {
      const dlgResult = await this.$refs.amlDlg.show({
        ticketId: row.item.ticketId
      });

      if (dlgResult.isOk) {
        this.$emit("approve-clicked");
      }
    },
    async onSortChanged(e) {
      this.$emit("sort-clicked", e.sortBy, e.sortDesc);
    },
    async onAddCommentBtnClick(row) {
      this.$emit("comment-clicked", row);
    },
    async onGoToUrlBtnClick(row) {
      this.$emit("route-clicked", row);
    },
    async onShowDetailsBtnClick(row) {
      this.$emit("details-clicked", row);
    },
    async onDownloadBtnClick(row) {
      this.$emit("download-clicked", row);
    },
    onReload(perPage) {
      this.$emit("load-clicked", perPage);
    },
    onDeleteValue(rowId, data) {
      this.$emit("delete-clicked", rowId, data);
    },
    onCopyValue(row) {
      this.$emit("copy-clicked", row);
    },
    onDeleteRowValue(id, value, type) {
      this.$emit("drop-value-clicked", id, value, type);
    },
    onSetDefault(id, value, state, type) {
      this.$emit("set-default-clicked", id, value, state, type);
    },
    onAddRowValue(id, type) {
      this.$emit("add-value-clicked", id, type);
    },
    onAddValue(row, sourceValues) {
      this.$emit("add-clicked", row, sourceValues);
    },
    onAddScheduler(row) {
      this.$emit("add-clicked", row, "scheduler");
    },
    onDeleteScheduler(row) {
      this.$emit("drop-clicked", row, "scheduler");
    },
    onStartValue(row) {
      this.$emit("start-clicked", row);
    },
    onStopValue(row) {
      this.$emit("stop-clicked", row);
    }
  }
};
</script>

<style scoped lang="scss">
.overflow-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-condensed {
  font-size: 14px;
  outline: 5rem;
  border-radius: 5px;
  overflow: auto !important;
}

.details-info {
  border-right: 0.01em solid darkgray;
}

.pre-formatted {
  white-space: pre-line;
}

#data-table::v-deep td {
  width: auto;
}

.riskScoring ::v-deep .score-weight {
  .score-low,
  .score-medium,
  .score-high {
    padding: 5px;
    display: block;
    width: 50%;
    height: 50%;
    color: #000;
    margin: auto;
  }

  .score-low {
    background: #d8ead3;
  }

  .score-medium {
    background: #fce5cd;
  }

  .score-high {
    background: #f4cccc;
  }
}
</style>
