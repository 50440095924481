//-----------------v1.0-----------------

//Lists
import UsersRepository_v10 from "./V1.0/users-repository";
import TradingAccountsRepository_v10 from "./V1.0/trading-accounts-repository";
import TransactionsRepository_v10 from "./V1.0/transactions-repository";
import TradingTournamentsRepository_v10 from "./V1.0/trading-tournaments-repository";

//Auth
import AuthRepository_v10 from "./V1.0/auth-repository";
import ProfileRepository_v10 from "./V1.0/profile-repository";

//System
import PlatformRepository_v10 from "./V1.0/platform-repository";
import ManageRepository_v10 from "./V1.0/manage-repository";
import ReportsRepository_v10 from "./V1.0/reports-repository";

//Checks
import ChecksRepository_v10 from "./V1.0/checks-repository";

//Payments
import PaymentsRepository_v10 from "./V1.0/payments-repository";

//Bonuses
import BonusesRepository_v10 from "./V1.0/bonuses-repository";

//-----------------v1.1-----------------
//System
import PlatformRepository_v11 from "./V1.1/platform-repository";

export const RepositoryName = {
  users: "users",
  tradingAccounts: "tradingAccounts",
  tradingTournaments: "tradingTournaments",
  transactions: "transactions",
  checks: "checks",
  payments: "payments",
  bonuses: "bonuses",
  profile: "profile",
  platform: "platform",
  reports: "reports",
  manage: "manage",
  auth: "auth"
};

const repositories = {
  "v1.0": {
    [RepositoryName.users]: UsersRepository_v10,
    [RepositoryName.tradingAccounts]: TradingAccountsRepository_v10,
    [RepositoryName.tradingTournaments]: TradingTournamentsRepository_v10,
    [RepositoryName.transactions]: TransactionsRepository_v10,
    [RepositoryName.profile]: ProfileRepository_v10,
    [RepositoryName.platform]: PlatformRepository_v10,
    [RepositoryName.manage]: ManageRepository_v10,
    [RepositoryName.auth]: AuthRepository_v10,
    [RepositoryName.checks]: ChecksRepository_v10,
    [RepositoryName.payments]: PaymentsRepository_v10,
    [RepositoryName.bonuses]: BonusesRepository_v10,
    [RepositoryName.reports]: ReportsRepository_v10
  },
  "v1.1": {
    [RepositoryName.platform]: PlatformRepository_v11
  }
  // other repos
};

export const RepositoryFactory = {
  get: (name, version = "v1.0") => repositories[version][name],
  show: () => repositories
};
