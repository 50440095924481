<template>
  <popup-modal ref="popup">
    <b-row>
      <b-col sm="5" class="text-sm-left"><b>Main:</b></b-col>
      <b-col class="text-sm-left mb-3">
        <b-form-checkbox
          v-for="option in resultData.main"
          v-model="selectedMain"
          :key="option.type"
          :value="option.type"
        >
          {{ option.title }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="5" class="text-sm-left"><b>Users:</b></b-col>
      <b-col class="text-sm-left mb-3">
        <b-form-checkbox
          v-for="option in resultData.users"
          v-model="selectedUsers"
          :key="option.type"
          :value="option.type"
        >
          {{ option.title }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="5" class="text-sm-left"><b>Trading accounts:</b></b-col>
      <b-col class="text-sm-left mb-3">
        <b-form-checkbox
          v-for="option in resultData.tradingAccounts"
          v-model="selectedAccounts"
          :key="option.type"
          :value="option.type"
        >
          {{ option.title }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="5" class="text-sm-left"><b>Transactions:</b></b-col>
      <b-col class="text-sm-left mb-3">
        <b-form-checkbox
          v-for="option in resultData.transactions"
          v-model="selectedTransactions"
          :key="option.type"
          :value="option.type"
        >
          {{ option.title }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="5" class="text-sm-left"><b>Checks:</b></b-col>
      <b-col class="text-sm-left mb-3">
        <b-form-checkbox
          v-for="option in resultData.checks"
          v-model="selectedChecks"
          :key="option.type"
          :value="option.type"
        >
          {{ option.title }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="5" class="text-sm-left"><b>Trading tournaments:</b></b-col>
      <b-col class="text-sm-left mb-3">
        <b-form-checkbox
          v-for="option in resultData.tradingTournaments"
          v-model="selectedTournaments"
          :key="option.type"
          :value="option.type"
        >
          {{ option.title }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="5" class="text-sm-left"><b>Bonuses:</b></b-col>
      <b-col class="text-sm-left mb-3">
        <b-form-checkbox
          v-for="option in resultData.bonuses"
          v-model="selectedBonuses"
          :key="option.type"
          :value="option.type"
        >
          {{ option.title }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="btns mt-2">
      <b-button variant="primary" @click="onChange">Save changes</b-button>
      <b-button variant="dark" @click="_cancel">Cancel</b-button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";

export default {
  name: "EditPermissionsDlg",
  components: {
    PopupModal
  },
  data() {
    return {
      selectedMain: [],
      selectedUsers: [],
      selectedAccounts: [],
      selectedTransactions: [],
      selectedChecks: [],
      selectedTournaments: [],
      selectedBonuses: [],

      rowValue: null,
      id: "",
      resultData: {
        main: [],
        users: [],
        tradingAccounts: [],
        transactions: [],
        checks: [],
        tradingTournaments: [],
        bonuses: []
      },
      updatedData: {
        isOk: false,
        permissions: []
      }
    };
  },
  computed: {
    getMain() {
      return this.resultData.main;
    },
    getUsers() {
      return this.rowValue.permissions.users;
    },
    getTradingAccounts() {
      return this.rowValue.permissions.tradingAccounts;
    },
    getTransactions() {
      return this.rowValue.permissions.transactions;
    },
    getChecks() {
      return this.rowValue.permissions.checks;
    },
    getTradingTournaments() {
      return this.rowValue.permissions.tradingTournaments;
    },
    getBonuses() {
      return this.rowValue.permissions.bonuses;
    }
  },
  methods: {
    onChange() {
      let data = {
        main: this.selectedMain.map(item => {
          return {
            type: item,
            isEnabled: true
          };
        }),
        users: this.selectedUsers.map(item => {
          return {
            type: item,
            isEnabled: true
          };
        }),
        tradingAccounts: this.selectedAccounts.map(item => {
          return {
            type: item,
            isEnabled: true
          };
        }),
        transactions: this.selectedTransactions.map(item => {
          return {
            type: item,
            isEnabled: true
          };
        }),
        checks: this.selectedChecks.map(item => {
          return {
            type: item,
            isEnabled: true
          };
        }),
        tradingTournaments: this.selectedTournaments.map(item => {
          return {
            type: item,
            isEnabled: true
          };
        }),
        bonuses: this.selectedBonuses.map(item => {
          return {
            type: item,
            isEnabled: true
          };
        })
      };

      let result = data.main
        .concat(data.users)
        .concat(data.tradingAccounts)
        .concat(data.transactions)
        .concat(data.checks)
        .concat(data.tradingTournaments)
        .concat(data.bonuses);

      this.updatedData = {
        isOk: true,
        permissions: result
      };

      this.resolvePromise(this.updatedData);
      this.$refs.popup.close();
    },
    convert(permissions) {
      return permissions
        .filter(function (obj) {
          return obj.isEnabled;
        })
        .map(function (item) {
          return item.type;
        });
    },
    show(opts = {}) {
      this.rowValue = opts.rowValue;
      this.id = opts.rowValue.id;

      let main = this.rowValue.permissions.adminPermissions.filter(x => x.group === "Main");
      let users = this.rowValue.permissions.adminPermissions.filter(x => x.group === "Users");
      let tradingAccounts = this.rowValue.permissions.adminPermissions.filter(x => x.group === "Trading accounts");
      let transactions = this.rowValue.permissions.adminPermissions.filter(x => x.group === "Transactions");
      let checks = this.rowValue.permissions.adminPermissions.filter(x => x.group === "Checks");
      let tradingTournaments = this.rowValue.permissions.adminPermissions.filter(x => x.group === "Tournaments");
      let bonuses = this.rowValue.permissions.adminPermissions.filter(x => x.group === "Bonuses");

      this.resultData = {
        main: main,
        users: users,
        tradingAccounts: tradingAccounts,
        transactions: transactions,
        checks: checks,
        tradingTournaments: tradingTournaments,
        bonuses: bonuses
      };

      this.selectedMain = this.convert(main);
      this.selectedUsers = this.convert(users);
      this.selectedAccounts = this.convert(tradingAccounts);
      this.selectedTransactions = this.convert(transactions);
      this.selectedChecks = this.convert(checks);
      this.selectedTournaments = this.convert(tradingTournaments);
      this.selectedBonuses = this.convert(bonuses);

      this.$refs.popup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}
</style>
