import moment from "moment";

export function convertDepositsList(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.paymentMethodId = item.paymentMethodId;
      container.paymentMethodTitle = item.paymentMethodTitle;
      container.currency = item.currency;
      container.totalAmount = item.totalAmount;

      container.details = [];

      if (item.comments) {
        container.details.splice(4, 0, {
          title: "Comments",
          key: "comments",
          description: item?.comments
            ? item?.comments
                .map(
                  x => `${x.admin.name} (${moment(String(x.date)).utc().format("DD.MM.YYYY HH:mm:ss")}): ${x.comment}`
                )
                .join("\n")
            : ""
        });
      }

      return container;
    });
  }
}

export function convertWithdrawalsList(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map((item, index) => {
      const container = {};

      container.tradingAccountId = item.tradingAccount?.id;
      container.login = item.tradingAccount?.login;
      container.group = item.tradingAccount?.login;
      container.index = index;

      container.paymentMethodId = item.paymentMethodId;
      container.paymentMethodTitle = item.paymentMethodTitle;
      container.currency = item.currency;
      container.totalMaxAmount = item.totalMaxAmount;
      container.totalMinAmount = item.totalMinAmount;

      container.details = [];

      if (item.comments) {
        container.details.splice(4, 0, {
          title: "Comments",
          key: "comments",
          description: item?.comments
            ? item?.comments
                .map(
                  x => `${x.admin.name} (${moment(String(x.date)).utc().format("DD.MM.YYYY HH:mm:ss")}): ${x.comment}`
                )
                .join("\n")
            : ""
        });
      }

      return container;
    });
  }
}
