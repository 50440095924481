import Repository from "./repository-base";

const resource = "platform";

export default {
  getPlatformInfo() {
    return Repository.get(`${resource}/info`);
  },
  getCountries() {
    return Repository.get(`${resource}/countries`);
  },
  getLocales() {
    return Repository.get(`${resource}/localesV2`);
  }
};
