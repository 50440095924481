import moment from "moment";
import "moment-duration-format";

export function convertAccountsList(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.id;
      container.login = item.login;
      container.mode = item.mode;
      container.user = item.user;
      container.isInternal = item.user.isInternal ? "Yes" : "No";
      container.currency = item.currency;
      container.balance = item.balance ?? 0;
      container.credit = item.credit ?? 0;
      container.leverage = item.leverage;

      container.dateRange = moment(String(item.dateCreate)).utc().format("DD.MM.YYYY HH:mm:ss");
      container.status = item.status;

      container.typeTitle = item.serverTypeTitle;
      container.platformTitle = item.serverPlatformTitle;
      container.server = item.serverTitle;
      container.serverAccount = item.serverAccountTitle;

      return container;
    });
  }
}

export function convertAccountDetails(sourceData) {
  const container = {};

  container.id = sourceData.id;
  container.login = sourceData.login;
  container.group = sourceData.group;
  container.mode = sourceData.mode;
  container.user = sourceData.user;
  container.status = sourceData.status;
  container.currency = sourceData.currency;
  container.balance = sourceData.balance ?? 0;
  container.equity = sourceData.equity ?? 0;
  container.credit = sourceData.credit ?? 0;
  container.leverage = sourceData.leverage;
  container.dateCreate = moment(String(sourceData.dateCreate)).utc().format("DD.MM.YYYY HH:mm:ss");
  container.serverTitle = sourceData.serverTitle;
  container.serverPlatformTitle = sourceData.serverPlatformTitle;
  container.serverAccountTitle = sourceData.serverAccountTitle;
  container.serverTypeTitle = sourceData.serverTypeTitle;
  container.isTradingEnabled = sourceData.isTradingEnabled;

  return container;
}

export function convertDeals(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.tradingAccountId;
      container.login = item.login;
      container.deal = item.deal;
      container.order = item.order;
      container.position = item.position;
      container.symbol = item.symbol;
      container.action = item.action;
      container.entry = item.entry;
      container.typeTitle = item.serverTypeTitle;

      container.profit = toFixed(Math.round(item.profit * 1000000) / 1000000);

      container.commission = toFixed(Math.round(item.commission * 1000000) / 1000000);
      container.swap = item.swap;
      container.volume = item.volume ?? 0;
      container.volumeBase = item.volumeBase === 0 ? "-" : item.volumeBase;
      container.volumeUsd = item.volumeUSD === 0 ? "-" : item.volumeUSD;
      container.price = toFixed(Math.round(item.price * 1000000) / 1000000);
      container.contractSize = item.contractSize;

      container.details = [
        {
          title: "Comment",
          description: item.comment,
          files: []
        },
        {
          title: "Volume Open Original Type",
          description: item.volumeOpenOriginalType,
          files: []
        },
        {
          title: "Volume Open Original",
          description: item.volumeOpenOriginal,
          files: []
        },
        {
          title: "Place Order Ip",
          description: item.placeOrderIp,
          files: []
        },
        ...(item.rateCurrencyAccountToSymbolQuote
          ? [
              {
                title: "Rate Currency Account To Symbol Quote",
                description: item.rateCurrencyAccountToSymbolQuote,
                files: []
              }
            ]
          : []),
        ...(item.rateCurrencyAccountToUsd
          ? [
              {
                title: "Rate Currency Account To Usd",
                description: item.rateCurrencyAccountToUsd,
                files: []
              }
            ]
          : []),
        ...(item.accountBalance
          ? [
              {
                title: "Account Balance",
                description: item.accountBalance,
                files: []
              }
            ]
          : []),
        ...(item.accountEquity
          ? [
              {
                title: "Account Equity",
                description: item.accountEquity,
                files: []
              }
            ]
          : []),
        ...(item.accountCredit
          ? [
              {
                title: "Account Credit",
                description: item.accountCredit,
                files: []
              }
            ]
          : []),
        ...(item.ownShare
          ? [
              {
                title: "Own Share",
                description: item.ownShare,
                files: []
              }
            ]
          : []),
        ...(item.signalType
          ? [
              {
                title: "Signal Type",
                description: item.signalType,
                files: []
              }
            ]
          : [])
      ];

      container.date = moment(String(item.date)).utc().format("DD.MM.YYYY HH:mm:ss");
      return container;
    });
  }
}
export function convertPositions(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.position = item.position;
      container.symbol = item.symbol;

      container.dateOpen = item.dateOpen ? moment(String(item.dateOpen)).utc().format("DD.MM.YYYY HH:mm:ss") : "-";
      container.dateClose = item.dateClose ? moment(String(item.dateClose)).utc().format("DD.MM.YYYY HH:mm:ss") : "-";
      container.priceOpen = item.priceOpen ? toFixed(Math.round(item.priceOpen * 1000000) / 1000000) : 0;

      container.priceClose = item.priceClose === 0 ? "-" : toFixed(Math.round(item.priceClose * 1000000) / 1000000);

      container.type = item.type;
      container.volume =
        item.type === "Buy"
          ? item.volumeBuy !== item.volumeSell
            ? item.volumeSell + "/" + item.volumeBuy
            : item.volumeBuy
          : item.volumeBuy !== item.volumeSell
          ? item.volumeBuy + "/" + item.volumeSell
          : item.volumeBuy;

      container.takeProfit =
        !item.takeProfit || item.takeProfit === 0 ? "-" : toFixed(Math.round(item.takeProfit * 1000000) / 1000000);
      container.stopLoss =
        !item.stopLoss || item.stopLoss === 0 ? "-" : toFixed(Math.round(item.stopLoss * 1000000) / 1000000);

      container.commission = toFixed(Math.round(item.commission * 1000000) / 1000000);
      container.swap = toFixed(Math.round(item.swap * 1000000) / 1000000);
      container.profit = toFixed(Math.round(item.profit * 1000000) / 1000000);

      return container;
    });
  }
}
export function convertOrders(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.tradingAccountId;
      container.login = item.login;
      container.deal = item.order;
      container.symbol = item.symbol;
      container.type = item.type;
      container.volume = item.volume ?? 0;
      container.volumeBaseOpen = item.volumeBaseOpen === 0 ? "-" : item.volumeBaseOpen;
      container.volumeUSDOpen = item.volumeUSDOpen === 0 ? "-" : item.volumeUSDOpen;
      container.volumeBaseClose = item.volumeBaseClose === 0 ? "-" : item.volumeBaseClose;
      container.volumeUSDClose = item.volumeUSDClose === 0 ? "-" : item.volumeUSDClose;

      container.typeTitle = item.serverTypeTitle;
      container.commission = toFixed(Math.round(item.commission * 1000000) / 1000000);
      container.swap = toFixed(Math.round(item.swap * 1000000) / 1000000);
      container.taxes = toFixed(Math.round(item.taxes * 1000000) / 1000000);

      container.details = [
        {
          title: "Comment",
          description: item.comment,
          files: []
        },
        ...(item.rateCurrencyAccountToSymbolQuoteOpen
          ? [
              {
                title: "Rate Currency Account To Symbol Quote Open",
                description: item.rateCurrencyAccountToSymbolQuoteOpen,
                files: []
              }
            ]
          : []),
        ...(item.rateCurrencyAccountToUsdOpen
          ? [
              {
                title: "Rate Currency Account To Usd Open",
                description: item.rateCurrencyAccountToUsdOpen,
                files: []
              }
            ]
          : []),
        ...(item.rateCurrencyAccountToSymbolQuoteClose
          ? [
              {
                title: "Rate Currency Account To Symbol Quote Close",
                description: item.rateCurrencyAccountToSymbolQuoteClose,
                files: []
              }
            ]
          : []),
        ...(item.rateCurrencyAccountToUsdClose
          ? [
              {
                title: "Rate Currency Account To Usd Close",
                description: item.rateCurrencyAccountToUsdClose,
                files: []
              }
            ]
          : []),
        ...(item.accountBalance
          ? [
              {
                title: "Account Balance",
                description: item.accountBalance,
                files: []
              }
            ]
          : []),
        ...(item.accountEquity
          ? [
              {
                title: "Account Equity",
                description: item.accountEquity,
                files: []
              }
            ]
          : []),
        ...(item.accountCredit
          ? [
              {
                title: "Account Credit",
                description: item.accountCredit,
                files: []
              }
            ]
          : []),
        ...(item.ownShare
          ? [
              {
                title: "Own Share",
                description: item.ownShare,
                files: []
              }
            ]
          : [])
      ];

      container.profit = toFixed(Math.round(item.profit * 1000000) / 1000000);
      container.dateOpen = item.dateOpen ? moment(String(item.dateOpen)).utc().format("DD.MM.YYYY HH:mm:ss") : "-";
      container.dateClose = item.dateClose ? moment(String(item.dateClose)).utc().format("DD.MM.YYYY HH:mm:ss") : "-";
      container.priceOpen = item.priceOpen ? toFixed(Math.round(item.priceOpen * 1000000) / 1000000) : 0;
      container.priceClose = item.priceClose ? toFixed(Math.round(item.priceClose * 1000000) / 1000000) : 0;

      return container;
    });
  }
}

export function convertTerminalSessions(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.from = item.from ? moment(String(item.from)).utc().format("DD.MM.YYYY HH:mm:ss") : "";
      container.to = item.to ? moment(String(item.to)).utc().format("DD.MM.YYYY HH:mm:ss") : "";

      return container;
    });
  }
}

function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x = x * Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}
