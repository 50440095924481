<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-overlay :show="isLoading" rounded="sm">
          <b-row class="mb-3">
            <b-col sm="auto">
              <h4 class="mb-0 text-left">
                <span class="mr-2">Reports</span>
              </h4>
            </b-col>
          </b-row>
          <b-row align-v="center" class="mb-3">
            <b-col sm="2">
              <b-form-select class="mt-1" :options="types" v-model="selectedType" @change="updateFilters">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select a report type</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row align-v="center" class="mb-4" v-if="usersVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Reg date:</b></label>
            </b-col>
            <b-col sm="3">
              <b-form-datepicker v-model="usersSelected.regDateFrom" />
            </b-col>
            -
            <b-col sm="3">
              <b-form-datepicker v-model="usersSelected.regDateTo" />
            </b-col>
          </b-row>
          <b-row align-v="center" class="mb-3" v-if="usersVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Last action date:</b></label>
            </b-col>
            <b-col sm="3">
              <b-form-datepicker v-model="usersSelected.lastActionDateFrom" />
            </b-col>
            -
            <b-col sm="3">
              <b-form-datepicker v-model="usersSelected.lastActionDateTo" />
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="usersVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a kyc status:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="usersSelected.kycStatuses"
                :options="usersSource.kycStatuses"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="usersVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a country:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="usersSelected.countries"
                :options="usersSource.countries"
                :searchable="true"
                :multiple="true"
                label="name"
                track-by="twoLetterCode"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="usersVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Is enabled:</b></label>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="usersSelected.isEnabled" :unchecked-value="null" class="text-sm-left">
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="usersVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Is withdrawal enabled:</b></label>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="usersSelected.isWithdrawalEnabled" :unchecked-value="null" class="text-sm-left">
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="usersVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Is email confirmed:</b></label>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="usersSelected.isEmailConfirmed" :unchecked-value="null" class="text-sm-left">
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row align-v="center" class="mb-4" v-if="txsVisible">
            <b-col sm="3">
              <b-form-datepicker v-model="txsSelected.dateFrom" />
            </b-col>
            -
            <b-col sm="3">
              <b-form-datepicker v-model="txsSelected.dateTo" />
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="txsVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a global type:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="txsSelected.globalTypes"
                :options="txsSource.globalTypes"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="txsVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a currency:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="txsSelected.currencies"
                :options="txsSource.currencies"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="txsVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select an user:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="txsSelected.users"
                :options="txsSource.users"
                label="value"
                track-by="id"
                :searchable="true"
                :multiple="true"
                :allow-empty="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Enter user email / user name"
                @search-change="getUsers"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="txsVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a country:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="txsSelected.countries"
                :options="txsSource.countries"
                label="name"
                track-by="twoLetterCode"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>

          <b-row align-v="center" class="mb-4" v-if="withdrawalsVisible">
            <b-col sm="3">
              <b-form-datepicker v-model="withdrawalsSelected.dateFrom" />
            </b-col>
            -
            <b-col sm="3">
              <b-form-datepicker v-model="withdrawalsSelected.dateTo" />
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="withdrawalsVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a transaction status:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="withdrawalsSelected.transactionStatuses"
                :options="withdrawalsSource.transactionStatuses"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="withdrawalsVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a check status:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="withdrawalsSelected.checkStatuses"
                :options="withdrawalsSource.checkStatuses"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="withdrawalsVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a payment method:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="withdrawalsSelected.paymentMethods"
                :options="withdrawalsSource.paymentMethods"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="withdrawalsVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a currency:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="withdrawalsSelected.currencies"
                :options="withdrawalsSource.currencies"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="withdrawalsVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select an user:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="withdrawalsSelected.users"
                :options="withdrawalsSource.users"
                label="value"
                track-by="id"
                :searchable="true"
                :multiple="true"
                :allow-empty="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Enter user email / user name"
                @search-change="getUsers"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="withdrawalsVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a country:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="withdrawalsSelected.countries"
                :options="withdrawalsSource.countries"
                label="name"
                track-by="twoLetterCode"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>

          <b-row align-v="center" class="mb-4" v-if="tradingVisible">
            <b-col sm="3">
              <b-form-datepicker v-model="tradingSelected.dateFrom" />
            </b-col>
            -
            <b-col sm="3">
              <b-form-datepicker v-model="tradingSelected.dateTo" />
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="tradingVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a server:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="tradingSelected.tradingServers"
                :options="tradingSource.tradingServers"
                :searchable="true"
                :multiple="true"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
                label="title"
                track-by="id"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="tradingVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select an account:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="tradingSelected.tradingServerAccounts"
                :options="tradingSource.tradingServerAccounts"
                :searchable="true"
                :multiple="true"
                :group-select="true"
                group-label="serverTitle"
                group-values="accounts"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
                label="title"
                track-by="id"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="tradingVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select an account type:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="tradingSelected.tradingServerAccountType"
                :options="tradingSource.tradingServerAccountTypes"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="tradingVisible">
            <b-col class="text-sm-left" style="max-width: 200px">
              <label class="text-sm-left"><b>Select a status:</b></label>
            </b-col>
            <b-col>
              <multiselect
                v-model="tradingSelected.isEnabledStatus"
                :options="tradingSource.isEnabledStatuses"
                :searchable="true"
                :multiple="false"
                :close-on-select="true"
                :show-labels="true"
                :taggable="true"
              >
              </multiselect>
            </b-col>
          </b-row>

          <slot v-if="statisticVisible">
            <b-row align-v="center" class="mb-4">
              <b-col sm="3">
                <b-form-datepicker v-model="statisticSelected.dateFrom" />
              </b-col>
              -
              <b-col sm="3">
                <b-form-datepicker v-model="statisticSelected.dateTo" />
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="text-sm-left" style="max-width: 200px">
                <label class="text-sm-left"><b>Select a server:</b></label>
              </b-col>
              <b-col>
                <multiselect
                  v-model="statisticSelected.platforms"
                  :options="statisticSource.platforms"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="true"
                  :show-labels="true"
                  :taggable="true"
                >
                </multiselect>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="text-sm-left" style="max-width: 200px">
                <label class="text-sm-left"><b>Select a country:</b></label>
              </b-col>
              <b-col>
                <multiselect
                  v-model="statisticSelected.countries"
                  :options="statisticSource.countries"
                  label="name"
                  track-by="twoLetterCode"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="true"
                  :show-labels="true"
                  :taggable="true"
                >
                </multiselect>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="text-sm-left" style="max-width: 200px">
                <label class="text-sm-left"><b>Select a MT group:</b></label>
              </b-col>
              <b-col>
                <multiselect
                  v-model="statisticSelected.tradingServerAccountGroups"
                  :options="statisticSource.tradingServerAccountGroups"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="true"
                  :show-labels="true"
                  :taggable="true"
                  label="title"
                  track-by="groupId"
                >
                </multiselect>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="text-sm-left" style="max-width: 200px">
                <label class="text-sm-left"><b>Select a status:</b></label>
              </b-col>
              <b-col>
                <multiselect
                  v-model="statisticSelected.statuses"
                  :options="statisticSource.statuses"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="true"
                  :show-labels="true"
                  :taggable="true"
                >
                </multiselect>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="text-sm-left" style="max-width: 200px">
                <label class="text-sm-left"><b>Select a client trading activity:</b></label>
              </b-col>
              <b-col>
                <multiselect
                  v-model="statisticSelected.activityType"
                  :options="statisticSource.activityTypes"
                  :taggable="true"
                  :show-labels="true"
                >
                  <template slot="singleLabel">
                    <b-badge class="tag"> {{ statisticSelected.activityType }} </b-badge>
                  </template>
                </multiselect>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="text-sm-left" style="max-width: 200px">
                <label class="text-sm-left"><b>Select a currency:</b></label>
              </b-col>
              <b-col>
                <multiselect
                  v-model="statisticSelected.currencies"
                  :options="statisticSource.currencies"
                  :searchable="true"
                  :multiple="true"
                  :close-on-select="true"
                  :show-labels="true"
                  :taggable="true"
                >
                </multiselect>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col class="text-sm-left" style="max-width: 200px">
                <label class="text-sm-left"><b>Enter clients (email, id or login):</b></label>
              </b-col>
              <b-col class="text-sm-right">
                <b-form-textarea class="textarea mb-3" rows="5" v-model="statisticSelected.clients" />
              </b-col>
            </b-row>
            <b-row align="left">
              <b-col>
                <b-button class="btn-success mb-1 mt-3" variant="success" @click="onCalculateSummary"
                  >Calculate Summary</b-button
                >
              </b-col>
            </b-row>
            <div v-if="statisticInfo" class="mb-3 mt-3">
              <b-row align-v="center">
                <b-col>
                  <div v-if="statisticInfo.totalTradingInfo">
                    <stats-card type="dark" icon="gear-fill" title="Total active/ paying users">
                      <template slot="footer">
                        <span class="text-success mr-2 font-weight-bold">{{
                          statisticInfo.totalTradingInfo.totalPayingUsers
                        }}</span>
                        <span class="text-nowrap">Paying users</span><br />
                        <span class="text-success mr-2 font-weight-bold">{{
                          statisticInfo.totalTradingInfo.totalActiveUsers
                        }}</span>
                        <span class="text-nowrap">Active users</span><br />
                      </template>
                    </stats-card>
                  </div>
                </b-col>
                <b-col>
                  <div v-if="statisticInfo.totalTradingAccountsInfo">
                    <stats-card
                      type="dark"
                      icon="gear-fill"
                      title="Total accounts (MT4/MT5)"
                      :sub-title="
                        statisticInfo.totalTradingAccountsInfo.totalTradingAccountsMt5 +
                        statisticInfo.totalTradingAccountsInfo.totalTradingAccountsMt4
                      "
                    >
                      <template slot="footer">
                        <span class="text-success mr-2 font-weight-bold">{{
                          statisticInfo.totalTradingAccountsInfo.totalTradingAccountsMt5
                        }}</span>
                        <span class="text-nowrap">МТ5 accounts</span><br />
                        <span class="text-success mr-2 font-weight-bold">{{
                          statisticInfo.totalTradingAccountsInfo.totalTradingAccountsMt4
                        }}</span>
                        <span class="text-nowrap">MT4 accounts</span><br />
                      </template>
                    </stats-card>
                  </div>
                </b-col>
                <b-col>
                  <div v-if="statisticInfo.totalRequestsInfo">
                    <stats-card
                      type="dark"
                      icon="gear-fill"
                      title="Total Deposits/Withdrawals"
                      :sub-title="getDepositsWithdrawalsString"
                    >
                      <template slot="footer">
                        <span class="text-success mr-2 font-weight-bold"
                          >{{ statisticInfo.totalRequestsInfo.totalDepositUsd }} USD</span
                        >
                        <span class="text-nowrap">Total Deposits</span><br />
                        <span class="text-success mr-2 font-weight-bold"
                          >{{ statisticInfo.totalRequestsInfo.totalWithdrawalUsd }} USD</span
                        >
                        <span class="text-nowrap">Total Withdrawals</span><br />
                      </template>
                    </stats-card>
                  </div>
                </b-col>
                <b-col>
                  <div v-if="statisticInfo.totalUsersInfo">
                    <stats-card type="dark" icon="gear-fill" title="Registrations/ Surveys/ KYC">
                      <template slot="footer">
                        <span class="text-success mr-2 font-weight-bold">{{
                          statisticInfo.totalUsersInfo.totalRegistrations
                        }}</span>
                        <span class="text-nowrap">Registrations</span><br />
                        <span class="text-success mr-2 font-weight-bold">{{
                          statisticInfo.totalUsersInfo.totalSurveys
                        }}</span>
                        <span class="text-nowrap">Surveys</span><br />
                        <span class="text-success mr-2 font-weight-bold">{{
                          statisticInfo.totalUsersInfo.totalKyc
                        }}</span>
                        <span class="text-nowrap">KYC</span>
                      </template>
                    </stats-card>
                  </div>
                </b-col>
              </b-row>
            </div>
          </slot>

          <b-row align-v="center">
            <b-col>
              <download-excel
                v-if="
                  txsVisible ||
                  usersVisible ||
                  tradingVisible ||
                  withdrawalsVisible ||
                  (statisticVisible && calculateSummary)
                "
                :data="resultData"
                :before-generate="updateData"
                class="btn btn-dark mt-2"
              >
                Download
              </download-excel>
            </b-col>
          </b-row>
        </b-overlay>
      </b-container>
    </b-card-header>
  </b-card>
</template>
<script>
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
const ReportsRepository = RepositoryFactory.get(RepositoryName.reports);
const UsersRepository = RepositoryFactory.get(RepositoryName.users);

import downloadExcel from "vue-json-excel";
import Multiselect from "vue-multiselect";
import StatsCard from "../../../components/cards/stats-card";
import { saveAs } from "file-saver";
import qs from "qs";

export default {
  name: "ReportsPage",
  components: {
    downloadExcel,
    Multiselect,
    StatsCard
  },
  data() {
    return {
      isLoading: false,
      calculateSummary: false,
      types: ["Transactions", "Users", "Withdrawals", "Financial statistic"],
      resultData: [],
      selectedType: null,

      filter: null,
      statisticInfo: null,

      dateFrom: "",
      dateTo: "",

      currentPage: 0,

      usersSource: {
        kycStatuses: [],
        countries: []
      },
      usersSelected: {
        kycStatuses: [],
        countries: [],

        regDateFrom: null,
        regDateTo: null,

        lastActionDateFrom: null,
        lastActionDateTo: null,

        isEnabled: null,
        isWithdrawalEnabled: null,
        isEmailConfirmed: null,
        isPhoneNumberConfirmed: null,
        hasReferrals: null,
        hasAgent: null
      },

      txsSource: {
        globalTypes: [],
        itemTypes: [],
        currencies: [],
        countries: [],
        users: []
      },
      txsSelected: {
        globalTypes: [],
        itemTypes: [],
        currencies: [],
        countries: [],
        users: [],
        dateFrom: null,
        dateTo: null
      },

      withdrawalsSource: {
        transactionStatuses: [],
        checkStatuses: [],
        currencies: [],
        countries: [],
        paymentMethods: [],
        users: []
      },
      withdrawalsSelected: {
        transactionStatuses: [],
        checkStatuses: [],
        currencies: [],
        countries: [],
        paymentMethods: [],
        users: [],
        dateFrom: null,
        dateTo: null
      },

      tradingSource: {
        tradingServers: [],
        tradingServerAccounts: [],
        tradingServerAccountTypes: [],
        isEnabledStatuses: []
      },
      tradingSelected: {
        tradingServers: [],
        tradingServerAccounts: [],
        tradingServerAccountType: null,
        isEnabledStatus: null,
        dateFrom: null,
        dateTo: null
      },

      statisticSource: {
        currencies: [],
        countries: [],
        platforms: [],
        tradingServerAccountGroups: [],
        statuses: [],
        activityTypes: []
      },
      statisticSelected: {
        countries: [],
        clients: "",
        tradingServerAccountGroups: [],
        currencies: [],
        platforms: [],
        statuses: [],
        activityType: null,
        dateFrom: null,
        dateTo: null
      },

      txsVisible: false,
      usersVisible: false,
      tradingVisible: false,
      withdrawalsVisible: false,
      statisticVisible: false
    };
  },
  computed: {
    getDepositsWithdrawalsString() {
      return (
        this.statisticInfo?.totalRequestsInfo?.totalDeposits +
        " / " +
        this.statisticInfo?.totalRequestsInfo?.totalWithdrawals
      );
    }
  },
  methods: {
    async updateFilters(value) {
      switch (value) {
        case "Transactions":
          this.txsVisible = true;
          this.usersVisible = this.tradingVisible = this.withdrawalsVisible = this.statisticVisible = false;

          this.selectedType = value;
          await this.getTransactionsFilters();
          return;
        case "Users":
          this.usersVisible = true;
          this.txsVisible = this.tradingVisible = this.withdrawalsVisible = this.statisticVisible = false;

          this.selectedType = value;
          await this.getUsersFilters();
          return;
        case "Trading":
          this.tradingVisible = true;
          this.txsVisible = this.usersVisible = this.withdrawalsVisible = this.statisticVisible = false;

          this.selectedType = value;
          await this.getTradingFilters();
          return;
        case "Withdrawals":
          this.withdrawalsVisible = true;
          this.txsVisible = this.usersVisible = this.tradingVisible = this.statisticVisible = false;

          this.selectedType = value;
          await this.getWithdrawalsFilters();
          return;
        case "Financial statistic":
          this.statisticVisible = true;
          this.txsVisible = this.usersVisible = this.tradingVisible = this.withdrawalsVisible = false;

          this.selectedType = value;
          await this.getStatisticFilters();
          return;
      }
    },
    async onCalculateSummary() {
      this.isLoading = true;
      this.calculateSummary = true;

      if (this.statisticSelected.dateTo) {
        var dateToResult = new Date(this.statisticSelected.dateTo);
        dateToResult.setDate(dateToResult.getDate() + 1);
      }

      let clients = this.statisticSelected.clients !== "" ? this.statisticSelected.clients.split(/[,\s\n]/) : [];

      let filter = {
        params: {
          dateFrom: this.statisticSelected.dateFrom ? new Date(this.statisticSelected.dateFrom) : null,
          dateTo: dateToResult,
          countries: this.statisticSelected.countries.filter(x => x.name !== "All").map(x => x.twoLetterCode),
          clients: clients,
          tradingAccountGroups: this.statisticSelected.tradingServerAccountGroups.find(x => x.groupId === "All")
            ? this.statisticSource.tradingServerAccountGroups
                .filter(x => x.groupId !== "All")
                .map(x => {
                  return x.groupId;
                })
            : this.statisticSelected.tradingServerAccountGroups.map(x => {
                return x.groupId;
              }),
          currencies: this.statisticSelected.currencies.includes("All")
            ? this.statisticSource.currencies
            : this.statisticSelected.currencies,
          platforms: this.statisticSelected.platforms.includes("All")
            ? this.statisticSource.platforms.filter(x => x !== "All")
            : this.statisticSelected.platforms,
          statuses: this.statisticSelected.statuses.includes("All")
            ? this.statisticSource.statuses.filter(x => x !== "All")
            : this.statisticSelected.statuses,
          activityType: this.statisticSelected.activityType !== "All" ? this.statisticSelected.activityType : null
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      };

      let axiosResponse = await ReportsRepository.getFinancialStatisticInfo(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      this.statisticInfo = axiosResponse.data;

      this.isLoading = false;
    },
    async updateData() {
      switch (this.selectedType) {
        case "Transactions":
          await this.getTransactionsReport();
          return;
        case "Users":
          await this.getUsersReport();
          return;
        case "Trading":
          await this.getTradingReport();
          return;
        case "Withdrawals":
          await this.getWithdrawalsReport();
          return;
        case "Financial statistic":
          await this.getFinancialStatisticReport();
          return;
      }
    },
    //#region Get reports
    async getTransactionsReport() {
      let users = this.txsSelected.users
        .map(x => {
          return x.id;
        })
        .includes(0)
        ? this.txsSource.users
        : this.txsSelected.users;

      if (this.txsSelected.dateTo) {
        var dateToResult = new Date(this.txsSelected.dateTo);
        dateToResult.setDate(dateToResult.getDate() + 1);
      }

      let filter = {
        globalTypes: this.txsSelected.globalTypes,
        itemTypes: this.txsSelected.itemTypes,
        currencies: this.txsSelected.currencies.includes("All")
          ? this.txsSource.currencies
          : this.txsSelected.currencies,
        countries: this.txsSelected.countries.filter(x => x !== "All").map(x => x.twoLetterCode),

        dateFrom: this.txsSelected.dateFrom ? new Date(this.txsSelected.dateFrom) : null,
        dateTo: dateToResult,
        userIds: users
          .map(x => {
            return x.id;
          })
          .filter(x => {
            return x !== 0;
          })
      };

      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getTransactions(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let transactionsResponse = axiosResponse.data;

      this.resultData = transactionsResponse;
      this.isLoading = false;

      let currentDate = new Date();
      saveAs(axiosResponse.data, "TransactionsReport_" + currentDate.toLocaleString() + ".xlsx");
    },
    async getWithdrawalsReport() {
      let users = this.withdrawalsSelected.users
        .map(x => {
          return x.id;
        })
        .includes(0)
        ? this.withdrawalsSource.users
        : this.withdrawalsSelected.users;

      if (this.withdrawalsSelected.dateTo) {
        var dateToResult = new Date(this.withdrawalsSelected.dateTo);
        dateToResult.setDate(dateToResult.getDate() + 1);
      }

      let filter = {
        transactionStatuses: this.withdrawalsSelected.transactionStatuses,
        checkStatuses: this.withdrawalsSelected.checkStatuses,
        currencies: this.withdrawalsSelected.currencies.includes("All")
          ? this.withdrawalsSource.currencies
          : this.withdrawalsSelected.currencies,
        countries: this.withdrawalsSelected.countries.filter(x => x !== "All").map(x => x.twoLetterCode),
        paymentMethods: this.withdrawalsSelected.paymentMethods,

        dateFrom: this.withdrawalsSelected.dateFrom ? new Date(this.withdrawalsSelected.dateFrom) : null,
        dateTo: dateToResult,
        userIds: users
          .map(x => {
            return x.id;
          })
          .filter(x => {
            return x !== 0;
          })
      };

      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getWithdrawals(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let withdrawalsResponse = axiosResponse.data;

      this.resultData = withdrawalsResponse;
      this.isLoading = false;

      let currentDate = new Date();
      saveAs(axiosResponse.data, "WithdrawalsReport_" + currentDate.toLocaleString() + ".xlsx");
    },
    async getFinancialStatisticReport() {
      if (this.statisticSelected.dateTo) {
        var dateToResult = new Date(this.statisticSelected.dateTo);
        dateToResult.setDate(dateToResult.getDate() + 1);
      }

      let clients = this.statisticSelected.clients !== "" ? this.statisticSelected.clients.split(/[,\s\n]/) : [];

      let filter = {
        dateFrom: this.statisticSelected.dateFrom ? new Date(this.statisticSelected.dateFrom) : null,
        dateTo: dateToResult,
        countries: this.statisticSelected.countries.filter(x => x.name !== "All").map(x => x.twoLetterCode),
        clients: clients,
        tradingAccountGroups: this.statisticSelected.tradingServerAccountGroups.find(x => x.groupId === "All")
          ? this.statisticSource.tradingServerAccountGroups
              .filter(x => x.groupId !== "All")
              .map(x => {
                return x.groupId;
              })
          : this.statisticSelected.tradingServerAccountGroups.map(x => {
              return x.groupId;
            }),
        currencies: this.statisticSelected.currencies.includes("All")
          ? this.statisticSource.currencies
          : this.statisticSelected.currencies,
        platforms: this.statisticSelected.platforms.includes("All")
          ? this.statisticSource.platforms.filter(x => x !== "All")
          : this.statisticSelected.platforms,
        statuses: this.statisticSelected.statuses.includes("All")
          ? this.statisticSource.statuses.filter(x => x !== "All")
          : this.statisticSelected.statuses,
        activityType: this.statisticSelected.activityType !== "All" ? this.statisticSelected.activityType : null
      };

      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getFinancialStatistic(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let statisticResponse = axiosResponse.data;

      this.resultData = statisticResponse;
      this.isLoading = false;

      let period = "";

      if (this.statisticSelected.dateFrom || this.statisticSelected.dateTo) {
        if (this.statisticSelected.dateFrom) period += "from " + filter.dateFrom.toLocaleDateString() + "_";
        if (this.statisticSelected.dateTo)
          period += "to " + new Date(this.statisticSelected.dateTo).toLocaleDateString() + "";
      } else period += "all time";

      saveAs(axiosResponse.data, "FinancialStatisticReport_" + period + ".xlsx");
    },
    async getUsersReport() {
      if (this.usersSelected.regDateTo) {
        var regDateToResult = new Date(this.usersSelected.regDateTo);
        regDateToResult.setDate(regDateToResult.getDate() + 1);
      }

      if (this.usersSelected.lastActionDateTo) {
        var lastActionDateToResult = new Date(this.usersSelected.lastActionDateTo);
        lastActionDateToResult.setDate(lastActionDateToResult.getDate() + 1);
      }

      let filter = {
        kycStatuses: this.usersSelected.kycStatuses,
        countries: this.usersSelected.countries.filter(x => x.name !== "All").map(x => x.twoLetterCode),

        regDateFrom: this.usersSelected.regDateFrom ? new Date(this.usersSelected.regDateFrom) : null,
        regDateTo: regDateToResult,
        lastActionDateFrom: this.usersSelected.lastActionDateFrom
          ? new Date(this.usersSelected.lastActionDateFrom)
          : null,
        lastActionDateTo: lastActionDateToResult,

        isEnabled: this.usersSelected.isEnabled,
        isWithdrawalEnabled: this.usersSelected.isWithdrawalEnabled,
        isEmailConfirmed: this.usersSelected.isEmailConfirmed,
        isPhoneNumberConfirmed: this.usersSelected.isPhoneNumberConfirmed,
        hasReferrals: this.usersSelected.hasReferrals,
        hasAgent: this.usersSelected.hasAgent
      };

      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getUsers(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let usersResponse = axiosResponse.data;

      this.resultData = usersResponse;
      this.isLoading = false;

      let currentDate = new Date();
      saveAs(axiosResponse.data, "UsersReport_" + currentDate.toLocaleString() + ".xlsx");
    },
    async getTradingReport() {
      if (this.tradingSelected.dateCreateTo) {
        var dateCreateToResult = new Date(this.tradingSelected.dateCreateTo);
        dateCreateToResult.setDate(dateCreateToResult.getDate() + 1);
      }

      let filter = {
        tradingServerIds: this.tradingSelected.tradingServers.map(x => {
          return x.id;
        }),
        tradingServerAccountsIds: this.tradingSelected.tradingServerAccounts.map(x => {
          return x.id;
        }),
        tradingServerAccountType:
          this.tradingSelected.tradingServerAccountType === "All"
            ? null
            : this.tradingSelected.tradingServerAccountType,
        isTradingEnabled:
          this.tradingSelected.isEnabledStatus === "All" || this.tradingSelected.isEnabledStatus === null
            ? null
            : this.tradingSelected.isEnabledStatus === "Active",
        dateCreateTo: dateCreateToResult
      };

      console.log(filter);
      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getTrading(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let tradingResponse = axiosResponse.data;

      this.resultData = tradingResponse;
      this.isLoading = false;

      let currentDate = new Date();
      saveAs(axiosResponse.data, "TradingReport_" + currentDate.toLocaleString() + ".xlsx");
    },
    //#endregion

    //#region Get filters
    async getTransactionsFilters() {
      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getTransactionsFilterInfo();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      let filterInfo = axiosResponse.data;
      this.isLoading = false;

      this.txsSource.globalTypes = filterInfo.globalTypes;
      this.txsSource.itemTypes = filterInfo.itemTypes;

      this.txsSource.currencies = filterInfo.currencies;
      this.txsSource.currencies.unshift("All");

      this.txsSource.countries = filterInfo.countries;
    },
    async getWithdrawalsFilters() {
      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getWithdrawalsFilterInfo();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      let filterInfo = axiosResponse.data;
      this.isLoading = false;

      this.withdrawalsSource.transactionStatuses = filterInfo.transactionStatuses;
      this.withdrawalsSource.checkStatuses = filterInfo.checkStatuses;
      this.withdrawalsSource.paymentMethods = filterInfo.paymentMethods;

      this.withdrawalsSource.currencies = filterInfo.currencies;
      this.withdrawalsSource.currencies.unshift("All");

      this.withdrawalsSource.countries = filterInfo.countries;
    },
    async getUsersFilters() {
      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getUsersFilterInfo(this.companyId);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      let filterInfo = axiosResponse.data;
      this.isLoading = false;

      this.usersSource.kycStatuses = filterInfo.kycStatuses;
      this.usersSource.countries = filterInfo.countries;
    },
    async getTradingFilters() {
      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getTradingFilters();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      let filterInfo = axiosResponse.data;
      this.isLoading = false;

      this.tradingSource.tradingServers = filterInfo.servers;
      this.tradingSource.tradingServerAccounts = filterInfo.servers.map(item => {
        const accounts = item.accounts.map(x => {
          return {
            id: x.id,
            title: x.title + " (" + x.type + ")"
          };
        });
        return { accounts, serverTitle: item.title };
      });

      this.tradingSource.tradingServerAccountTypes = ["All", "Real", "Demo"];
      this.tradingSource.isEnabledStatuses = ["All", "Active", "Disabled"];
    },
    async getStatisticFilters() {
      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getFinancialStatisticFilters();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      let filterInfo = axiosResponse.data;
      this.isLoading = false;

      this.statisticSource.platforms = filterInfo.platforms;
      this.statisticSource.platforms.unshift("All");

      this.statisticSource.tradingServerAccountGroups = filterInfo.servers
        .map(server =>
          server.accounts
            .filter(account => account.type === "Real")
            .map(account =>
              account.groups.map(group => {
                const result = {
                  groupId: group,
                  title: server.typeTitle + " - " + account.title + " ( " + account.type + " ) " + " - " + group
                };
                return result;
              })
            )
        )
        .flat(2);
      this.statisticSource.tradingServerAccountGroups.unshift({
        groupId: "All",
        title: "All"
      });

      this.statisticSource.countries = filterInfo.countries;
      this.statisticSource.countries.unshift({
        twoLetterCode: 0,
        name: "All"
      });

      this.statisticSource.statuses = filterInfo.statuses;
      this.statisticSource.statuses.unshift("All");

      this.statisticSource.activityTypes = filterInfo.activityTypes;
      this.statisticSource.activityTypes.unshift("All");

      this.statisticSource.currencies = filterInfo.currencies;
      this.statisticSource.currencies.unshift("All");
    },
    async getUsers(query) {
      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,

          mask: query
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUsers(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      let usersResponse = axiosResponse.data.items;
      this.isLoading = false;

      if (this.txsVisible) {
        this.txsSource.users = usersResponse.map(item => {
          return {
            id: item.id,
            value: item.name + " (" + item.email + ")"
          };
        });
        this.txsSource.users.unshift({ id: 0, value: "All" });
      }

      if (this.withdrawalsVisible) {
        this.withdrawalsSource.users = usersResponse.map(item => {
          return {
            id: item.id,
            value: item.name + " (" + item.email + ")"
          };
        });
        this.withdrawalsSource.users.unshift({ id: 0, value: "All" });
      }
    }
    //#endregion
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
.cards-wrapper {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
</style>
<style scoped lang="scss">
.tag {
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
  background: #41b883;
  margin: 0 !important;
}
</style>
