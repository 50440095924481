<template>
  <div>
    <h1 v-if="name" class="text-left main-header mb-3">Hello, {{ name }}!</h1>
    <div v-if="statsResult" class="cards-wrapper mb-3">
      <div v-if="statsResult.users">
        <stats-card
          title="Total users / Active users"
          :sub-title="statsResult.users.total + ' / ' + statsResult.users.active"
          type="warning"
          icon="person-plus-fill"
          link="/users-list"
        >
          <template slot="footer" v-if="statsResult.users.lastMonth && hasFinanceInfoRead">
            <span class="text-success mr-2 font-weight-bold">{{ statsResult.users.totalBalanceUsd }} USD</span>
            <span class="text-nowrap">Total balance</span><br />
            <span class="text-success mr-2 font-weight-bold">{{ statsResult.users.lastMonth }}</span>
            <span class="text-nowrap">Since last month</span>
          </template>
        </stats-card>
      </div>
      <div v-if="statsResult.tradingAccounts">
        <stats-card
          title="Total trading accounts (Live/Demo)"
          :sub-title="statsResult.tradingAccounts.totalReal + ' / ' + statsResult.tradingAccounts.totalDemo"
          type="info"
          icon="wallet-fill"
          link="/trading-accounts-list"
        >
          <template slot="footer" v-if="statsResult.tradingAccounts.lastMonth && hasFinanceInfoRead">
            <span class="text-success mr-2 font-weight-bold"
              >{{ statsResult.tradingAccounts.totalBalanceUsd }} USD</span
            >
            <span class="text-nowrap">Total balance (Live)</span><br />
            <span class="text-success mr-2 font-weight-bold">{{ statsResult.tradingAccounts.lastMonth }}</span>
            <span class="text-nowrap">Since last month</span>
          </template>
        </stats-card>
      </div>
      <div v-if="statsResult.kyc">
        <stats-card
          title="KYC requests"
          :sub-title="statsResult.kyc.newRequests"
          type="danger"
          icon="shield-fill-exclamation"
          :link="getKycStatusesLink"
        >
        </stats-card>
      </div>
      <div v-if="statsResult.withdrawRequests">
        <stats-card
          title="Withdrawal requests"
          type="success"
          icon="wallet-fill"
          link="/withdrawals"
          :sub-title="
            statsResult.withdrawRequests.newRequestsCount + statsResult.withdrawRequests.inProgressRequestsCount
          "
        >
          <template slot="footer" v-if="statsResult.withdrawRequests">
            <span class="text-success mr-2 font-weight-bold statistic-title">{{
              statsResult.withdrawRequests.newRequestsCount
            }}</span>
            <span class="text-nowrap">New</span><br />
            <span class="text-success mr-2 font-weight-bold statistic-title">{{
              statsResult.withdrawRequests.inProgressRequestsCount
            }}</span>
            <span class="text-nowrap">In progress</span>
          </template>
        </stats-card>
      </div>
    </div>
    <b-row v-if="!is2FaEnabled">
      <b-col>
        <b-card no-body border-variant="danger" class="shadow bg-white rounded mb-2">
          <b-card-header class="red text-left" style="background-color: #ffcccc">
            <b-icon class="mr-4 red" icon="shield-x" font-scale="1.5" style="font-size: 2rem"></b-icon>
            <b class="mr-4 red">Two Factor Authentication</b>To protect your data, we strongly recommend you to enable
            two factor authentication. You can do this at any time in your CRM account.</b-card-header
          >
        </b-card>
      </b-col>
    </b-row>
    <b-card border-variant="light" no-body>
      <div class="tabs-container">
        <b-tabs content-class="mt-3" card pills>
          <b-tab no-body>
            <template slot="title"> Permissions </template>
            <common-data-selection-grid
              :ref="refKeyCommonDataSelectionGrid"
              :data-result-list="permissionsResult"
              :fields-list="permissionsFields"
              :is-busy="isLoading"
            >
            </common-data-selection-grid>
          </b-tab>
          <b-tab no-body>
            <template slot="title">
              Action logs
              <b-badge pill variant="secondary">{{ logsTotalRows }}</b-badge>
            </template>
            <common-data-selection-grid
              :ref="refKeyCommonDataSelectionGrid"
              :data-result-list="logsResult"
              :fields-list="logsFields"
              :is-busy="isLoading"
              :total-rows="logsTotalRows"
              :per-page="perPage"
              @pg-clicked="onLogsPgClicked"
            >
            </common-data-selection-grid>
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import CommonDataSelectionGrid from "../../components/grids/common-data-selection-grid";
import StatsCard from "../../components/cards/stats-card";
import { convertLogsList } from "@/modules/sections/helpers/logs-converter";
import { mapGetters } from "vuex";

const ProfileRepository = RepositoryFactory.get(RepositoryName.profile);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "home-page",
  components: {
    CommonDataSelectionGrid,
    StatsCard
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,

      perPage: 10,
      filter: null,

      logsTotalRows: 1,
      isLoading: false,

      logsResult: [],
      statsResult: null,
      name: null,
      is2FaEnabled: true,
      externalKycStatuses: ["Pending", "Resubmission requested", "Requires action"],
      permissionsResult: [],
      permissionsFields: [
        {
          key: "email",
          label: "Email",
          sortDirection: "desc",
          tdClass: "fieldsClass table-cell-wider"
        },
        {
          key: "permissions.main",
          label: "Main",
          sortDirection: "desc",
          tdClass: "permissionFields text-left"
        },
        {
          key: "permissions.users",
          label: "Users",
          sortDirection: "desc",
          tdClass: "permissionFields text-left"
        },
        {
          key: "permissions.tradingAccounts",
          label: "Trading accounts",
          sortDirection: "desc",
          tdClass: "permissionFields text-left"
        },
        {
          key: "permissions.transactions",
          label: "Transactions",
          sortDirection: "desc",
          tdClass: "permissionFields text-left"
        },
        {
          key: "permissions.checks",
          label: "Checks",
          sortDirection: "desc",
          tdClass: "permissionFields text-left"
        },
        {
          key: "permissions.tradingTournaments",
          label: "Trading Tournaments",
          sortDirection: "desc",
          tdClass: "permissionFields text-left"
        },
        {
          key: "permissions.bonuses",
          label: "Bonuses",
          sortDirection: "desc",
          tdClass: "permissionFields text-left"
        }
      ],
      logsFields: [
        {
          key: "date",
          label: "Date",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "ip",
          label: "Ip",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "group",
          label: "Group",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "action",
          label: "Action",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "data",
          label: "Data",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "user",
          label: "Person",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "admin",
          label: "Action by user",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        }
      ]
    };
  },
  mounted: async function () {
    await this.getLogs();
    await this.getPermissions();
    await this.getUserStatisticResult();
  },
  computed: {
    ...mapGetters(["userProfile", "hasFinanceInfoRead"]),
    getKycStatusesLink() {
      return "/users-list?externalKycStatuses=" + this.externalKycStatuses;
    }
  },
  methods: {
    async onLogsPgClicked(value) {
      this.currentPage = value;
      await this.getLogs().catch(error => {
        console.error(error);
      });
    },
    async getLogs() {
      await this.getRequestResult();
    },
    async getPermissions() {
      let profile = this.userProfile;

      let result = {
        email: profile.email,
        id: profile.id,
        permissions: {
          main: profile.permissions.adminPermissions.filter(obj => obj.group === "Main"),
          users: profile.permissions.adminPermissions.filter(obj => obj.group === "Users"),
          tradingAccounts: profile.permissions.adminPermissions.filter(obj => obj.group === "Trading accounts"),
          transactions: profile.permissions.adminPermissions.filter(obj => obj.group === "Transactions"),
          checks: profile.permissions.adminPermissions.filter(obj => obj.group === "Checks"),
          tradingTournaments: profile.permissions.adminPermissions.filter(obj => obj.group === "Tournaments"),
          bonuses: profile.permissions.adminPermissions.filter(obj => obj.group === "Bonuses")
        },
        isEmailConfirmed: true
      };

      this.permissionsResult.push(result);
      this.name = profile.name || profile.email;
      this.is2FaEnabled = profile.is2FaEnabled;
    },
    async getRequestResult() {
      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,

          take: this.perPage,
          mask: this.filter
        }
      };

      this.isLoading = true;
      let axiosResponse = await ProfileRepository.getUserLogs(filter);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.logsTotalRows = axiosResponse.data.total;
      let logsResponse = axiosResponse.data;
      this.isLoading = false;

      this.logsResult = convertLogsList(logsResponse.items);
    },
    async getUserStatisticResult() {
      let axiosResponse = await ProfileRepository.getUserStatistic();

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      this.statsResult = axiosResponse.data;
    }
  }
};
</script>

<style scoped lang="scss">
.main-header {
  color: #ffffff;
}
.red {
  color: #b80019;
}

.cards-wrapper {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
</style>
