<template>
  <div class="signup-container">
    <b-card border-variant="light">
      <b-card-header> </b-card-header>
      <b-card-body class="card-body">
        <b-form-row>
          <b-form-input
            class="input-item"
            v-model="userEmail"
            placeholder="Enter your email"
            @keyup.enter="onClickUserSingUp"
          />
          <b-form-input
            class="input-item"
            v-model="password"
            type="password"
            placeholder="Enter password"
            @keyup.enter="onClickUserSingUp"
          />
          <b-button variant="dark" size="lg" block class="input-item" type="button" @click="onClickUserSingUp">
            Create account
          </b-button>
        </b-form-row>
        <b-row v-if="isLoading" class="load-row">
          <b-spinner :variant="'dark'"></b-spinner>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";

const AuthRepository = RepositoryFactory.get(RepositoryName.auth);
export default {
  name: "Signup",
  data() {
    return {
      userEmail: "",
      password: "",
      captchaToken: "",
      rememberMe: false,

      resultData: "",
      isLoading: false
    };
  },
  methods: {
    async onClickUserSingUp() {
      await this.getCaptcha();
      await this.authUserSingUp();
    },
    async getCaptcha() {
      this.isLoading = true;

      await this.$recaptchaLoaded();
      this.captchaToken = await this.$recaptcha("signUp");

      this.isLoading = false;
    },
    async authUserSingUp() {
      const data = {
        email: this.userEmail,
        password: this.password,
        refCode: null,
        captcha: {
          challengeGoogleRecaptchaV3: {
            token: this.captchaToken
          }
        }
      };

      this.isLoading = true;
      let axiosResponse = await AuthRepository.signUp(data);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message: "Registration successful",
        blockClass: "msg-block"
      });

      this.userEmail = "";
      this.password = "";

      this.isLoading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.signup-container {
  max-width: 700px;
  margin: 0 auto;
  @include media-breakpoint-tablet() {
    margin-top: 150px;
  }
}

.card-body {
  @include media-breakpoint-tablet() {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  }
}

.load-row {
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
</style>
