<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">Transactions</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
          <b-col sm="auto" class="ml-auto" v-if="hasReportsRead">
            <download-excel :before-generate="getTransactionsReport" class="btn btn-light"> Download </download-excel>
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="transactionsResultList"
      :fields-list="fields"
      :filters-list="sourceFiltersList"
      :filters-model="filtersModel"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :has-details="false"
      :is-filtered="true"
      :need-per-page="true"
      @load-clicked="onLoadClicked"
      @pg-clicked="onPgClicked"
      @srch-clicked="onSrchClicked"
      @filter-clicked="onFilterClicked"
    >
    </common-data-selection-grid>
  </b-card>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import CommonDataSelectionGrid from "../../../components/grids/common-data-selection-grid";
import { convertTransactionsList } from "@/modules/sections/helpers/transactions-converter";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import downloadExcel from "vue-json-excel";
import { saveAs } from "file-saver";
import { mapGetters } from "vuex";

const TransactionsRepository = RepositoryFactory.get(RepositoryName.transactions);
const ReportsRepository = RepositoryFactory.get(RepositoryName.reports);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "TransactionsList",
  components: {
    CommonDataSelectionGrid,
    downloadExcel
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,
      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,

      sourceFiltersList: [],

      transactionsResultList: [],
      resultData: [],

      filtersModel: [
        {
          key: "first",
          selected: ""
        },
        {
          key: "second",
          selected: ""
        },
        {
          key: "dateRangeStart",
          selected: ""
        },
        {
          key: "dateRangeEnd",
          selected: ""
        }
      ]
    };
  },
  mounted: async function () {
    await this.getTransactions();
    await this.getFiltersList();
  },
  computed: {
    ...mapGetters(["hasReportsRead"]),
    fields() {
      return [
        {
          key: "number",
          label: "Number",
          sortDirection: "desc",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "user.email",
          label: "User",
          sortDirection: "desc",
          tdClass: "fieldsClass table-cell-wider",
          filtered: false
        },
        {
          key: "dateRange",
          type: "date",
          label: "Date",
          sortDirection: "desc",
          tdClass: "dateField",
          filtered: true
        },
        {
          key: "first",
          label: "Source",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "second",
          label: "Destination",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "description",
          label: "Description",
          sortDirection: "asc",
          tdClass: "responsive-field",
          filtered: false
        },
        {
          key: "typeTitle",
          label: "Type",
          sortDirection: "asc",
          tdClass: "responsive-field",
          filtered: false
        },
        {
          key: "paymentMethod",
          label: "Payment Method",
          sortDirection: "asc",
          tdClass: "responsive-field",
          filtered: false
        },
        {
          key: "transactionInfoUrl",
          label: "Transaction Info Url",
          sortDirection: "asc",
          tdClass: "responsive-field",
          filtered: false
        },
        {
          key: "status.status",
          label: "Status",
          sortDirection: "asc",
          filtered: false
        }
      ];
    }
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getTransactions().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getTransactions().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getTransactions().catch(error => {
        console.error(error);
      });
    },
    async onFilterClicked(value, textMask) {
      this.filtersModel = value;
      this.filter = textMask;

      await this.getTransactions().catch(error => {
        console.error(error);
      });
    },
    async getTransactions() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      let sourceCurrency = this.filtersModel.find(item => item.key === "first");
      let destinationCurrency = this.filtersModel.find(item => item.key === "second");
      let dateStart = this.filtersModel.find(item => item.key === "dateRangeStart");
      let dateEnd = this.filtersModel.find(item => item.key === "dateRangeEnd");

      if (dateEnd.selected) {
        var dateEndResult = new Date(dateEnd.selected);
        dateEndResult.setDate(dateEndResult.getDate() + 1);
      }

      let filter = {
        params: {
          userId: this.$route.query.userId,
          tradingAccountId: this.$route.query.tradingAccountId,
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.filter,

          sourceCurrency: sourceCurrency.selected,
          destinationCurrency: destinationCurrency.selected,

          dateStart: dateStart.selected,
          dateEnd: dateEndResult
        }
      };

      this.isLoading = true;
      let axiosResponse = await TransactionsRepository.getTransactions(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let transactionsResponse = axiosResponse.data;

      this.resultData = transactionsResponse.items;
      this.isLoading = false;

      this.transactionsResultList = convertTransactionsList(this.resultData);
    },
    async getTransactionsReport() {
      let sourceCurrency = this.filtersModel.find(item => item.key === "first");
      let destinationCurrency = this.filtersModel.find(item => item.key === "second");
      let dateStart = this.filtersModel.find(item => item.key === "dateRangeStart");
      let dateEnd = this.filtersModel.find(item => item.key === "dateRangeEnd");

      if (dateEnd.selected) {
        var dateEndResult = new Date(dateEnd.selected);
        dateEndResult.setDate(dateEndResult.getDate() + 1);
      }

      let userIds = [];
      if (this.$route.query.userId) userIds = [this.$route.query.userId];

      let tradingAccountsIds = [];
      if (this.$route.query.tradingAccountId) tradingAccountsIds = [this.$route.query.tradingAccountId];

      let filter = {
        sourceCurrency: sourceCurrency.selected,
        destinationCurrency: destinationCurrency.selected,
        userIds: userIds,
        tradingAccountsIds: tradingAccountsIds,
        dateFrom: dateStart.selected,
        dateTo: dateEndResult
      };

      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getTransactions(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.isLoading = false;

      let currentDate = new Date();
      saveAs(axiosResponse.data, "TransactionsReport_" + currentDate.toLocaleString() + ".xlsx");
    },
    //#region Filters
    async getFiltersList() {
      this.isLoading = true;
      let axiosResponse = await TransactionsRepository.getTransactionsFilterInfo(this.companyId);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      let filterInfo = axiosResponse.data;
      this.isLoading = false;

      this.sourceFiltersList = [];
      this.sourceFiltersList.push(await this.filters_getSourceCurrencies(filterInfo));
      this.sourceFiltersList.push(await this.filters_getDestinationCurrencies(filterInfo));
    },
    async filters_getSourceCurrencies(filtersInfo) {
      if (filtersInfo.sourceCurrencies) {
        return {
          key: "first",
          items: filtersInfo.sourceCurrencies
        };
      }
    },
    async filters_getDestinationCurrencies(filtersInfo) {
      if (filtersInfo.destinationCurrencies) {
        return {
          key: "second",
          items: filtersInfo.destinationCurrencies
        };
      }
    }
    //#endregion
  }
};
</script>
