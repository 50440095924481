import Repository from "./repository-base";

const resource = "admin/manage";

export default {
  updateUserPermissions(id, permissions) {
    return Repository.post(`${resource}/users/${id}/permissions`, permissions);
  },
  getLogs(filter) {
    return Repository.get(`${resource}/logs`, filter);
  },
  getEmailTemplates(filter) {
    return Repository.get(`${resource}/email/templates`, filter);
  },
  updateEmails(request) {
    return Repository.post(`${resource}/email/templates`, request);
  }
};
