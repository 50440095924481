<template>
  <popup-modal ref="popup">
    <h4 class="mb-4 comments">
      <b>{{ message }}</b>
    </h4>
    <b-container class="popup" fluid>
      <b-row class="mb-2">
        <b-col sm="3 text-sm-left">
          <label><b>Leverage: </b></label>
        </b-col>
        <b-col>
          <b-input :state="leverage > 0" v-model="leverage" />
        </b-col>
      </b-row>
    </b-container>
    <div class="btns mt-3">
      <b-button variant="primary" @click="onSave" :disabled="leverage <= 0">Save</b-button>
      <b-button variant="dark" @click="_cancel">Cancel</b-button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";
export default {
  name: "EditTradingAccountLeverageDlg",
  components: { PopupModal },
  data() {
    return {
      message: "",
      leverage: 0,

      updatedData: {
        isOk: false,
        leverage: 0
      }
    };
  },
  methods: {
    onSave() {
      this.updatedData = {
        isOk: true,
        leverage: this.leverage
      };

      this.resolvePromise(this.updatedData);
      this.resetState();

      this.$refs.popup.close();
    },
    show(opts = {}) {
      this.message = opts.message;
      this.leverage = opts.leverage;

      this.$refs.popup.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _cancel() {
      this.resetState();
      this.$refs.popup.close();
      this.resolvePromise(false);
    },
    resetState() {
      this.leverage = 0;
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.popup {
  @include media-breakpoint-tablet() {
    width: 30rem;
  }
}
</style>
