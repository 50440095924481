<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">AML Checks</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col sm="auto">
            <b-form-datepicker v-model="selectedDatesRange.dateAndTimeStart" @input="onFilterValueChange" />
          </b-col>
          -
          <b-col sm="auto">
            <b-form-datepicker v-model="selectedDatesRange.dateAndTimeEnd" @input="onFilterValueChange" />
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="amlResultList"
      :fields-list="fields"
      :has-details="true"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :need-per-page="true"
      @load-clicked="onLoadClicked"
      @sort-clicked="onSortClicked"
      @srch-clicked="onSrchClicked"
      @pg-clicked="onPgClicked"
      @filter-clicked="onFilterClicked"
      @approve-clicked="getAmlChecks"
    >
    </common-data-selection-grid>
    <aml-dlg ref="AmlDlg"></aml-dlg>
  </b-card>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import CommonDataSelectionGrid from "../../../components/grids/common-data-selection-grid";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import { convertList } from "../helpers/checks-converter";
import AmlDlg from "../../../components/popups/aml-dlg";

const AmlRepository = RepositoryFactory.get(RepositoryName.checks);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "WithdrawalsList",
  components: {
    AmlDlg,
    CommonDataSelectionGrid
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,

      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,
      sortBy: "",
      sortDesc: false,
      sorting: "ByDateAndTimeDesc",

      selectedDatesRange: {
        dateAndTimeStart: "",
        dateAndTimeEnd: ""
      },

      amlResultList: [],
      resultData: []
    };
  },
  async mounted() {
    await this.getAmlChecks();
  },
  computed: {
    fields() {
      return [
        {
          key: "dateAndTime",
          label: "Date & time",
          sortable: true,
          sortDirection: "asc",
          tdClass: "dateField"
        },
        {
          key: "ticketId",
          label: "Ticket ID",
          sortable: true
        },
        {
          key: "clientId",
          label: "ID code",
          sortable: true
        },
        {
          key: "client",
          label: "Client",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "riscScorePartOne",
          label: "Client risk score",
          sortable: true
        },
        {
          key: "amount",
          label: "Amount Deposited",
          sortable: true
        },
        {
          key: "currency",
          label: "Currency",
          sortable: false
        },
        {
          key: "reasonForTrigger",
          label: "Reason for trigger",
          sortable: true
        },
        {
          key: "checkStatus",
          label: "Check status",
          sortDirection: "desc"
        },
        {
          key: "editAmlStatus",
          label: "Edit status"
        }
      ];
    }
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getAmlChecks().catch(error => {
        console.error(error);
      });
    },
    async onSortClicked(value, sortDesc) {
      this.sortBy = value;
      this.sortDesc = sortDesc;

      await this.getAmlChecks().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getAmlChecks().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getAmlChecks().catch(error => {
        console.error(error);
      });
    },
    async onFilterClicked(value, textMask) {
      this.filtersModel = value;
      this.filter = textMask;

      if (!textMask) this.selectedDatesRange.dateAndTimeEnd = this.selectedDatesRange.dateAndTimeStart = "";

      await this.getAmlChecks().catch(error => {
        console.error(error);
      });
    },
    async onFilterValueChange() {
      await this.getAmlChecks().catch(error => {
        console.error(error);
      });
    },
    async getAmlChecks() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      switch (this.sortBy) {
        case "dateAndTime": {
          if (this.sortDesc) this.sorting = "ByDateAndTimeDesc";
          else this.sorting = "ByDateAndTimeAsc";
          break;
        }
        case "clientId": {
          if (this.sortDesc) this.sorting = "ByClientIdDesc";
          else this.sorting = "ByClientIdAsc";
          break;
        }
        case "email": {
          if (this.sortDesc) this.sorting = "ByEmailDesc";
          else this.sorting = "ByEmailAsc";
          break;
        }
        case "amount": {
          if (this.sortDesc) this.sorting = "ByAmountDesc";
          else this.sorting = "ByAmountAsc";
          break;
        }
      }

      if (this.selectedDatesRange.dateAndTimeEnd) {
        var dateEnd = new Date(this.selectedDatesRange.dateAndTimeEnd);
        dateEnd.setDate(dateEnd.getDate() + 1);
      }

      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.filter,
          dateFrom: this.selectedDatesRange.dateAndTimeStart
            ? new Date(this.selectedDatesRange.dateAndTimeStart)
            : null,
          dateTo: this.selectedDatesRange.dateAndTimeEnd ? dateEnd : null,
          sorting: this.sorting
        }
      };

      this.isLoading = true;
      let axiosResponse = await AmlRepository.getAmlChecks(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let amlResponse = axiosResponse.data;

      this.resultData = amlResponse.items;
      this.isLoading = false;

      this.amlResultList = convertList(this.resultData, true);
    }
  }
};
</script>
