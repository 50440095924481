// https://medium.com/canariasjs/vue-api-calls-in-a-smart-way-8d521812c322
import cookie from "js-cookie";

export const baseDomain = process.env.VUE_APP_ROOT_API;
export const baseUrl = `${baseDomain}/v1.0`;

export const addInterceptorXhrHeaders = function (axiosInstance) {
  axiosInstance.interceptors.request.use(function (config) {
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    config.headers["Content-Type"] = "application/json";
    config.headers["Authorization"] = "Bearer " + cookie.get("authToken") || "";
    config.headers["accept-language"] = "en";
    return config;
  });
};
