import { render, staticRenderFns } from "./edit-tournament-dlg.vue?vue&type=template&id=185a7c51&scoped=true"
import script from "./edit-tournament-dlg.vue?vue&type=script&lang=js"
export * from "./edit-tournament-dlg.vue?vue&type=script&lang=js"
import style0 from "./edit-tournament-dlg.vue?vue&type=style&index=0&id=185a7c51&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185a7c51",
  null
  
)

export default component.exports