<template>
  <div>
    <b-navbar type="dark" justified class="app-container bg-transparent">
      <b-navbar-nav>
        <b-img class="logo" :src="require('../../assets/settings.png')"></b-img>
        <b-nav-item>
          <router-link to="/home" exact>Admin</router-link>
        </b-nav-item>
        <b-nav-item-dropdown
          v-for="{ condition, text, children } in routes"
          v-show="condition"
          toggle-class="nav-link-custom"
          class="desktop-navbar"
          :key="text"
          :text="text"
          :lazy="true"
        >
          <b-dropdown-item v-for="{ linkName, condition, text } in children" :key="linkName" v-show="condition">
            <router-link class="dropdown-nav-link" :to="$router.resolve({ name: linkName }).href">
              {{ text }}
            </router-link>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <div class="logout-btns">
        <span class="email mr-3">{{ userName }}</span>
        <b-button size="lg" v-show="isLoggedIn" variant="dark" class="logout" @click="logout">Logout</b-button>
      </div>
    </b-navbar>
    <b-navbar type="dark" class="app-container mobile-navbar">
      <b-navbar-nav class="d-flex flex-wrap">
        <b-nav-item-dropdown
          v-for="{ condition, text, children } in routes"
          v-show="condition"
          toggle-class="nav-link-custom"
          :key="text"
          :text="text"
          :lazy="true"
        >
          <b-dropdown-item v-for="{ linkName, condition, text } in children" :key="linkName" v-show="condition">
            <router-link class="dropdown-nav-link" :to="$router.resolve({ name: linkName }).href">
              {{ text }}
            </router-link>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import cookie from "js-cookie";

import router, { routePaths } from "../../modules/route/routes";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "nav-menu",
  computed: {
    ...mapGetters(["userName"]),
    ...mapGetters({
      isLoggedIn: "userIsAuthenticated",
      isUsersRead: "hasUsersRead",
      isDuplicatesManagement: "hasDuplicatesManage",
      isChecksRead: "hasChecksRead",
      isTournamentsRead: "hasTournamentsRead",
      isBonusesRead: "hasBonusesRead",
      isAdmin: "hasAdmin",
      isReadLogs: "hasReadLogs",
      isTradingAccountRead: "hasTradingAccountsRead",
      isTransactionsRead: "hasTransactionsRead",
      isUsersManagement: "hasUserManage",
      isReportsRead: "hasReportsRead",
      isKycRead: "hasUsersKycRead"
    }),
    routes() {
      return [
        {
          text: "CRM",
          condition: this.isLoggedIn && this.isUsersRead,
          children: [
            { text: "Users", condition: this.isUsersRead, linkName: "Users" },
            {
              text: "Trading Accounts",
              condition: this.isTradingAccountRead,
              linkName: "Trading Accounts"
            },
            { text: "Transactions", condition: this.isTransactionsRead, linkName: "Transactions" }
          ]
        },
        {
          text: "Checks",
          condition: this.isLoggedIn && this.isChecksRead,
          children: [
            { text: "Deposits", condition: this.isChecksRead, linkName: "Deposits" },
            { text: "Withdrawals", condition: this.isChecksRead, linkName: "Withdrawals" },
            { text: "AML Checks", condition: this.isChecksRead, linkName: "AmlChecks" },
            { text: "Expired PoI", condition: this.isKycRead, linkName: "ExpiredPol" }
          ]
        },
        {
          condition: this.isLoggedIn && this.isBonusesRead,
          text: "Bonuses",
          children: [
            { text: "Bonuses", condition: this.isBonusesRead, linkName: "Bonuses" },
            { text: "User's bonuses", condition: this.isBonusesRead, linkName: "UsersBonuses" }
          ]
        },
        {
          condition: this.isLoggedIn && this.isTournamentsRead,
          text: "Tournaments",
          children: [
            { text: "Trading Tournaments", condition: this.isTournamentsRead, linkName: "Trading Tournaments" }
          ]
        },
        {
          condition: this.isLoggedIn && this.isReportsRead,
          text: "Reports",
          children: [{ text: "Reports", condition: this.isReportsRead, linkName: "Reports" }]
        },
        {
          condition: (this.isLoggedIn && this.isAdmin) || this.isUsersRead || this.isReadLogs,
          text: "Settings",
          children: [
            { text: "CRM admins", condition: this.isUsersRead, linkName: "Permissions" },
            { text: "Antifraud", condition: this.isDuplicatesManagement, linkName: "Duplicates" },
            { text: "Action logs", condition: this.isReadLogs, linkName: "Logs" },
            { text: "Email templates", condition: this.isAdmin, linkName: "Emails" }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions(["resetState"]),
    async logout() {
      cookie.remove("authToken");
      await this.resetState();
      await router.push(routePaths.authLogin);
    }
  }
};
</script>

<style scoped lang="scss">
.bg-transparent {
  background-color: transparent !important;
  color: #ffffff;
}

.mobile-navbar {
  @include media-breakpoint-tablet() {
    display: none;
  }
}

.desktop-navbar {
  display: none;
  @include media-breakpoint-tablet() {
    display: block;
  }
}

.dropdown-nav-link {
  transition: background-color 0.15s ease;
  text-decoration: none;
  color: black;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  width: 100%;

  &:not(.router-link-active):hover,
  &:not(.router-link-active):focus {
    color: white;
    background-color: $primary-color;
  }
}

.logo {
  width: 25px;
  height: 25px;
}

.email {
  font-weight: 600;
}

.logout-btns {
  margin-left: auto;
  display: flex;
  align-items: center;
}
</style>
