import Repository from "./repository-base";

const resource = "admin/auth";
const clientResource = "auth";

export default {
  login(params) {
    return Repository.post(`${resource}/signin`, params, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  signUp(params) {
    return Repository.post(`${resource}/signup`, params, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  confirmCode(params) {
    return Repository.post(`${resource}/code/confirm`, params);
  }
};
