<template>
  <a :href="fullSrc" target="_blank">
    <slot v-if="preview">
      <img style="width: 100px; height: 100px" class="m-3" :src="src" />
    </slot>
    <slot v-else>
      <img style="width: 64px; height: 64px" class="m-3" :src="src" />
      <p>{{ fileName }}</p>
    </slot>
  </a>
</template>

<script>
export default {
  name: "LoadImage",
  props: ["fileUrl", "preview", "fileName"],
  data() {
    return {
      src: null,
      fullSrc: null
    };
  },
  mounted: async function () {
    this.fullSrc = this.fileUrl;
    this.src = this.preview ? this.preview.url : require("../../assets/document.png");
  }
};
</script>
