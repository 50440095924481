<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">Users</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
          <b-col sm="auto" class="ml-auto" v-if="hasReportsRead">
            <download-excel :before-generate="getUsersReport" class="btn btn-light">Download</download-excel>
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="usersResultList"
      :fields-list="fields"
      :filters-list="sourceFiltersList"
      :filters-model="filtersModel"
      :multiple-filters-model="multipleFiltersModel"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :has-details="true"
      :is-filtered="true"
      :need-per-page="true"
      @load-clicked="onLoadClicked"
      @sort-clicked="onSortClicked"
      @pg-clicked="onPgClicked"
      @srch-clicked="onSrchClicked"
      @filter-clicked="onFilterClicked"
      @multiple-filter-clicked="onMultipleFilterClicked"
    >
    </common-data-selection-grid>
  </b-card>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import CommonDataSelectionGrid from "../../../components/grids/common-data-selection-grid";
import { convertUsersList } from "@/modules/sections/helpers/users-converter";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import downloadExcel from "vue-json-excel";
import { saveAs } from "file-saver";
import { mapGetters } from "vuex";
import qs from "qs";

const UsersRepository = RepositoryFactory.get(RepositoryName.users);
const ReportsRepository = RepositoryFactory.get(RepositoryName.reports);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "UsersList",
  components: {
    CommonDataSelectionGrid,
    downloadExcel
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,

      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,
      sourceFiltersList: [],
      sourceCountries: [],
      sortBy: "",
      sortDesc: false,
      sorting: null,

      filtersModel: [
        {
          key: "isEnabled",
          selected: ""
        },
        {
          key: "isInternal",
          selected: ""
        },
        {
          key: "isWithdrawEnabled",
          selected: ""
        },
        {
          key: "riskStatus",
          selected: ""
        },
        {
          key: "dateRangeStart",
          selected: ""
        },
        {
          key: "dateRangeEnd",
          selected: ""
        }
      ],
      multipleFiltersModel: {
        externalKycStatus: [],
        country: []
      },

      companyId: "",
      usersResultList: [],
      resultData: []
    };
  },
  mounted: async function () {
    await this.getUsers();
    await this.getFiltersList();
  },
  computed: {
    ...mapGetters(["hasReportsRead"]),
    fields() {
      return [
        {
          key: "email",
          label: "Email",
          sortable: true,
          sortDirection: "asc",
          tdClass: "table-cell-wider",
          filtered: false
        },
        {
          key: "isInternal",
          label: "Internal",
          sortDirection: "asc",
          filtered: true
        },
        {
          key: "dateRange",
          type: "date",
          label: "Registration Date",
          sortable: true,
          filtered: true,
          sortDirection: "desc",
          tdClass: "dateField"
        },
        {
          key: "lastAction",
          label: "Last Action Date",
          sortable: true,
          sortDirection: "desc",
          tdClass: "dateField",
          filtered: false
        },
        {
          key: "isEnabled",
          label: "Enabled",
          sortDirection: "asc",
          filtered: true
        },
        {
          key: "isWithdrawEnabled",
          label: "Withdrawal",
          sortDirection: "asc",
          filtered: true
        },
        {
          key: "externalKycStatus",
          label: "KYC Status",
          sortDirection: "asc",
          multipleFiltered: true
        },
        {
          key: "riskStatus",
          label: "Risk Status",
          sortable: true,
          sortDirection: "asc",
          filtered: true
        },
        {
          key: "name",
          label: "User Name",
          sortable: true,
          sortDirection: "asc",
          tdClass: "table-cell-wider",
          filtered: false
        },
        {
          key: "country",
          label: "Country",
          sortable: true,
          sortDirection: "asc",
          multipleFiltered: true
        }
      ];
    }
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getUsers().catch(error => {
        console.error(error);
      });
    },
    async onSortClicked(value, sortDesc) {
      this.sortBy = value;
      this.sortDesc = sortDesc;

      await this.getUsers().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getUsers().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getUsers().catch(error => {
        console.error(error);
      });
    },
    async onFilterClicked(value, textMask) {
      this.filtersModel = value;
      this.filter = textMask;

      await this.getUsers().catch(error => {
        console.error(error);
      });
    },
    async onMultipleFilterClicked(value, textMask) {
      this.multipleFiltersModel = value;
      this.filter = textMask;

      await this.getUsers().catch(error => {
        console.error(error);
      });
    },
    async getUsers() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      switch (this.sortBy) {
        case "dateRange": {
          if (this.sortDesc) this.sorting = "ByRegDateDesc";
          else this.sorting = "ByRegDateAsc";
          break;
        }
        case "lastAction": {
          if (this.sortDesc) this.sorting = "ByLastActionDateDesc";
          else this.sorting = "ByLastActionDateAsc";
          break;
        }
        case "country": {
          if (this.sortDesc) this.sorting = "ByCountryDesc";
          else this.sorting = "ByCountryAsc";
          break;
        }
        case "email": {
          if (this.sortDesc) this.sorting = "ByEmailDesc";
          else this.sorting = "ByEmailAsc";
          break;
        }
        case "name": {
          if (this.sortDesc) this.sorting = "ByNameDesc";
          else this.sorting = "ByNameAsc";
          break;
        }
        case "balanceRange": {
          if (this.sortDesc) this.sorting = "ByTotalBalanceDesc";
          else this.sorting = "ByTotalBalanceAsc";
          break;
        }
        case "riskStatus": {
          if (this.sortDesc) this.sorting = "ByRiskStatusDesc";
          else this.sorting = "ByRiskStatusAsc";
          break;
        }
      }

      let riskStatus = this.filtersModel.find(item => item.key === "riskStatus");
      let isEnabled = this.filtersModel.find(item => item.key === "isEnabled");
      let isInternal = this.filtersModel.find(item => item.key === "isInternal");
      let isWithdrawEnabled = this.filtersModel.find(item => item.key === "isWithdrawEnabled");

      let regDateStart = this.filtersModel.find(item => item.key === "dateRangeStart");
      let regDateEnd = this.filtersModel.find(item => item.key === "dateRangeEnd");

      if (regDateEnd && regDateEnd.selected) {
        var regDateEndResult = new Date(regDateEnd.selected);
        regDateEndResult.setDate(regDateEndResult.getDate() + 1);
      }

      this.multipleFiltersModel["externalKycStatus"] = this.$route.query.externalKycStatuses
        ? this.$route.query.externalKycStatuses.split(",")
        : this.multipleFiltersModel["externalKycStatus"];

      let countries = this.multipleFiltersModel["country"]
        ? this.sourceCountries
            .filter(x => this.multipleFiltersModel["country"].includes(x.name))
            .map(x => x.twoLetterCode)
        : [];

      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,

          mask: this.filter,
          sorting: this.sorting,

          isEnabled: isEnabled.selected !== "" ? isEnabled.selected === "Enabled" : null,
          isInternal: isInternal.selected !== "" ? isInternal.selected === "Enabled" : null,
          isWithdrawEnabled: isWithdrawEnabled.selected !== "" ? isWithdrawEnabled.selected === "Enabled" : null,
          externalKycStatuses: this.multipleFiltersModel["externalKycStatus"],
          riskStatus: riskStatus.selected,
          countries: countries,
          regDateStart: regDateStart.selected,
          regDateEnd: regDateEndResult
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      };

      this.isLoading = true;
      let axiosResponse = await UsersRepository.getUsers(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let usersResponse = axiosResponse.data;

      this.resultData = usersResponse.items;
      this.isLoading = false;

      this.usersResultList = convertUsersList(this.resultData);
      if (this.$route.query.externalKycStatuses) await this.$router.replace({ query: null });
    },
    async getUsersReport() {
      let isEnabled = this.filtersModel.find(item => item.key === "isEnabled");
      let isInternal = this.filtersModel.find(item => item.key === "isInternal");
      let isWithdrawEnabled = this.filtersModel.find(item => item.key === "isWithdrawEnabled");

      let regDateStart = this.filtersModel.find(item => item.key === "dateRangeStart");
      let regDateEnd = this.filtersModel.find(item => item.key === "dateRangeEnd");

      if (regDateEnd && regDateEnd.selected) {
        var regDateEndResult = new Date(regDateEnd.selected);
        regDateEndResult.setDate(regDateEndResult.getDate() + 1);
      }

      let countries = this.multipleFiltersModel["country"]
        ? this.sourceCountries
            .filter(x => this.multipleFiltersModel["country"].includes(x.name))
            .map(x => x.twoLetterCode)
        : [];

      let filter = {
        isEnabled: isEnabled.selected !== "" ? isEnabled.selected === "Enabled" : null,
        isInternal: isInternal.selected !== "" ? isInternal.selected === "Enabled" : null,
        isWithdrawalEnabled: isWithdrawEnabled.selected !== "" ? isWithdrawEnabled.selected === "Enabled" : null,
        kycStatuses: this.multipleFiltersModel["externalKycStatus"],
        countries: countries,
        regDateFrom: regDateStart.selected,
        regDateTo: regDateEndResult,
        mask: this.filter
      };

      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getUsers(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.isLoading = false;

      let currentDate = new Date();
      saveAs(axiosResponse.data, "UsersReport_" + currentDate.toLocaleString() + ".xlsx");
    },
    //#region Filters
    async getFiltersList() {
      this.isLoading = true;

      let axiosResponse = await UsersRepository.getUsersFilterInfo();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      let filterInfo = axiosResponse.data;
      this.isLoading = false;

      this.sourceFiltersList = [];

      this.sourceFiltersList = [];
      this.sourceFiltersList.push(await this.filters_getEnabled());
      this.sourceFiltersList.push(await this.filters_getInternal());
      this.sourceFiltersList.push(await this.filters_getWithdrawEnabled());
      if (filterInfo.kycStatuses) this.sourceFiltersList.push(await this.filters_getKycStatuses(filterInfo));
      if (filterInfo.countries) this.sourceFiltersList.push(await this.filters_getCountries(filterInfo));
      if (filterInfo.riskStatuses) this.sourceFiltersList.push(await this.filters_getRiskStatuses(filterInfo));

      this.isLoading = false;
    },
    async filters_getEnabled() {
      return {
        key: "isEnabled",
        items: ["Enabled", "Disabled"]
      };
    },
    async filters_getInternal() {
      return {
        key: "isInternal",
        items: ["Enabled", "Disabled"]
      };
    },
    async filters_getWithdrawEnabled() {
      return {
        key: "isWithdrawEnabled",
        items: ["Enabled", "Disabled"]
      };
    },
    async filters_getKycStatuses(filtersInfo) {
      return {
        key: "externalKycStatus",
        items: filtersInfo.kycStatuses
      };
    },
    async filters_getCountries(filtersInfo) {
      this.sourceCountries = filtersInfo.countries;
      return {
        key: "country",
        items: filtersInfo.countries.map(x => x.name)
      };
    },
    async filters_getRiskStatuses(filtersInfo) {
      return {
        key: "riskStatus",
        items: filtersInfo.riskStatuses
      };
    }
  }
};
</script>
