import moment from "moment";
import "moment-duration-format";

export function convertUsersList(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.id;
      container.number = item.number;
      container.name = item.name;
      container.email = item.email;
      container.isEmailConfirmed = item.isEmailConfirmed;
      container.country = item.country;
      container.company = item.company;

      container.dateRange = moment(String(item.regDate)).utc().format("DD.MM.YYYY HH:mm:ss");
      container.lastAction = item.lastAction ? moment(String(item.lastAction)).utc().format("DD.MM.YYYY HH:mm:ss") : "";
      container.lastKycRequestDate = moment(String(item.lastKycRequestDate)).utc().format("DD.MM.YYYY HH:mm:ss");

      container.isInternal = item.isInternal ? "Yes" : "No";
      container.isEnabled = item.isEnabled ? "Yes" : "No";
      container.is2FaEnabled = item.is2FaEnabled ? "Yes" : "No";
      container.isWithdrawEnabled = item.isWithdrawEnabled ? "Yes" : "No";

      container.isAdmin = item.isAdmin;
      container.isRootAdmin = item.isRootAdmin;
      container.externalKycStatus = item.externalKycStatus;
      container.agent = item.agent;
      container.wallets = item.wallets;
      container.permissions = item.permissions;
      container.riskStatus = item.riskStatus;

      container.details = [
        {
          title: "Id",
          description: item.id,
          files: []
        },
        {
          title: "ID code",
          description: item.number,
          files: []
        }
      ];

      return container;
    });
  }
}

export function convertUserDetails(sourceData) {
  const container = {};

  container.id = sourceData.id;
  container.number = sourceData.number;
  container.firstName = sourceData.firstName;
  container.lastName = sourceData.lastName;
  container.dateOfBirth = sourceData.dateOfBirth
    ? moment(String(sourceData.dateOfBirth)).utc().format("DD.MM.YYYY")
    : "";
  container.phone = sourceData.phone;
  container.email = sourceData.email;
  container.regDate = moment(String(sourceData.regDate)).utc().format("DD.MM.YYYY HH:mm:ss");
  container.country = sourceData.country;
  container.city = sourceData.city;
  container.street = sourceData.street;
  container.postalCode = sourceData.postalCode;
  container.nationality = sourceData.nationality;
  container.billingTarget = sourceData.billingTarget;
  container.tag = sourceData.tag;

  container.externalKycStatus = sourceData.externalKycStatus;
  container.riskStatus = sourceData.riskStatus ?? "-";

  container.lastAction = sourceData.lastAction
    ? moment(String(sourceData.lastAction)).utc().format("DD.MM.YYYY HH:mm:ss")
    : "";

  container.lockoutWithdrawalsEndDate = sourceData.lockoutWithdrawalsEndDate
    ? moment(String(sourceData.lockoutWithdrawalsEndDate)).utc().format("DD.MM.YYYY HH:mm:ss")
    : "-";

  container.isDeleted = sourceData.isDeleted ? "Yes" : "No";
  container.isEnabled = sourceData.isEnabled ? "Yes" : "No";
  container.isInternal = sourceData.isInternal ? "Yes" : "No";
  container.isEmailConfirmed = sourceData.isEmailConfirmed ? "Yes" : "No";
  container.isDepositEnabled = sourceData.isDepositEnabled ? "Yes" : "No";
  container.isWithdrawEnabled = sourceData.isWithdrawEnabled ? "Yes" : "No";
  container.is2FaEnabled = sourceData.is2FaEnabled ? "Yes" : "No";
  container.isAdmin = sourceData.isAdmin ? "Yes" : "No";
  container.isSurveyCompleted = sourceData.isSurveyCompleted ? "Yes" : "No";
  container.sumSubInfo = sourceData.sumSubInfo && sourceData.sumSubInfo.hasApplicant ? sourceData.sumSubInfo : null;
  container.permissions = sourceData.permissions;

  let utmInfo = sourceData.utmInfo;
  container.utmCampaign = utmInfo && utmInfo.utmCampaign ? utmInfo.utmCampaign : "-";
  container.clickId = utmInfo && utmInfo.clickId ? utmInfo.clickId : utmInfo;

  if (utmInfo) {
    container.url = utmInfo.url;
  }

  return container;
}

export function convertIps(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.ip = item.ip;
      container.countryCode = item.countryCode;
      container.firstDate = moment(String(item.firstDate)).utc().format("DD.MM.YYYY HH:mm:ss");
      container.lastDate = moment(String(item.lastDate)).utc().format("DD.MM.YYYY HH:mm:ss");
      return container;
    });
  }
}

export function convertKyc(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.externalKycStatus = item.externalKycStatus;
      container.clientStatus = item.clientStatus;
      container.details = item.documentsChecks
        ? item.documentsChecks
            .map(x => {
              let title = {
                title: x.typeTitle,
                statusTitle: "",
                reviewType: "",
                reviewTypeTitle: "",
                comment: "",
                needColor: true
              };

              let docs = x.documents.map(d => {
                let document = {
                  title: d.id + " (id)",
                  statusTitle: d.statusTitle,
                  reviewType: d.reviewRejectType,
                  reviewTypeTitle: d.reviewRejectTypeTitle,
                  comment: d.comment,
                  needColor: false
                };

                let labels = d.reviewRejectLabels.map(l => {
                  return {
                    title: "",
                    statusTitle: "",
                    reviewType: "",
                    reviewTypeTitle: "",
                    comment: l.labelTitle,
                    needColor: false
                  };
                });

                labels.unshift(document);
                labels.unshift(title);

                return labels;
              });

              return docs.flat();
            })
            .flat()
        : null;

      container.lastActionKycDate = moment(String(item.lastAction)).utc().format("DD.MM.YYYY HH:mm:ss");

      return container;
    });
  }
}
export function convertKycDocs(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.kycDocType = item.idDocTypeTitle;
      container.validUntil = item.validUntil ? moment(String(item.validUntil)).utc().format("DD.MM.YYYY") : "";
      container.isExpired = item.isExpired;

      container.userId = item.userId;
      container.userEmail = item.userEmail;

      return container;
    });
  }
}
export function convertKingFinLogs(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.id;
      container.eventName = item.eventName;
      container.externalId = item.externalId;
      container.createdAt = item.createdAt ? moment(String(item.createdAt)).utc().format("DD.MM.YYYY") : "";
      container.payload = item.payload;
      container.status = item.status;

      return container;
    });
  }
}
export function convertDuplicates(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.id;
      container.fieldId = item.fieldId;
      container.user = item.user;
      container.userOriginal = item.userOriginal;
      container.date = item.date ? moment(String(item.date)).utc().format("DD.MM.YYYY HH:mm:ss") : "";
      container.status = item.status;
      container.description = item.description;
      container.comment = item?.comments ? item?.comments.map(x => `${x.comment}`).join("\n") : "";
      container.admin = item.admin;

      return container;
    });
  }
}
export function convertComments(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.date = item.date ? moment(String(item.date)).utc().format("DD.MM.YYYY HH:mm:ss") : "";
      container.comment = item.comment;
      container.admin = item.admin;

      if (item.tradingAccount) {
        container.tradingAccountId = item.tradingAccount.id;
        container.login = item.tradingAccount.login;
      }
      return container;
    });
  }
}
export function convertAml(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.groupTitle = item.title;
      container.description = item.description;

      let details = [];
      item.fields.map(x => {
        if (x.value) {
          let titles = [];
          if (x.fields.length > 0) {
            x.fields.filter(x => x.pollValues.length > 0).forEach(f => titles.push(getPollTitles(f)));
          }

          let additionalFields = titles.length > 0 ? " (" + titles.join(", ") + ")" : "";
          details.push({
            title: x.title,
            description: x.value + additionalFields,
            files: []
          });
          return;
        }

        let values = getPollTitles(x);

        details.push({
          title: x.title,
          description: values?.join(", "),
          files: []
        });
      });

      container.details = details;
      return container;
    });
  }
}

function getPollTitles(field) {
  if (field.pollValues.length > 0) {
    let values = [];
    field.pollValues.forEach(p => {
      let pollField = field.pollFields.find(field => field.id === p);

      let description =
        pollField.fields.length > 0 ? pollField.title + ": " + pollField.fields[0].value : pollField.title;
      values.push(description);
    });
    return values;
  }
}
