<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">Trading Accounts</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
          <b-col sm="auto" class="ml-auto" v-if="hasReportsRead">
            <download-excel :before-generate="getTradingAccountsReport" class="btn btn-light"> Download</download-excel>
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="accountsResultList"
      :fields-list="fields"
      :filters-list="sourceFiltersList"
      :filters-model="filtersModel"
      :multiple-filters-model="multipleFiltersModel"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :is-filtered="true"
      :need-per-page="true"
      @load-clicked="onLoadClicked"
      @pg-clicked="onPgClicked"
      @srch-clicked="onSrchClicked"
      @filter-clicked="onFilterClicked"
      @multiple-filter-clicked="onMultipleFilterClicked"
    >
    </common-data-selection-grid>
  </b-card>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import CommonDataSelectionGrid from "../../../components/grids/common-data-selection-grid";
import { convertAccountsList } from "../helpers/accounts-converter";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import downloadExcel from "vue-json-excel";
import { saveAs } from "file-saver";
import { mapGetters } from "vuex";
import qs from "qs";

const TradingAccountsRepository = RepositoryFactory.get(RepositoryName.tradingAccounts);
const ReportsRepository = RepositoryFactory.get(RepositoryName.reports);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "TradingAccountsList",

  components: {
    CommonDataSelectionGrid,
    downloadExcel
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,

      isLoading: false,
      accountsResultList: [],
      resultData: [],

      currentPage: 0,
      perPage: 20,
      totalRows: 1,
      searchMask: null,
      agentsList: [],

      filtersModel: [
        {
          key: "typeTitle",
          selected: ""
        },
        {
          key: "platformTitle",
          selected: ""
        },
        {
          key: "serverAccount",
          selected: ""
        },
        {
          key: "server",
          selected: ""
        },
        {
          key: "currency",
          selected: ""
        },
        {
          key: "dateRangeStart",
          selected: ""
        },
        {
          key: "dateRangeEnd",
          selected: ""
        },
        {
          key: "isInternal",
          selected: ""
        }
      ],

      multipleFiltersModel: {
        status: []
      },

      sourceFiltersList: []
    };
  },
  mounted: async function () {
    await this.getTradingAccounts();
    await this.getFiltersList();
  },
  computed: {
    ...mapGetters(["hasReportsRead"]),
    fields() {
      return [
        {
          key: "login",
          label: "Login",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "user.email",
          label: "User",
          sortDirection: "asc",
          tdClass: "fieldsClass table-cell-wider",
          filtered: false
        },
        {
          key: "isInternal",
          label: "Internal",
          sortDirection: "asc",
          filtered: true
        },
        {
          key: "status",
          label: "Status",
          multipleFiltered: true
        },
        {
          key: "balance",
          label: "Balance",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "currency",
          label: "Currency",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "leverage",
          label: "Leverage",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: false
        },
        {
          key: "typeTitle",
          label: "Type",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "dateRange",
          label: "Date Create",
          sortDirection: "asc",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "platformTitle",
          label: "Platform",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "serverAccount",
          label: "Account",
          tdClass: "fieldsClass",
          filtered: true
        },
        {
          key: "server",
          label: "Server",
          tdClass: "fieldsClass",
          filtered: true
        }
      ];
    }
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getTradingAccounts().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getTradingAccounts().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(value) {
      this.searchMask = value;
      await this.getTradingAccounts().catch(error => {
        console.error(error);
      });
    },
    async onFilterClicked(value, textMask) {
      this.filtersModel = value;
      this.searchMask = textMask;

      await this.getTradingAccounts().catch(error => {
        console.error(error);
      });
    },
    async onMultipleFilterClicked(value, textMask) {
      this.multipleFiltersModel = value;
      this.filter = textMask;

      await this.getTradingAccounts().catch(error => {
        console.error(error);
      });
    },
    async getTradingAccounts() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      let serverType = this.filtersModel.find(item => item.key === "typeTitle");
      let platform = this.filtersModel.find(item => item.key === "platformTitle");
      let serverAccount = this.filtersModel.find(item => item.key === "serverAccount");
      let server = this.filtersModel.find(item => item.key === "server");
      let currency = this.filtersModel.find(item => item.key === "currency");
      let isInternal = this.filtersModel.find(item => item.key === "isInternal");

      let openDateStart = this.filtersModel.find(item => item.key === "dateRangeStart");
      let openDateEnd = this.filtersModel.find(item => item.key === "dateRangeEnd");

      if (openDateEnd.selected) {
        var openDateEndResult = new Date(openDateEnd.selected);
        openDateEndResult.setDate(openDateEndResult.getDate() + 1);
      }

      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.searchMask,

          serverType: serverType.selected,
          platform: platform.selected,
          serverAccount: serverAccount.selected,
          server: server.selected,
          statuses: this.multipleFiltersModel["status"],
          currency: currency.selected,
          dateStart: openDateStart.selected,
          dateEnd: openDateEndResult,
          isInternal: isInternal.selected !== "" ? isInternal.selected === "Enabled" : null
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      };

      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getAccounts(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let accountsResponse = axiosResponse.data;

      this.resultData = accountsResponse.items;
      this.isLoading = false;
      this.accountsResultList = convertAccountsList(this.resultData);
    },
    async getTradingAccountsReport() {
      let serverType = this.filtersModel.find(item => item.key === "typeTitle");
      let platform = this.filtersModel.find(item => item.key === "platformTitle");
      let serverAccount = this.filtersModel.find(item => item.key === "serverAccount");
      let server = this.filtersModel.find(item => item.key === "server");
      let currency = this.filtersModel.find(item => item.key === "currency");

      let openDateStart = this.filtersModel.find(item => item.key === "dateRangeStart");
      let openDateEnd = this.filtersModel.find(item => item.key === "dateRangeEnd");

      if (openDateEnd.selected) {
        var openDateEndResult = new Date(openDateEnd.selected);
        openDateEndResult.setDate(openDateEndResult.getDate() + 1);
      }

      let filter = {
        serverType: serverType.selected,
        platforms: platform.selected !== "" ? [platform.selected] : [],
        serverAccounts: serverAccount.selected !== "" ? [serverAccount.selected] : [],
        server: server.selected,
        statuses: this.multipleFiltersModel["status"],
        currencies: currency.selected !== "" ? [currency.selected] : [],
        dateCreateFrom: openDateStart.selected,
        dateCreateTo: openDateEndResult,
        mask: this.filter
      };

      this.isLoading = true;
      let axiosResponse = await ReportsRepository.getTradingAccounts(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.isLoading = false;

      let currentDate = new Date();
      saveAs(axiosResponse.data, "TradingAccountsReport_" + currentDate.toLocaleString() + ".xlsx");
    },
    //#region Filters
    async getFiltersList() {
      this.isLoading = true;
      let axiosResponse = await TradingAccountsRepository.getAccountsFilterInfo();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      if (!axiosResponse.data) return;

      let filterInfo = axiosResponse.data;
      this.isLoading = false;

      this.sourceFiltersList = [];
      this.sourceFiltersList.push(await this.filters_getServerTypes(filterInfo));
      this.sourceFiltersList.push(await this.filters_getServerAccounts(filterInfo));
      this.sourceFiltersList.push(await this.filters_getPlatforms(filterInfo));
      this.sourceFiltersList.push(await this.filters_getServers(filterInfo));
      this.sourceFiltersList.push(await this.filters_getStatuses());
      this.sourceFiltersList.push(await this.filters_getCurrencies(filterInfo));
      this.sourceFiltersList.push(await this.filters_getInternal());
    },
    async filters_getServerTypes(filtersInfo) {
      if (filtersInfo.serverTypes) {
        return {
          key: "typeTitle",
          items: filtersInfo.serverTypes
        };
      }
    },
    async filters_getServerAccounts(filtersInfo) {
      if (filtersInfo.serverAccounts) {
        return {
          key: "serverAccount",
          items: filtersInfo.serverAccounts
        };
      }
    },
    async filters_getPlatforms(filtersInfo) {
      if (filtersInfo.platforms) {
        return {
          key: "platformTitle",
          items: filtersInfo.platforms
        };
      }
    },
    async filters_getServers(filtersInfo) {
      if (filtersInfo.servers) {
        return {
          key: "server",
          items: filtersInfo.servers
        };
      }
    },
    async filters_getStatuses() {
      return {
        key: "status",
        items: ["Active", "Archived", "Deleted"]
      };
    },
    async filters_getCurrencies(filtersInfo) {
      if (filtersInfo.currencies) {
        return {
          key: "currency",
          items: filtersInfo.currencies
        };
      }
    },
    async filters_getInternal() {
      return {
        key: "isInternal",
        items: ["Enabled", "Disabled"]
      };
    }
    //#endregion
  }
};
</script>
