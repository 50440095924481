import Repository from "./repository-base";

const resource = "admin/bonuses";

export default {
  getBonuses(filter) {
    return Repository.get(`${resource}`, filter);
  },
  getUserBonuses(filter) {
    return Repository.get(`${resource}/users`, filter);
  },
  getUserBonusTargets(id, filter) {
    return Repository.get(`${resource}/user/${id}/targets`, filter);
  },
  getUserBonusLogs(id, bonusTitle, filter) {
    return Repository.get(`${resource}/user/${id}/${bonusTitle}/logs`, filter);
  },
  getBonusDetails(id) {
    return Repository.get(`${resource}/${id}`);
  },
  getUserBonusDetails(id) {
    return Repository.get(`${resource}/user/${id}`);
  },
  getCreateBonusesInfo() {
    return Repository.get(`${resource}/create/info`);
  },
  createBonus(params) {
    return Repository.post(`${resource}/create`, params);
  },
  editBonus(params) {
    return Repository.post(`${resource}/edit`, params);
  },
  editUserBonus(id, params) {
    return Repository.post(`${resource}/user/${id}/edit`, params);
  },
  cancelUserBonus(userBonusId, userBonusTargetId) {
    return Repository.post(`${resource}/user/${userBonusId}/cancel/${userBonusTargetId}`);
  }
};
