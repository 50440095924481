import moment from "moment";
import "moment-duration-format";

export function convertTournamentsList(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.id;
      container.tournamentTitle = item.title;
      container.isActive = item.isActive ? "Yes" : "No";
      container.type = item.type;
      container.leadersCount = item.leadersCount;

      container.dateCreate = moment(String(item.dateCreate)).utc().format("DD.MM.YYYY HH:mm:ss");
      container.dateStart = item.dateStart ? moment(String(item.dateStart)).utc().format("DD.MM.YYYY HH:mm:ss") : null;
      container.dateEnd = item.dateEnd ? moment(String(item.dateEnd)).utc().format("DD.MM.YYYY HH:mm:ss") : null;

      container.url = item.url;
      container.signUpUrl = item.signUpUrl;
      container.details = item.details;
      return container;
    });
  }
}
