import { render, staticRenderFns } from "./trading-account-details.vue?vue&type=template&id=7db41956"
import script from "./trading-account-details.vue?vue&type=script&lang=js"
export * from "./trading-account-details.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports