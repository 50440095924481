<template>
  <transition name="fade">
    <div class="popup-modal" v-if="isVisible">
      <div class="window">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "PopupModal",

  data: () => ({
    isVisible: false
  }),

  methods: {
    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  z-index: 10;
  align-items: flex-start;

  @include media-breakpoint-tablet() {
    align-items: center;
  }
}

.window {
  background: #fff;
  border-radius: 5px;
  overflow: auto;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 100rem;
  max-height: 50rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}
</style>
