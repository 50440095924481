import moment from "moment";
import "moment-duration-format";

export function convertBonusesList(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.bonusId = item.id;

      container.bonusTitle = item.title;
      container.dateFrom = item.dateFrom ? moment(String(item.dateFrom)).utc().format("DD.MM.YYYY HH:mm:ss") : null;
      container.dateTo = item.dateTo ? moment(String(item.dateTo)).utc().format("DD.MM.YYYY HH:mm:ss") : null;

      container.isEnabled = item.isEnabled ? "Yes" : "No";
      container.isHided = item.isHided ? "Yes" : "No";
      container.isMultiApplicability = item.isMultiApplicability ? "Yes" : "No";
      container.isRepeatedly = item.isRepeatedly ? "Yes" : "No";

      container.maxAmount = item.amount;
      container.currency = item.currency;
      container.accrualMode = item.accrualMode;

      container.details = item.amountSet.map(x => {
        return {
          amount: x.amount + (x.type === "Percent" ? " %" : ""),
          limitMax: x.limitMax
        };
      });

      return container;
    });
  }
}

export function convertUsersBonusesList(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.id;
      container.bonusId = item.bonus.id;
      container.userBonusTitle = item.bonus.title;

      if (item.tradingAccount) {
        container.tradingAccountId = item.tradingAccount.id;
        container.login = item.tradingAccount.login;
      }

      container.user = item.user;

      container.dateStart = item.dateStart ? moment(String(item.dateStart)).utc().format("DD.MM.YYYY HH:mm:ss") : "";
      container.dateExpiration = item.dateExpiration
        ? moment(String(item.dateExpiration)).utc().format("DD.MM.YYYY HH:mm:ss")
        : "";

      container.currency = item.currency;
      container.status = item.status;

      container.accrualMode = item.accrualMode;
      container.details = item.targets.map(x => {
        return {
          login: x.tradingAccount.login,
          tradingAccountId: x.tradingAccount.id,
          amountAccrued: x.amountAccrued,
          amountAccruedTotal: x.amountAccruedTotal,
          amountPending: x.amountPending,
          amountReleased: x.amountReleased,
          currency: x.currency
        };
      });

      return container;
    });
  }
}

export function convertTargets(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.id;

      container.dateLastAction = item.dateLastAction
        ? moment(String(item.dateLastAction)).utc().format("DD.MM.YYYY HH:mm:ss")
        : null;
      container.dateLastPartialAccrual = item.dateLastPartialAccrual
        ? moment(String(item.dateLastPartialAccrual)).utc().format("DD.MM.YYYY HH:mm:ss")
        : null;
      container.dateLastPartialCancellation = item.dateLastPartialCancellation
        ? moment(String(item.dateLastPartialCancellation)).utc().format("DD.MM.YYYY HH:mm:ss")
        : null;

      container.isActive = item.isActive ? "Yes" : "No";
      container.isCancelling = item.isCancelling ? "Yes" : "No";

      container.amountAccrued = item.amountAccrued;
      container.amountAccruedTotal = item.amountAccruedTotal;
      container.amountPending = item.amountPending;
      container.amountReleased = item.amountReleased;

      container.progress = item.progress;
      container.currency = item.currency;

      if (item.tradingAccount) {
        container.tradingAccountId = item.tradingAccount.id;
        container.login = item.tradingAccount ? item.tradingAccount.login : "-";
      }

      return container;
    });
  }
}

export function convertBonusDetails(sourceData) {
  const container = {};

  container.id = sourceData.id ?? "-";
  container.title = sourceData.title ?? "-";
  container.descriptionShort = sourceData.descriptionShort ?? "-";
  container.currency = sourceData.currency;

  container.dateFrom = sourceData.dateFrom
    ? moment(String(sourceData.dateFrom)).utc().format("DD.MM.YYYY HH:mm:ss")
    : "-";
  container.dateTo = sourceData.dateTo ? moment(String(sourceData.dateTo)).utc().format("DD.MM.YYYY HH:mm:ss") : "-";

  container.isEnabled = sourceData.isEnabled ? "Enabled" : "Disabled";
  container.isHided = sourceData.isHided ? "Hided" : "Showed";
  container.isMultiApplicability = sourceData.isMultiApplicability ? "Yes" : "No";
  container.isRepeatedly = sourceData.isRepeatedly ? "Yes" : "No";

  container.expirationInDays = sourceData.expirationInDays ?? "-";
  container.maxCountPerUser = sourceData.maxCountPerUser ?? "-";

  container.startTrigger = sourceData.startTriggerDescription ?? "-";
  container.applicability = sourceData.applicabilityDescription ?? "-";
  container.accrualMode = sourceData.accrualMode ?? "-";

  container.amountSet =
    sourceData.amountSet.length > 0
      ? sourceData.amountSet
          .map(item => {
            return (
              item.amount + (item.type === "Percent" ? "%" : "") + (item.limitMax ? ", max limit " + item.limitMax : "")
            );
          })
          .join(";\r\n")
      : "-";

  container.startTriggerOptions = sourceData.startTriggerOptions
    ? "Min amount: " + (sourceData.startTriggerOptions.minAmount ?? "-")
    : "-";

  container.partialExecutionRule = sourceData.partialExecutionRule
    ? "Interval accrual in hours: " +
      sourceData.partialExecutionRule.intervalAccrualInHours +
      ", min amount: " +
      sourceData.partialExecutionRule.minAmount
    : "-";

  container.partialCancellationRule = sourceData.partialCancellationRule
    ? "Type: " +
      sourceData.partialCancellationRule.type +
      (sourceData.partialCancellationRule.amount ? ", amount: " + sourceData.partialCancellationRule.amount : "")
    : "-";

  container.cancellationConditions =
    sourceData.cancellationConditions.length > 0
      ? sourceData.cancellationConditions
          .map(item => {
            return item.description;
          })
          .join(",\r\n")
      : "-";

  container.executionConditions =
    sourceData.executionConditions.length > 0
      ? sourceData.executionConditions
          .map(item => {
            return (item.amount ? item.amount + " " : "") + item.description;
          })
          .join(",\r\n")
      : "-";

  container.allowedTradingServerAccounts =
    sourceData.allowedTradingServerAccounts.length > 0
      ? sourceData.allowedTradingServerAccounts
          .map(item => {
            return item.serverTitle + " " + item.title + " " + item.type;
          })
          .join(",\r\n")
      : "-";

  container.allowedTradingServerSymbols =
    sourceData.allowedTradingServerSymbols.length > 0
      ? sourceData.allowedTradingServerSymbols
          .map(item => {
            return item.symbol + "; " + item.path;
          })
          .join(",\r\n")
      : "-";

  container.allowedTradingServerSymbolGroups =
    sourceData.allowedTradingServerSymbolGroups.length > 0
      ? sourceData.allowedTradingServerSymbolGroups
          .map(item => {
            return item.group;
          })
          .join(",\r\n")
      : "-";

  return container;
}

export function convertUserBonusDetails(sourceData) {
  const container = {};

  container.id = sourceData.id ?? "-";
  if (sourceData.bonus) {
    container.bonus = sourceData.bonus;
  }

  container.user = sourceData.user;
  container.status = sourceData.status;
  container.accrualMode = sourceData.accrualMode ?? "-";
  container.applicability = sourceData.applicability ?? "-";

  container.isActive = sourceData.isActive ? "Yes" : "No";
  container.isMultiApplicability = sourceData.isMultiApplicability ? "Yes" : "No";
  container.isRepeatedly = sourceData.isRepeatedly ? "Yes" : "No";
  container.isFreezed = sourceData.isFreezed ? "Yes" : "No";

  container.amountLeft = sourceData.amountLeft ?? "-";
  container.amountUsed = sourceData.amountUsed ?? "-";
  container.currency = sourceData.currency;

  container.dateStart = sourceData.dateStart
    ? moment(String(sourceData.dateStart)).utc().format("YYYY-MM-DD HH:mm:ss")
    : "";

  let dateExpirationDate = sourceData.dateExpiration
    ? moment(String(sourceData.dateExpiration)).utc().format("YYYY-MM-DD")
    : "";

  let dateExpirationTime = sourceData.dateExpiration
    ? moment(String(sourceData.dateExpiration)).utc().format("HH:mm:ss")
    : "";

  container.dateExpiration = {
    date: dateExpirationDate,
    time: dateExpirationTime
  };

  container.amountSet =
    sourceData.amountSet.length > 0
      ? sourceData.amountSet
          .map(item => {
            return (
              item.amount + (item.type === "Percent" ? "%" : "") + (item.limitMax ? ", max limit " + item.limitMax : "")
            );
          })
          .join(";\r\n")
      : "-";

  return container;
}
