<template>
  <popup-modal ref="popup">
    <h2 class="title">Withdraw company funds</h2>

    <b-form>
      <b-form-input v-model="blockchain" class="mb-3" readonly></b-form-input>
      <b-form-input v-model="currency" class="mb-3" readonly></b-form-input>
      <b-form-input v-model="balance" class="mb-3" readonly></b-form-input>
      <b-form-input
        v-model="address"
        class="mb-3"
        :state="address.length > 0"
        placeholder="Enter withdraw address"
      ></b-form-input>
      <b-form-input
        v-model="amount"
        class="mb-3"
        :state="/^[0-9]*\.?[0-9]*$/.test(amount) && amount <= balance && amount > 0"
        placeholder="Enter withdraw amount"
      ></b-form-input>
    </b-form>

    <div class="btns mt-1">
      <b-button
        :disabled="!/^[0-9]*\.?[0-9]*$/.test(amount) || amount > balance || amount <= 0 || address.length <= 0"
        variant="primary"
        @click="_withdraw"
        >Withdraw</b-button
      >
      <b-button variant="dark" @click="_cancel">Close</b-button>
    </div>
    <confirm-dialogue :show-confirm="true" ref="confirmDialogue"></confirm-dialogue>
    <confirm-2fa-dlg :show-confirm="true" ref="confirm2FaDialogue"></confirm-2fa-dlg>
  </popup-modal>
</template>

<script>
import PopupModal from "./popup-modal.vue";
import Confirm2faDlg from "../../components/popups/confirm-2fa-dlg";
import ConfirmDialogue from "../../components/popups/confirm-dlg";
import { mapGetters } from "vuex";

export default {
  name: "WithdrawFundsDlg",
  components: {
    PopupModal,
    ConfirmDialogue,
    Confirm2faDlg
  },
  computed: {
    ...mapGetters(["userProfile"])
  },
  data: () => ({
    blockchain: "",
    currency: "",
    address: "",
    balance: null,

    amount: null,
    twoFaCode: "",
    recoveryCode: "",

    is2FaEnabled: false,
    userId: null,

    findInfo: null,

    resultItems: {
      isOk: false,
      amount: 0,
      address: "",
      twoFaCode: "",
      recoveryCode: ""
    },
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined
  }),
  methods: {
    show(opts = {}) {
      this.blockchain = opts.blockchain;
      this.currency = opts.currency;
      this.balance = opts.balance;

      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    async _withdraw() {
      let profile = this.userProfile;

      if (profile.is2FaEnabled) {
        const dlgResult = await this.$refs.confirm2FaDialogue.show();

        if (dlgResult.isOk) {
          this.resultItems = {
            isOk: true,
            amount: this.amount,
            address: this.address,
            twoFaCode: dlgResult.twoFaCode,
            recoveryCode: dlgResult.recoveryCode
          };
        }
      } else {
        this.resultItems = {
          isOk: true,
          amount: this.amount,
          address: this.address
        };
      }

      this.resolvePromise(this.resultItems);
      this.$refs.popup.close();
    },

    _cancel() {
      this.blockchain = "";
      this.currency = "";
      this.balance = null;

      this.amount = null;
      this.address = "";
      this.twoFaCode = "";
      this.recoveryCode = "";

      this.$refs.popup.close();
      this.resolvePromise(false);
    }
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.title {
  margin-bottom: 2rem;
  font-weight: bold;
  font-family: sans-serif;
}
</style>
