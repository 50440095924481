import Repository from "./repository-base";

const resource = "admin/users";

export default {
  getUsers(filter) {
    return Repository.get(`${resource}`, filter);
  },
  getUserDetails(id) {
    return Repository.get(`${resource}/${id}`);
  },
  getUserLogs(id, filter) {
    return Repository.get(`${resource}/${id}/logs`, filter);
  },
  getUserIps(id, filter) {
    return Repository.get(`${resource}/${id}/ips`, filter);
  },
  getUserSumSubHistory(id, filter) {
    return Repository.get(`${resource}/${id}/kyc/sumsub/history`, filter);
  },
  getUserSumSubInfo(id) {
    return Repository.get(`${resource}/${id}/kyc/sumsub`);
  },
  getUserSurveyInfo(id, filter) {
    return Repository.get(`${resource}/${id}/kyc/survey`, filter);
  },
  getUserSumSubDocs(filter) {
    return Repository.get(`${resource}/kyc/sumsub/docs`, filter);
  },
  getUserRiskInfo(id) {
    return Repository.get(`${resource}/${id}/kyc/scoring`);
  },
  getUserKingFinLogs(id, filter) {
    return Repository.get(`${resource}/${id}/kingfin/events`, filter);
  },
  getUserDuplicates(filter) {
    return Repository.get(`${resource}/duplicates`, filter);
  },
  getComments(id, filter) {
    return Repository.get(`${resource}/${id}/comments`, filter);
  },
  updateProfile(id, request) {
    return Repository.post(`${resource}/${id}/profile/update`, request);
  },
  updateTarget(id, request) {
    return Repository.post(`${resource}/${id}/target/update`, request);
  },
  updateUserIsEnabled(id, isEnabled) {
    return Repository.post(`${resource}/${id}/permissions/enable/${isEnabled}`);
  },
  updateUserIsInternal(id, isInternal) {
    return Repository.post(`${resource}/${id}/permissions/internal/${isInternal}`);
  },
  updateUserWithdrawIsEnabled(id, isEnabled) {
    return Repository.post(`${resource}/${id}/permissions/withdraw/${isEnabled}`);
  },
  confirmEmail(id) {
    return Repository.post(`${resource}/${id}/email/confirm`);
  },
  disable2Fa(id) {
    return Repository.post(`${resource}/${id}/2fa/disable`);
  },
  deleteUser(id) {
    return Repository.post(`${resource}/${id}/delete`);
  },
  resetLock(id) {
    return Repository.post(`${resource}/${id}/lock/reset`);
  },
  reloadCache(id) {
    return Repository.post(`${resource}/${id}/cache/reload`);
  },
  getUsersFilterInfo() {
    return Repository.get(`${resource}/filters/info`);
  },
  resetKycStatus(id) {
    return Repository.post(`${resource}/${id}/kyc/sumsub/reset`);
  },
  attachToPartnership(id, request) {
    return Repository.post(`${resource}/${id}/partnership/attach`, request);
  },
  cancelDuplicate(id, request) {
    return Repository.post(`${resource}/${id}/duplicates/cancel`, request);
  },
  addComment(id, request) {
    return Repository.post(`${resource}/${id}/comments`, request);
  }
};
