<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">Email templates</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="emailsResultList"
      :fields-list="fields"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :has-details="false"
      :need-per-page="true"
      @load-clicked="onLoadClicked"
      @pg-clicked="onPgClicked"
      @srch-clicked="onSrchClicked"
      @edit-clicked="onEditClicked"
      @add-clicked="onAddClicked"
      @filter-clicked="onFilterClicked"
    >
    </common-data-selection-grid>
    <edit-emails-dlg ref="editEmailsDlg"></edit-emails-dlg>
  </b-card>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";

import CommonDataSelectionGrid from "../../../../components/grids/common-data-selection-grid";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";
import EditEmailsDlg from "../../../../components/popups/edit-email-dlg";

const ManageRepository = RepositoryFactory.get(RepositoryName.manage);
const PlatformRepository = RepositoryFactory.get(RepositoryName.platform, "v1.1");

const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "EmailsList",
  components: {
    CommonDataSelectionGrid,
    EditEmailsDlg
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,
      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,

      emailsResultList: [],
      resultData: []
    };
  },
  mounted: async function () {
    await this.getEmails();
  },
  computed: {
    fields() {
      return [
        {
          key: "description",
          label: "Type",
          sortDirection: "desc",
          tdClass: "templatesFieldsClass"
        },
        {
          key: "items.locale",
          label: "Locale",
          sortDirection: "desc",
          tdClass: "localeClass"
        },
        {
          key: "addValue",
          label: "Actions",
          tdClass: "actionsClass"
        }
      ];
    }
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getEmails().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getEmails().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getEmails().catch(error => {
        console.error(error);
      });
    },
    async onFilterClicked(value, textMask) {
      this.filter = textMask;

      await this.getEmails().catch(error => {
        console.error(error);
      });
    },
    async onEditClicked(rowValue, sourceItems) {
      await this.updateEmailTemplate(rowValue, sourceItems).catch(error => {
        console.error(error);
      });
    },
    async onAddClicked(rowValue, sourceItems) {
      await this.updateEmailTemplate(rowValue, sourceItems).catch(error => {
        console.error(error);
      });
    },
    async getEmails() {
      await this.getRequestResult();
    },
    async getLocales() {
      this.isLoading = true;

      let axiosResponse = await PlatformRepository.getLocales();

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      return axiosResponse.data.items;
    },
    async updateEmailTemplate(rowValue, sourceItems) {
      let locales = await this.getLocales();

      const dlgResult = await this.$refs.editEmailsDlg.show({
        rowValue: rowValue,
        sourceLocales: locales.map(x => x.code),
        sourceItems: sourceItems
      });

      if (dlgResult) {
        let request = {
          type: dlgResult.type,
          locale: dlgResult.locale,
          subject: dlgResult.newSubject,
          body: dlgResult.newMessage
        };

        let axiosResponse = await ManageRepository.updateEmails(request);
        if (axiosResponse.status !== 200) {
          if (axiosResponse.status === 403) {
            await checkUserPermissions();
          }

          this.flashMessage.error({
            message: axiosResponse.data.errors
              .map(function (obj) {
                return obj.message;
              })
              .join("\n"),
            blockClass: "msg-block"
          });

          this.isLoading = false;
          return;
        } else {
          this.isLoading = false;
          this.flashMessage.success({
            message: "Email template updated",
            blockClass: "msg-block"
          });
        }
        await this.getEmails();
      }
      this.isLoading = false;
    },
    async getRequestResult() {
      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.filter
        }
      };

      this.isLoading = true;
      let axiosResponse = await ManageRepository.getEmailTemplates(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let emailsResponse = axiosResponse.data;

      this.resultData = emailsResponse.items;
      this.isLoading = false;

      this.emailsResultList = this.resultData;
      this.emailsResultList = this.emailsResultList.map(item => {
        return {
          description: item.description,
          type: item.type,
          tags: item.tags,
          items: item.items.map(value => {
            return {
              type: item.type,
              tags: item.tags,
              locale: value.locale,
              template: value
            };
          })
        };
      });
    }
  }
};
</script>
