import Repository from "./repository-base";

const resource = "admin/tournaments";

export default {
  getTournaments(filter) {
    return Repository.get(`${resource}`, filter);
  },
  updateTournamentActiveState(id, isActive) {
    return Repository.post(`${resource}/${id}/active/${isActive}`);
  },
  attachToTournament(id, request) {
    return Repository.post(`${resource}/${id}/attach`, request);
  },
  detachFromTournament(id, request) {
    return Repository.post(`${resource}/${id}/detach`, request);
  },
  updateTournament(id, request) {
    return Repository.post(`${resource}/${id}/update`, request);
  },
  createTournament(request) {
    return Repository.post(`${resource}/create`, request);
  }
};
