<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-overlay :show="isLoading" rounded="sm">
          <b-row class="mb-4">
            <b-col sm="auto">
              <h4>
                <span>{{ formMode === "Edit" ? "Edit bonus" : "Create bonus" }}</span>
              </h4>
            </b-col>
          </b-row>
          <b-form @submit.prevent="submitForm">
            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b>Title <span style="color: red">*</span></b>
                </label>
              </b-col>
              <b-col sm="7">
                <b-form-input v-model="form.title"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label><b>Description</b></label>
              </b-col>
              <b-col sm="7">
                <b-form-input :maxlength="100" v-model.trim="form.descriptionShort"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label><b>Date from</b></label>
              </b-col>
              <b-col sm="7">
                <b-input-group>
                  <b-form-datepicker class="w-50" v-model="form.dateFrom.date" />
                  <b-form-timepicker v-model="form.dateFrom.time" />
                  <b-input-group-prepend>
                    <b-button
                      type="button"
                      @click="
                        form.dateFrom.date = null;
                        form.dateFrom.time = null;
                      "
                      variant="light"
                    >
                      <b-icon icon="x-square" variant="dark"></b-icon>
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label><b>Date to</b></label>
              </b-col>
              <b-col sm="7">
                <b-input-group>
                  <b-form-datepicker class="w-50" v-model="form.dateTo.date" />
                  <b-form-timepicker v-model="form.dateTo.time" />
                  <b-input-group-prepend>
                    <b-button
                      type="button"
                      @click="
                        form.dateTo.date = null;
                        form.dateTo.time = null;
                      "
                      variant="light"
                    >
                      <b-icon icon="x-square" variant="dark"></b-icon>
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label><b>Expiration in days</b></label>
              </b-col>
              <b-col sm="7">
                <b-form-input v-model="form.expirationInDays"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label><b>Max сount per user</b></label>
              </b-col>
              <b-col sm="7">
                <b-form-input v-model="form.maxCountPerUser"></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b>
                    Is enabled
                    <b-icon
                      icon="info-circle"
                      font-scale="1"
                      variant="secondary"
                      v-b-popover.hover.topright="
                        'If this option is checked, the bonus will be active and can be used. If this option is not selected, the bonus is disabled and cannot be applied'
                      "
                    >
                    </b-icon>
                  </b>
                </label>
              </b-col>
              <b-col sm="auto">
                <b-form-checkbox v-model="form.isEnabled"></b-form-checkbox>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b> Is hided </b>
                </label>
              </b-col>
              <b-col sm="auto">
                <b-form-checkbox v-model="form.isHided"></b-form-checkbox>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b> Is multi applicability </b>
                </label>
              </b-col>
              <b-col sm="auto">
                <b-form-checkbox v-model="form.isMultiApplicability"></b-form-checkbox>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b> Is repeatedly </b>
                </label>
              </b-col>
              <b-col sm="auto">
                <b-form-checkbox v-model="form.isRepeatedly"></b-form-checkbox>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label
                  ><b>Currency <span style="color: red">*</span></b></label
                >
              </b-col>
              <b-col sm="7">
                <b-form-select v-model="form.currency" :options="currenciesOptions"></b-form-select>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b>Amount Set <span style="color: red">*</span></b>
                </label>
              </b-col>
              <b-col sm="8">
                <template>
                  <b-col sm="1 text-sm-left p-0" v-if="!showAmountSet">
                    <b-button variant="light" class="p-0" @click="addAmountSet">
                      <b-icon icon="plus-square" variant="dark"></b-icon>
                    </b-button>
                  </b-col>

                  <b-row class="mb-3 align-items-center" v-for="(set, index) in form.amountSet" :key="index">
                    <b-col sm="1 text-sm-left">
                      <label
                        ><b>Type <span style="color: red">*</span></b></label
                      >
                    </b-col>
                    <b-col sm="2">
                      <b-form-select v-model="set.type" :options="$options.amountTypeOptions"></b-form-select>
                    </b-col>

                    <b-col sm="1 text-sm-left">
                      <label
                        ><b>Amount <span style="color: red">*</span></b></label
                      >
                    </b-col>
                    <b-col sm="2">
                      <b-form-input v-model="set.amount"></b-form-input>
                    </b-col>

                    <b-col sm="text-sm-left ml-3 mr-3">
                      <label><b>Limit Max</b></label>
                    </b-col>
                    <b-col sm="2">
                      <b-form-input v-model="set.limitMax"></b-form-input>
                    </b-col>

                    <b-col sm="2 text-sm-left">
                      <b-button variant="light" class="p-0 mr-4" @click="dropAmountSet(index)">
                        <b-icon icon="trash-fill" variant="dark"></b-icon>
                      </b-button>
                      <b-button v-if="showAddAmountSet(index)" variant="light" class="p-0" @click="addAmountSet">
                        <b-icon icon="plus-square" variant="dark"></b-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b>Start Trigger <span style="color: red">*</span></b>
                </label>
              </b-col>
              <b-col sm="7">
                <b-form-select v-model="form.startTrigger" :options="startTriggerOptions"></b-form-select>
              </b-col>
            </b-row>

            <b-row class="mb-3" v-if="showStartTriggerOptions">
              <b-col sm="2 text-sm-left">
                <label><b>Start Trigger Options</b></label>
              </b-col>
              <b-col sm="7">
                <b-row class="mb-3 align-items-center" v-if="startTriggerValidations.isAmountTypeRequired">
                  <b-col sm="2 text-sm-left">
                    <label><b>Amount type</b></label>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="form.startTriggerOptions.amountType"
                      :options="$options.amountTypeOptions"
                    ></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="mb-3 align-items-center" v-if="startTriggerValidations.isMinAmountRequired">
                  <b-col sm="2 text-sm-left">
                    <label>
                      <b>Min amount {{ form.currency ? `(${form.currency})` : "" }}</b>
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-input v-model="form.startTriggerOptions.minAmount"></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="mb-3 align-items-center" v-if="startTriggerValidations.isMaxAmountRequired">
                  <b-col sm="2 text-sm-left">
                    <label>
                      <b>Max amount {{ form.currency ? `(${form.currency})` : "" }}</b>
                    </label>
                  </b-col>
                  <b-col>
                    <b-form-input v-model="form.startTriggerOptions.maxAmount"></b-form-input>
                  </b-col>
                </b-row>

                <b-row
                  class="mb-3 align-items-center"
                  v-if="
                    startTriggerValidations.isBlockchainDepositRequired &&
                    startTriggerValidations.isManualDepositRequired
                  "
                >
                  <b-col sm="3 text-sm-left">
                    <label>
                      <b>
                        Deposit type
                        <b-icon
                          icon="info-circle"
                          font-scale="1"
                          variant="secondary"
                          v-b-popover.hover.topright="
                            'After the user makes this type of deposit, the bonus will be applied'
                          "
                        >
                        </b-icon>
                        <span style="color: red" class="ml-1">*</span>
                      </b>
                    </label>
                  </b-col>

                  <b-col v-if="startTriggerValidations.isBlockchainDepositRequired" sm="3">
                    <b-row>
                      <b-form-checkbox v-model="form.startTriggerOptions.isBlockchainDeposit"></b-form-checkbox>
                      <label><b>Blockchain</b></label>
                    </b-row>
                  </b-col>

                  <b-col v-if="startTriggerValidations.isManualDepositRequired" sm="3">
                    <b-row>
                      <b-form-checkbox v-model="form.startTriggerOptions.isManualDeposit"></b-form-checkbox>
                      <label><b>Manual</b></label>
                    </b-row>
                  </b-col>
                </b-row>

                <b-row
                  class="mb-3 align-items-center"
                  v-if="
                    startTriggerValidations.isDepositToWalletRequired &&
                    startTriggerValidations.isDepositToTradingAccountRequired
                  "
                >
                  <b-col sm="3 text-sm-left">
                    <label>
                      <b>
                        Deposit to
                        <b-icon
                          icon="info-circle"
                          font-scale="1"
                          variant="secondary"
                          v-b-popover.hover.topright="'Where does the user need to make a deposit to get a bonus'"
                        >
                        </b-icon>
                        <span style="color: red" class="ml-1">*</span>
                      </b>
                    </label>
                  </b-col>

                  <b-col v-if="startTriggerValidations.isDepositToWalletRequired" sm="3">
                    <b-row>
                      <b-form-checkbox v-model="form.startTriggerOptions.isDepositToWallet"></b-form-checkbox>
                      <label><b>Wallet</b></label>
                    </b-row>
                  </b-col>

                  <b-col v-if="startTriggerValidations.isDepositToTradingAccountRequired" sm="3">
                    <b-row>
                      <b-form-checkbox v-model="form.startTriggerOptions.isDepositToTradingAccount"></b-form-checkbox>
                      <label><b>Trading account</b></label>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col sm="2 text-sm-left">
                <label><b>Partial Execution Rule</b></label>
              </b-col>
              <b-col sm="7">
                <b-row class="mb-3">
                  <b-col sm="2 text-sm-left">
                    <b-form-checkbox v-model="showPartialExecution"></b-form-checkbox>
                  </b-col>
                </b-row>

                <template v-if="showPartialExecution">
                  <b-row class="mb-3 align-items-center">
                    <b-col sm="2 text-sm-left">
                      <label><b>Interval Accrual In Hours</b></label>
                    </b-col>
                    <b-col sm="7">
                      <b-form-input v-model="form.partialExecutionRule.intervalAccrualInHours"></b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="mb-3 align-items-center">
                    <b-col sm="2 text-sm-left">
                      <label><b>Min amount</b></label>
                    </b-col>
                    <b-col sm="7">
                      <b-form-input v-model="form.partialExecutionRule.minAmount"></b-form-input>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col sm="2 text-sm-left">
                <label><b>Partial Cancellation Rule</b></label>
              </b-col>
              <b-col sm="7">
                <b-row class="mb-3">
                  <b-col sm="2 text-sm-left">
                    <b-form-checkbox v-model="showPartialCancellation"></b-form-checkbox>
                  </b-col>
                </b-row>

                <template v-if="showPartialCancellation">
                  <b-row class="mb-3 align-items-center">
                    <b-col sm="2 text-sm-left">
                      <label><b>Type</b></label>
                    </b-col>
                    <b-col sm="7">
                      <b-form-select
                        v-model="form.partialCancellationRule.type"
                        :options="$options.partialCancellationRuleTypeOptions"
                      ></b-form-select>
                    </b-col>
                  </b-row>

                  <b-row class="mb-3 align-items-center" v-if="form.partialCancellationRule.type === 'Percent'">
                    <b-col sm="2 text-sm-left">
                      <label><b>Amount</b></label>
                    </b-col>
                    <b-col sm="7">
                      <b-form-input v-model="form.partialCancellationRule.amount"></b-form-input>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b>Applicability <span style="color: red">*</span></b>
                </label>
              </b-col>
              <b-col sm="7">
                <b-form-select v-model="form.applicability" :options="$options.applicabilityOptions"></b-form-select>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b>Accrual Mode <span style="color: red">*</span></b>
                </label>
              </b-col>
              <b-col sm="7">
                <b-form-select v-model="form.accrualMode" :options="$options.accrualOptions"></b-form-select>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col sm="2 text-sm-left">
                <label>
                  <b
                    >Execution Conditions
                    <b-icon
                      class="mr-1"
                      icon="info-circle"
                      font-scale="1"
                      variant="secondary"
                      v-b-popover.hover.topright="'If all the conditions are met, the user will receive a bonus'"
                    >
                    </b-icon
                  ></b>
                </label>
              </b-col>
              <b-col sm="7">
                <b-row
                  class="mb-3 align-items-center"
                  v-for="(condition, index) in form.executionConditions"
                  :key="index"
                >
                  <b-col sm="auto mb-2">
                    <b-form-checkbox v-model="condition.enabled"></b-form-checkbox>
                  </b-col>
                  <b-col sm="auto">
                    <label>
                      <b>{{ condition.label }}</b>
                    </label>
                  </b-col>
                  <template
                    v-if="getRequiredExecutionConditionAmount(condition.executionCondition) && condition.enabled"
                  >
                    <b-col sm="auto text-sm-left ml-3">
                      <label><b>Amount</b></label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input v-model="condition.amount"></b-form-input>
                    </b-col>
                  </template>
                  <template v-if="condition.enabled">
                    <b-col sm="1 mb-1">
                      <b-form-checkbox v-model="condition.useInProgress"></b-form-checkbox>
                    </b-col>
                    <b-col sm="auto mt-1">
                      <label>
                        <b>Use in progress</b>
                        <b-icon
                          class="ml-1"
                          icon="info-circle"
                          font-scale="1"
                          variant="secondary"
                          v-b-popover.hover.topright="
                            'This condition will be considered in the progress (%) of the bonus execution'
                          "
                        >
                        </b-icon>
                      </label>
                    </b-col>
                  </template>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b>Cancellation Conditions <span style="color: red">*</span></b>
                </label>
              </b-col>
              <b-col sm="7">
                <multiselect
                  v-model="form.cancellationConditions"
                  :options="$options.cancellationConditionsOptions"
                  label="text"
                  track-by="value"
                  :multiple="true"
                  :close-on-select="true"
                  :show-labels="true"
                  :taggable="true"
                >
                </multiselect>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b>
                    Accounts<b-icon
                      class="ml-1"
                      icon="info-circle"
                      font-scale="1"
                      variant="secondary"
                      v-b-popover.hover.topright="
                        'The bonus will only be applied to selected accounts. If no accounts are selected, the bonus will be available to all accounts'
                      "
                    >
                    </b-icon>
                  </b>
                </label>
              </b-col>
              <b-col sm="7">
                <multiselect
                  v-model="form.allowedTradingServerAccounts"
                  :options="accountsOptions"
                  :multiple="true"
                  :group-select="true"
                  group-label="serverTitle"
                  group-values="accounts"
                  label="text"
                  track-by="value"
                  :show-labels="true"
                  :taggable="true"
                >
                </multiselect>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b>
                    Symbols
                    <b-icon
                      icon="info-circle"
                      font-scale="1"
                      variant="secondary"
                      v-b-popover.hover.topright="
                        'The bonus will only be valid when trading with the selected symbols. If symbols are not selected, the bonus will be available for all trading.'
                      "
                    >
                    </b-icon>
                  </b>
                </label>
              </b-col>
              <b-col sm="7">
                <multiselect
                  v-model="form.allowedTradingServerSymbols"
                  :options="symbolsOptions"
                  :multiple="true"
                  :group-select="true"
                  group-label="serverTitle"
                  group-values="symbols"
                  label="text"
                  track-by="value"
                  :show-labels="true"
                  :taggable="true"
                >
                </multiselect>
              </b-col>
            </b-row>

            <b-row class="mb-3 align-items-center">
              <b-col sm="2 text-sm-left">
                <label>
                  <b>
                    Symbol Groups
                    <b-icon
                      icon="info-circle"
                      font-scale="1"
                      variant="secondary"
                      v-b-popover.hover.topright="
                        'The bonus will only be valid when trading with the selected instruments. If instruments are not selected, the bonus will be available for all trading.'
                      "
                    >
                    </b-icon>
                  </b>
                </label>
              </b-col>
              <b-col sm="7">
                <multiselect
                  v-model="form.allowedTradingServerSymbolGroups"
                  :options="symbolGroupsOptions"
                  :multiple="true"
                  :group-select="true"
                  group-label="serverTitle"
                  group-values="symbolGroups"
                  label="text"
                  track-by="value"
                  :show-labels="true"
                  :taggable="true"
                >
                </multiselect>
              </b-col>
            </b-row>

            <b-button variant="primary" type="submit" :disabled="isLoading">
              {{ formMode === "Edit" ? "Edit bonus" : "Create bonus" }}
            </b-button>
          </b-form>
        </b-overlay>
      </b-container>
    </b-card-header>
  </b-card>
</template>

<script>
import { routePaths } from "../../route/routes";
import Multiselect from "vue-multiselect";
import { splitPascalCaseToWords } from "../../../utils/index";
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import { BIcon } from "bootstrap-vue";
import moment from "moment/moment";
import { toISOLocal } from "@/modules/sections/helpers/dates-converter";

const BonusesRepository = RepositoryFactory.get(RepositoryName.bonuses);
export default {
  name: "CreateBonusPage",
  components: {
    BIcon,
    Multiselect
  },
  amountTypeOptions: ["Fixed", "Percent"],
  applicabilityOptions: [{ text: "Trading Account", value: "TradingAccount" }],
  accrualOptions: ["Balance", "Credit"],
  partialCancellationRuleTypeOptions: ["Proportional", "Percent"],
  cancellationConditionsOptions: [
    { text: "Zero equity", value: "ZeroEquity" },
    { text: "Withdrawal from account", value: "WithdrawalFromAccount" },
    { text: "Equity less than bonus", value: "EquityLessThanBonus" }
  ],
  data() {
    return {
      isLoading: false,
      createInfoData: null,
      companyId: null,
      datesOptions: {
        format: "DD/MM/YYYY",
        useCurrent: false
      },
      showPartialExecution: false,
      showPartialCancellation: false,
      form: {
        title: "",
        descriptionShort: "",
        descriptionFull: "",
        dateFrom: {
          date: null,
          time: null
        },
        dateTo: {
          date: null,
          time: null
        },
        expirationInDays: null,
        maxCountPerUser: null,
        isEnabled: false,
        isHided: false,
        isMultiApplicability: false,
        isRepeatedly: false,
        amountSet: [],
        startTrigger: null,
        startTriggerDescription: "",
        startTriggerOptions: {
          amountType: null,
          minAmount: null,
          maxAmount: null,
          isPartialBonus: false
        },
        partialExecutionRule: {
          intervalAccrualInHours: null,
          minAmount: null
        },
        partialCancellationRule: {
          type: null,
          amount: null
        },
        applicability: null,
        applicabilityDescription: "",
        accrualMode: null,
        executionConditions: [
          {
            label: "Traded Volume Usd",
            executionCondition: "TradedVolumeUsd",
            amount: null,
            enabled: false,
            useInProgress: false
          },
          {
            label: "KYC",
            executionCondition: "KYC",
            amount: null,
            enabled: false,
            useInProgress: false
          },
          {
            label: "Filled Profile",
            executionCondition: "FilledProfile",
            amount: null,
            enabled: false,
            useInProgress: false
          }
        ],
        cancellationConditions: [],
        allowedTradingServerAccounts: [],
        allowedTradingServerSymbols: [],
        allowedTradingServerSymbolGroups: []
      }
    };
  },
  async mounted() {
    await this.getBonusDetails();
    await this.getCreateInfo();
  },
  computed: {
    formMode() {
      return this.$route.query.id ? "Edit" : "Create";
    },
    bonusId() {
      return this.$route.query.id;
    },
    startTriggerOptions() {
      if (!this.createInfoData) {
        return [];
      }

      return this.createInfoData.startTriggerOptions.map(({ startTrigger }) => ({
        value: startTrigger,
        text: splitPascalCaseToWords(startTrigger)
      }));
    },
    startTriggerValidations() {
      if (!this.createInfoData || !this.form.startTrigger) {
        return {};
      }
      return this.createInfoData.startTriggerOptions.find(option => option.startTrigger === this.form.startTrigger);
    },
    showStartTriggerOptions() {
      if (!this.createInfoData || !this.form.startTrigger) {
        return false;
      }
      return this.startTriggerValidations.isMinAmountRequired;
    },
    showAmountSet() {
      if (!this.createInfoData) {
        return false;
      }
      return this.form.amountSet.length > 0;
    },
    currenciesOptions() {
      if (!this.createInfoData) {
        return [];
      }
      return this.createInfoData.currencies.map(currency => {
        return { text: `${currency}`, value: currency };
      });
    },
    accountsOptions() {
      if (!this.createInfoData) {
        return [];
      }
      return this.createInfoData.servers.map(server => {
        const accounts = server.accounts.map(({ title, id }) => ({
          text: `${server.title} ${title}`,
          value: id
        }));
        return { accounts, serverTitle: server.title };
      });
    },
    symbolsOptions() {
      if (!this.createInfoData) {
        return [];
      }
      return this.createInfoData.servers.map(server => {
        const symbols = server.symbols.map(({ symbol, id }) => ({
          text: `${server.title} ${symbol}`,
          value: id
        }));
        return { symbols, serverTitle: server.title };
      });
    },
    symbolGroupsOptions() {
      if (!this.createInfoData) {
        return [];
      }
      return this.createInfoData.servers.map(server => {
        const symbolGroups = server.symbolGroups.map(group => ({
          text: `${server.title} ${group}`,
          value: group
        }));
        return { symbolGroups, serverTitle: server.title };
      });
    }
  },
  methods: {
    async getBonusDetails() {
      if (!this.bonusId) {
        return;
      }

      let resultInfo = await BonusesRepository.getBonusDetails(this.bonusId);

      if (resultInfo.status !== 200) {
        this.flashMessage.error({
          message: resultInfo.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        return;
      }

      const {
        title,
        descriptionFull,
        descriptionShort,
        dateFrom,
        dateTo,
        expirationInDays,
        maxCountPerUser,
        isEnabled,
        isHided,
        isMultiApplicability,
        isRepeatedly,
        currency,
        amountSet,
        startTrigger,
        applicability,
        applicabilityDescription,
        accrualMode,
        executionConditions,
        cancellationConditions,
        startTriggerOptions,
        startTriggerDescription,
        partialExecutionRule,
        partialCancellationRule,
        allowedTradingServerAccounts,
        allowedTradingServerSymbols,
        allowedTradingServerSymbolGroups
      } = resultInfo.data;

      this.form.title = title;
      this.form.descriptionFull = descriptionFull;
      this.form.descriptionShort = descriptionShort;

      this.form.dateFrom.date = moment(String(dateFrom)).utc().format("YYYY-MM-DD");
      this.form.dateFrom.time = moment(String(dateFrom)).utc().format("HH:mm:ss");

      this.form.dateTo.date = moment(String(dateTo)).utc().format("YYYY-MM-DD");
      this.form.dateTo.time = moment(String(dateTo)).utc().format("HH:mm:ss");

      this.form.expirationInDays = expirationInDays;
      this.form.maxCountPerUser = maxCountPerUser;
      this.form.isEnabled = isEnabled;
      this.form.isHided = isHided;
      this.form.isMultiApplicability = isMultiApplicability;
      this.form.isRepeatedly = isRepeatedly;
      this.form.amountSet = amountSet;
      this.form.currency = currency;
      this.form.startTrigger = startTrigger;
      this.form.startTriggerDescription = startTriggerDescription;
      this.form.applicability = applicability;
      this.form.applicabilityDescription = applicabilityDescription;
      this.form.accrualMode = accrualMode;

      this.form.allowedTradingServerAccounts = allowedTradingServerAccounts.map(({ id, title, type, serverTitle }) => ({
        value: id,
        text: `${serverTitle} ${title} ${type}`
      }));

      this.form.allowedTradingServerSymbols = allowedTradingServerSymbols.map(({ id, symbol }) => ({
        value: id,
        text: `${symbol}`
      }));

      this.form.allowedTradingServerSymbolGroups = allowedTradingServerSymbolGroups.map(({ group }) => ({
        value: group,
        text: `${group}`
      }));

      this.form.cancellationConditions = cancellationConditions.map(({ cancellationCondition }) => {
        const condition = this.$options.cancellationConditionsOptions.find(
          ({ value }) => value === cancellationCondition
        );
        return {
          text: condition.text,
          value: cancellationCondition
        };
      });
      if (executionConditions.length !== 0) {
        this.form.executionConditions = this.form.executionConditions.map(({ label, executionCondition }) => {
          const existingCondition = executionConditions.find(cond => cond.executionCondition === executionCondition);
          return {
            label,
            executionCondition,
            amount: existingCondition ? existingCondition.amount : null,
            enabled: !!existingCondition,
            useInProgress: existingCondition ? existingCondition.useInProgress : false
          };
        });
      }
      if (startTriggerOptions) {
        this.form.startTriggerOptions.minAmount = startTriggerOptions.minAmount;
      }
      if (partialExecutionRule) {
        this.showPartialExecution = true;
        this.form.partialExecutionRule.intervalAccrualInHours = partialExecutionRule.intervalAccrualInHours;
        this.form.partialExecutionRule.minAmount = partialExecutionRule.minAmount;
      }
      if (partialCancellationRule) {
        this.showPartialCancellation = true;
        this.form.partialCancellationRule.type = partialCancellationRule.type;
        this.form.partialCancellationRule.amount = partialCancellationRule.amount;
      }
    },
    async submitForm() {
      const data = {
        ...this.form,
        dateFrom: toISOLocal(new Date(this.form.dateFrom.date + " " + this.form.dateFrom.time)),
        dateTo: toISOLocal(new Date(this.form.dateTo.date + " " + this.form.dateTo.time)),
        amountSet: this.form.amountSet,
        allowedTradingServerAccounts: this.form.allowedTradingServerAccounts.map(item => item.value),
        allowedTradingServerSymbols: this.form.allowedTradingServerSymbols.map(item => item.value),
        allowedTradingServerSymbolGroups: this.form.allowedTradingServerSymbolGroups.map(item => item.value),
        cancellationConditions: this.form.cancellationConditions.map(condition => ({
          cancellationCondition: condition.value
        })),
        executionConditions: this.form.executionConditions
          .filter(({ enabled }) => enabled)
          .map(({ executionCondition, amount, useInProgress }) => ({
            executionCondition,
            amount: amount ? amount : null,
            useInProgress: useInProgress
          })),
        startTriggerOptions: {
          minAmount:
            this.startTriggerValidations.isMinAmountRequired && this.form.startTriggerOptions.minAmount
              ? this.form.startTriggerOptions.minAmount
              : null
        },
        partialExecutionRule: this.showPartialExecution
          ? {
              intervalAccrualInHours: this.form.partialExecutionRule.intervalAccrualInHours,
              minAmount: this.form.partialExecutionRule.minAmount
            }
          : null,
        partialCancellationRule: this.showPartialCancellation
          ? {
              type: this.form.partialCancellationRule.type,
              amount: this.form.partialCancellationRule.amount
            }
          : null
      };

      console.log(data);

      this.isLoading = true;
      let axiosResponse =
        this.formMode === "Edit"
          ? await BonusesRepository.editBonus({ ...data, id: this.bonusId })
          : await BonusesRepository.createBonus(data);

      if (axiosResponse.status !== 200) {
        this.flashMessage.error({
          message: axiosResponse.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.flashMessage.success({
        message:
          this.formMode === "Edit" ? "Bonus has been successfully updated" : "Bonus has been successfully created",
        blockClass: "msg-block"
      });

      this.$router.push(routePaths.bonusesList);
    },
    async getCreateInfo() {
      this.isLoading = true;

      const response = await BonusesRepository.getCreateBonusesInfo();
      this.createInfoData = response.data;

      this.isLoading = false;
    },
    getRequiredExecutionConditionAmount(executionCondition) {
      if (!executionCondition || !this.createInfoData) {
        return false;
      }
      return this.createInfoData.executionConditions.find(({ condition }) => condition === executionCondition)
        .isAmountRequired;
    },
    addAmountSet() {
      this.form.amountSet.push({
        type: null,
        amount: null,
        limitMax: null
      });
    },
    dropAmountSet(x) {
      this.form.amountSet.splice(x, 1);
    },
    showAddAmountSet(index) {
      return index === this.form.amountSet.length - 1;
    },
    dropDateFrom() {
      this.form.dateFrom = null;
    },
    dropDateTo() {
      this.form.dateTo = null;
    }
  }
};
</script>

<style scoped lang="scss"></style>
