<template>
  <b-card no-body border-variant="light">
    <b-card-header header-class="pl-0 pr-0">
      <b-container fluid>
        <b-row align-v="center">
          <b-col sm="auto">
            <h4 class="mb-0 text-left">
              <span class="mr-2">Logs</span>
              <b-badge variant="secondary" pill>
                {{ totalRows }}
              </b-badge>
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </b-card-header>
    <common-data-selection-grid
      :ref="refKeyCommonDataSelectionGrid"
      :data-result-list="logsResultList"
      :fields-list="fields"
      :is-busy="isLoading"
      :total-rows="totalRows"
      :per-page="perPage"
      :has-details="true"
      :need-per-page="true"
      @load-clicked="onLoadClicked"
      @pg-clicked="onPgClicked"
      @srch-clicked="onSrchClicked"
      @filter-clicked="onFilterClicked"
    >
    </common-data-selection-grid>
  </b-card>
</template>

<script>
import { RepositoryFactory, RepositoryName } from "@/api/repositories/repository-factory";
import CommonDataSelectionGrid from "../../../../components/grids/common-data-selection-grid";
import { convertLogsList } from "@/modules/sections/helpers/logs-converter";
import { checkUserPermissions } from "@/modules/sections/helpers/permissions-checker";

const ManageRepository = RepositoryFactory.get(RepositoryName.manage);
const refKeyCommonDataSelectionGrid = "common-data-selection-grid";

export default {
  name: "logs-list",
  components: {
    CommonDataSelectionGrid
  },
  data() {
    return {
      refKeyCommonDataSelectionGrid,

      isLoading: false,
      currentPage: 0,
      perPage: 20,
      filter: null,
      totalRows: 1,

      logsResultList: [],
      resultData: [],
      fields: [
        {
          key: "showDetails",
          label: "",
          sortDirection: "desc"
        },
        {
          key: "date",
          label: "Date",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "ip",
          label: "Ip",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "group",
          label: "Group",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "action",
          label: "Action",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "login",
          label: "Trading account",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "user",
          label: "User",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        },
        {
          key: "admin",
          label: "Admin",
          sortDirection: "desc",
          tdClass: "fieldsClass"
        }
      ]
    };
  },
  mounted: async function () {
    await this.getLogs();
  },
  methods: {
    async onLoadClicked(value) {
      this.perPage = value;

      await this.getLogs().catch(error => {
        console.error(error);
      });
    },
    async onPgClicked(value) {
      this.currentPage = value;
      await this.getLogs().catch(error => {
        console.error(error);
      });
    },
    async onSrchClicked(textValue) {
      this.filter = textValue;

      await this.getLogs().catch(error => {
        console.error(error);
      });
    },
    async onFilterClicked(value, textMask) {
      this.filter = textMask;

      await this.getLogs().catch(error => {
        console.error(error);
      });
    },
    async getLogs() {
      await this.getRequestResult();
    },
    async getRequestResult() {
      let filter = {
        params: {
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
          mask: this.filter,
          companyId: this.companyId
        }
      };

      this.isLoading = true;
      let axiosResponse = await ManageRepository.getLogs(filter);

      if (axiosResponse.status !== 200) {
        if (axiosResponse.status === 403) {
          await checkUserPermissions();
        }

        this.flashMessage.error({
          message: axiosResponse.response.data.errors
            .map(function (obj) {
              return obj.message;
            })
            .join("\n"),
          blockClass: "msg-block"
        });

        this.isLoading = false;
        return;
      }

      this.totalRows = axiosResponse.data.total;
      let logsResponse = axiosResponse.data;

      this.resultData = logsResponse.items;
      this.isLoading = false;

      this.logsResultList = convertLogsList(this.resultData);
    }
  }
};
</script>
