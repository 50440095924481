import Repository from "./repository-base";

const resource = "admin/profile";

export default {
  getProfile() {
    return Repository.get(`${resource}`);
  },
  getUserLogs(filter) {
    return Repository.get(`${resource}/logs`, filter);
  },
  getUserStatistic() {
    return Repository.get(`${resource}/statistic`);
  }
};
