import moment from "moment";
import "moment-duration-format";

export function convertTransactionsList(sourceData) {
  if (sourceData.length > 0) {
    return sourceData.map(item => {
      const container = {};

      container.id = item.id;
      container.number = item.number;
      container.user = item.user;

      container.dateRange = moment(String(item.date)).utc().format("DD.MM.YYYY HH:mm:ss");
      container.first = toFixed(item.amounts.first.amount) + " " + item.amounts.first.currency;
      container.second = item.amounts.second
        ? toFixed(Math.round(item.amounts.second.amount * 10000000) / 10000000) + " " + item.amounts.second.currency
        : item.amounts.second;
      container.description = item.description;

      container.details = item.items;
      container.status = item.status;
      container.actions = item.actions;
      container.paymentMethod = item.paymentMethod;
      container.typeTitle = item.typeTitle;
      container.transactionInfoUrl = item.transactionInfoUrl;
      container.sourceBefore =
        item.sourceSnapshots.length > 0
          ? item.sourceSnapshots
              .map(x => {
                return x.balanceOld + " " + x.currency;
              })
              .join(", ")
          : "-";

      container.sourceAfter =
        item.sourceSnapshots.length > 0
          ? item.sourceSnapshots
              .map(x => {
                return x.balanceNew + " " + x.currency;
              })
              .join(", ")
          : "-";

      container.destinationBefore =
        item.destinationSnapshots.length > 0
          ? item.destinationSnapshots
              .map(x => {
                return x.balanceOld + " " + x.currency;
              })
              .join(", ")
          : "-";

      container.destinationAfter =
        item.destinationSnapshots.length > 0
          ? item.destinationSnapshots
              .map(x => {
                return x.balanceNew + " " + x.currency;
              })
              .join(", ")
          : "-";

      container.amount = container.first ? container.first : container.second;
      return container;
    });
  }
}

function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x = x * Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}
