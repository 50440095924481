import Repository from "./repository-base";

const resource = "admin/payments";

export default {
  getTotalDeposits(userId, filter) {
    return Repository.get(`${resource}/${userId}/deposits`, filter);
  },
  getTotalWithdrawals(userId, filter) {
    return Repository.get(`${resource}/${userId}/withdrawals`, filter);
  },
  getUserPaymentMethods(id, filter) {
    return Repository.get(`${resource}/${id}/payment/methods`, filter);
  },
  getUserPaymentMethodLimits(id, paymentMethodId) {
    return Repository.get(`${resource}/${id}/${paymentMethodId}/payment/method/limits`);
  },
  createWithdrawalRequest(id, request) {
    return Repository.post(`${resource}/${id}/withdraw`, request);
  },
  addDepositLimitComment(userId, paymentMethodId, request) {
    return Repository.post(`${resource}/limit/${userId}/${paymentMethodId}/comments`, request);
  },
  addPaymentMethodComment(accountId, paymentMethodId, request) {
    return Repository.post(`${resource}/method/${accountId}/${paymentMethodId}/comments`, request);
  }
};
